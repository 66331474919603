<template>
  <div class="profile" v-if="userData !== null" v-bind:style="{ paddingBottom: '64px' }">
    <NavigationBar id="profileNavigationBarId" ref="profileNavigationBarRef">
      <template v-slot:center>
        {{ $t('profile.title') }}
      </template>
    </NavigationBar>
    
    <!-- Hidden!!! -->
    <div v-if="1 > 2" class="table-view">
      <div class="table-view-header">
        Erfolge
      </div>
      <!-- TODO: Load achievements from DB-->
      <div class="achievements">
  
        <div v-for="(achievement, index) in achievements"
             :key="index"
             class="achievements-item"
             v-on:click="showAchievement(achievement)"
             :style="{
                'background': 'rgba(' + achievement.color + ', .08)',
                'box-shadow': 'inset 0 0 0 2px rgba(' + achievement.color + ', 1)'
              }">
          <img :src="achievement.iconSmall">
          <div class="achievements-item-label" :style="{'color' : 'rgba(' + achievement.color + ', 1)' }">
            {{ achievement.text }}
          </div>
        </div>
      </div>
    </div>

    <!--
    <div v-if="userBetgames.length > 1" class="table-view">
      <div class="table-view-header">
        Tipprunden
      </div>
      <a v-for="(betgame, index) in userBetgames" :key="index" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-label">{{ betgame.name }}</div>
        <div class="table-view-cell-value"></div>
        <div class="table-view-cell-drilldown"></div>
      </a>
    </div>
    -->
    <div class="table-view">
      <div class="table-view-header">
        {{ $t('profile.header_section_1', { userId: userId }) }}
      </div>
      <router-link v-if="this.$store.state.user && this.$store.state.user.status === 'validation_pending'" to="/validateregistration" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.confirm_email_address') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profilechangenickname" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.change_nickname') }}</div>
          <div class="table-view-cell-value" style="overflow: hidden;">{{ this.$store.state.user.nickname }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profilechangeemail" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.change_email_address') }}</div>
          <div class="table-view-cell-value" style="overflow: hidden;">{{ this.$store.state.user.email }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profilechangepassword" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.change_password') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profilechangelanguage" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.change_language') }}</div>
          <div class="table-view-cell-value" style="overflow: hidden;">{{ this.$store.state.language }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profileeditavatar" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.profile_picture') }}</div>
          <img v-if="isValidIcon(this.$store.state.user.avatar)" v-bind:src="buildAvatarPath(this.$store.state.user.avatar, 300)">
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profilechangenotifications" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.notification_settings') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profilechangeuserage" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.age_rating') }}</div>
          <div class="table-view-cell-value" style="overflow: hidden;"></div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <!--
      <router-link v-if="this.isSPMUser()" to="/profileupdatetoken" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.push_token') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      -->
      <router-link to="" class="router_link">
        <a v-on:click="showCMP()" href="" target="_blank" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('home.footer_privacy_settings') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/profiledeleteaccount" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.delete_account') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      
      <!--  
      <router-link to="/profilechangerakingspagesize" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('menu.matchday_ranking') }}</div>
          <div class="table-view-cell-value" style="overflow: hidden;">{{ this.getBGValue('spmrps') ? this.getBGValue('spmrps') : this.pageSize }} {{ $t('per_page')}}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.isSPMUser()" to="/localrankings" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">Rankings</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.isSPMUser()" to="" class="router_link">
        <a v-on:click="requestReview()" href="" target="_blank" class="table-view-cell">
          <div class="table-view-cell-label">Review</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.isSPMUser()" to="" class="router_link">
        <a v-on:click="updateWidget()" href="" target="_blank" class="table-view-cell">
          <div class="table-view-cell-label">Widget</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.isSPMUser()" to="" class="router_link">
        <a v-on:click="clearChat()" href="" target="_blank" class="table-view-cell">
          <div class="table-view-cell-label">Chat leeren</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link v-if="this.isSPMUser()" to="/dashboard" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">Dashboard</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      -->
      
      <!--
      <a href="" class="table-view-cell">
        <div class="table-view-cell-label">Benachrichtigungen</div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      -->
      
      <!--
      <a href="" class="table-view-cell">
        <div class="table-view-cell-label">Sprache</div>
        <div class="table-view-cell-value">Deutsch</div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      -->
    </div>
  
    <div class="table-view">
      <div class="table-view-header">
        {{ $t('profile.header_section_2', { version: this.buildVersion() }) }}
      </div>
      <router-link to="" class="router_link">
        <a v-if="selectedLanguage === 'de'" v-on:click="openFAQ('')" href="https://teamtip.zendesk.com/hc/de" target="_blank" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.faq') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
        <a v-else-if="!selectedLanguage || selectedLanguage !== 'de'" v-on:click="openFAQ('')" href="https://teamtip.zendesk.com/hc/en-us" target="_blank" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.faq') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/contact" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.contact') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/termsofuse" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.conditions') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/privacy" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.data_protection') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/adminprivacy" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.data_protection_admin') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/orderagreement" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.order_processing_agreement') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
      <router-link to="/imprint" class="router_link">
        <a href="" class="table-view-cell">
          <div class="table-view-cell-label">{{ $t('profile.imprint') }}</div>
          <div class="table-view-cell-drilldown"></div>
        </a>
      </router-link>
    </div>
    
    <div v-if="this.isSPMUser()" class="table-view">
      <div class="table-view-header">
        Info
      </div>
      <div><span>AppOpenedCount: {{ this.appOpenedCount() }}</span></div>
      <div><span>ReviewRequestedCount: {{ this.reviewRequestedCount() }}</span></div>
      <div><span>LastReviewRequestDate: {{ lastReviewRequestDate }}</span></div>
      <div><span>ReviewPopupShown: {{ this.$store.state.reviewPopupShown }}</span></div>
      <div><span>ShowReviewPopupDebug: {{ showReviewPopupDebug }}</span></div>
      <div><span>App version: {{ this.appVersion() }}</span></div>
      <div><span>App build: {{ this.appBuild() }}</span></div>
      <div><span>Language: {{ language }}</span></div>
      <div><span>Firebase Id: {{ this.firebaseInstallationId() }}</span></div>
      <div><span>CMP: {{ this.cmpInfo() }}</span></div>
      <div><span>Chat: {{ chatToken }}</span></div>
      <div><span>Chat Token: {{ chatTokenToString }}</span></div>
      <div><span>Chat Token Expires: {{ chatTokenExpires }}</span></div>
      <div><span>Chat Msg count: {{ chatMessageCount }}</span></div>
      <div><span>Chat participant count: {{ chatParticipantCount }}</span></div>
      <div><span>Chat participant: {{ chatParticipant }}</span></div>
      <div><span>Geo: {{ this.$store.state.geoData }}</span></div>
      <div><span>Version: {{ this.buildVersion() }}</span></div>
      <div><span>Tracking: -{{ appTrackingAuthorizationStatus }}- {{ this.appTrackingAuthorized() }}</span></div>
      <div><span>Timezone: {{ timezone }}</span></div>
      <div><span>Local time: {{ localtime }}</span></div>
      <div><span>App: {{ this.isApp() }}</span></div>
      <div><span>Odds: {{ showOdds }}</span></div>
      <div><span>User source: {{ userSource }}</span></div>
      <div><span>isAndroidTeamtipApp: {{ this.isAndroidTeamtipApp() }}</span></div>
      <div><span>API: {{ apiURL }}</span></div>
      <div><span>Betgame: {{ betgameInfo }}</span></div>
      <div><span>CurrentSeason: {{ currentSeason }}</span></div>
      <div><span>BetgameSeason: {{ betgameSeason }}</span></div>
      <div><span>{{ this.userAgent() }}</span></div>
      <div><span>Push: {{ pushToken }}</span></div>
      <div><span>AppLang: {{ appLanguage }}</span></div>
      <div><span>Api: {{ this.$store.state.token }}</span></div>
      <div><span>User: {{ userProfile }}</span></div>
      <div><span>Token Valid Until: {{ tokenValidUntil }}</span></div>
      <div><span>Last Token Refresh {{ lastTokenRefresh }}</span></div>
      <div><span>Last Refresh Check {{ lastTokenRefreshCheck }}</span></div>
      <div><span>Sent Last Active {{ lastActive }}</span></div>
      <div><span>Sent Last Active App {{ lastActiveApp }}</span></div>
      <a href="javascript:window.location.reload(true);">Reload</a>
      <br>
    </div>
    
    <div class="table-view">
      <button @click="logout()">Logout</button>
    </div>
    
    <!--
    <div style="margin: 20px">
      <router-link to="/error">
          <span>Fehler-Seite</span>
      </router-link>
    </div>
    
    <p>{{ userData.nickname }}</p>
    <p>{{ userData.email }}</p>
    <p>{{ userData.id }} - {{ userData.status }}</p>
    <p>{{ exp }}</p>
    <p>{{ payload }}</p>
    <p>{{ this.$store.getters.getCachingDuration }}</p>
    <p class="token">{{ token }}</p>
  
    <br>
    
    <div>
      <a href="https://www.fussifreunde.de/">https://www.fussifreunde.de/</a><br>
      <a href="https://www.fussifreunde.de/" target="_blank">https://www.fussifreunde.de/ (target="_blank")</a><br>
      <a href="https://www.spielerplus.de/">https://www.spielerplus.de/</a><br>
      <a href="https://www.spielerplus.de/" target="_blank">https://www.spielerplus.de/ (target="_blank")</a><br>
      <a href="https://www.kicker.de/">https://www.kicker.de/</a><br>
      <a href="https://www.kicker.de/" target="_blank">https://www.kicker.de/ (target="_blank")</a><br>
    </div>
    -->

    <transition v-if="overlayOpened" name="review-modal">
      <div class="review-modal-mask">
        <div class="review-modal-wrapper">
          <div class="review-modal-container">
            <div class="input-header">
              {{ $t('review_request_title') }}
            </div>
            <div class="input-infotext">
              {{ $t('review_request_text') }}
            </div>
            <star-rating style="justify-content: center;" @rating-selected="ratingChanged($event)" :rating="rating" :show-rating="false"></star-rating>
            <div class="review-modal-footer">
              <button class="review-modal-default-button" @click="ratingSkipped()">
                {{ $t('later') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    
    <modal name="achievement-modal" id="achievement-modal" :scrollable="true" width="100%" height="auto">
  
      <div>
        <div class="modal-header-left"></div>
        <div class="modal-header-center"></div>
        <div class="modal-header-right">
          <button
                  type="button"
                  class="btn-close"
                  @click="$modal.hide('achievement-modal')"
                  aria-label="Close modal">
          </button>
        </div>
      </div>
      <div>
        <div v-if="selectedAchievement" class="achievement-modal-image" :style="{'background' : 'rgba(' + selectedAchievement.color + ', .08)' }">
          <img :src="selectedAchievement.iconLarge">
        </div>
        <div v-if="selectedAchievement" class="achievement-modal-content">
          <div class="achievement-modal-title">
            {{ selectedAchievement.headline }}
          </div>
          <div v-if="selectedAchievement" class="achievement-modal-description">
            {{ selectedAchievement.description }}
          </div>
          <ul class="achievement-modal-bullets">
            <li>17. Spieltag <span>(14)</span></li>
            <li>18. Spieltag <span>(12)</span></li>
            <li>19. Spieltag <span>(13)</span></li>
          </ul>
        </div>
      </div>
    </modal>
    
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import NavigationBar from './../components/NavigationBar'
import Vue from 'vue'
import StarRating from 'vue-star-rating'
import { RANKINGS_PAGE_SIZE } from '@/main'

export default {
  name: 'Profile',
  data () {
    return {
      rating: 0,
      overlayOpened: false,
      selectedAchievement: null,
      achievements: [
        {
          type: 'Siegesserie',
          text: 'Siegesserie',
          headline: '3 Siege in Folge',
          description: 'Wahnsinn! Du hast drei Spieltage in Folge die meisten Punkte abgeräumt.',
          iconSmall: require('../assets/achievements/ic_64_achievement_siegesserie.svg'),
          iconLarge: require('../assets/achievements/ic_140_achievement_siegesserie.svg'),
          color: '241,92,83'
        },
        {
          type: 'Spieltagssieg',
          text: 'Spieltagssieg',
          headline: '3. Spieltag',
          description: 'Glückwunsch! Mit 17 Punkten konntest du dir den Spieltagssieg holen - weiter so!',
          iconSmall: require('../assets/achievements/ic_64_achievement_spieltagssieg.svg'),
          iconLarge: require('../assets/achievements/ic_140_achievement_spieltagssieg.svg'),
          color: '245,184,66'
        },
        {
          type: 'Spitzenreiter',
          text: 'Spitzenreiter',
          headline: 'Gipfelstürmer',
          description: 'Super! Am 12. Spieltag hast du erstmalig die Tabellenführung übernommen.',
          iconSmall: require('../assets/achievements/ic_64_achievement_spitzenreiter.svg'),
          iconLarge: require('../assets/achievements/ic_140_achievement_spitzenreiter.svg'),
          color: '121,199,239'
        },
        {
          type: 'Volltreffer',
          text: 'Volltreffer',
          headline: 'Volltreffer',
          description: 'Du bist ein Expert! Bei drei Partien wusstest du an diesem Spieltag das richtige Ergebnis.',
          iconSmall: require('../assets/achievements/ic_64_achievement_volltreffer.svg'),
          iconLarge: require('../assets/achievements/ic_140_achievement_volltreffer.svg'),
          color: '222,128,234'
        },
        {
          type: 'Welcome',
          text: 'Beigetreten',
          headline: 'Herzlich Willkommen',
          description: 'Wir freuen uns, dass du dabei bist. Wenn du richtig tippst, wirst du hier schon bald weitere Erfolge finden!',
          iconSmall: require('../assets/achievements/ic_64_achievement_welcome.svg'),
          iconLarge: require('../assets/achievements/ic_140_achievement_welcome.svg'),
          color: '36,224,124'
        },
      ]
    };
  },
  created () {
    if(this.$store.state.user && (this.$store.state.user.status === 'active' || this.$store.state.user.status === 'validation_pending')) {
      const data = {
        'id': '' + this.$store.state.user.id
      }
      this.$store.dispatch('refreshToken', data).then(response => {
      })
      this.$store.dispatch('fetchUnsubscribed', { id: this.$store.state.user.id, email: this.$store.state.user.email }).then((resonse) => {
        Vue.$log.debug('profile created fetchUnsubscribed ' + JSON.stringify(resonse.data))
      })
    }
  },
  components: { NavigationBar, StarRating },
  computed: {
    pageSize() {
      return parseInt(this.getBGValue('spmrps') ? this.getBGValue('spmrps') : RANKINGS_PAGE_SIZE)
    },
    chatToken() {
      return this.$store.state.chat_token
    },
    showOdds() {
      var result = ''
      var show = false
      var bets = this.$store.state.matchBets ? this.$store.state.matchBets.length : 0
      if(this.$store.state.betgame) {
        result += 'betgame ' + this.$store.state.betgame.id
        result += ' - hide_ads ' + this.$store.state.betgame.hide_ads
      }
      if(this.$store.state.user) {
        result += ' - user ' + this.$store.state.user.id
        result += ' - isadult ' + this.$store.state.user.isadult
      }
      result += ' - isHandballBetgame ' + this.isHandballBetgame
      result += ' - countryBlocked ' + this.countryBlocked()
      if(this.$store.state.betgame && this.$store.state.user) {
        show = !this.isHandballBetgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
      }
      result += ' - show ' + show
      result += ' - bets ' + bets
      return result
    },
    isHandballBetgame() {
      return this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533
    },
    selectedLanguage() {
      return this.$store.state.language
    },
    currentSeason() {
      return JSON.stringify(this.$store.state.currentSeason)
    },
    betgameSeason() {
      if(this.$store.state.betgame) {
        return JSON.stringify(this.$store.state.betgame.season)
      }
      return 'betgameSeason null'
    },
    userProfile() {
      return JSON.stringify(this.$store.state.user)
    },
    showReviewPopupDebug() {
      const a = this.isSPMUser() 
      const b = navigator.userAgent.indexOf('TEAMTIP') > 0
      const appOpenedCount = window.localStorage.getItem('appOpenedCount')
      const c = appOpenedCount !== null && appOpenedCount !== undefined && parseInt(appOpenedCount) > 0 && parseInt(appOpenedCount) % 3 === 0
      return a + ' --- ' + b + ' --- ' + c + ' --- ' + this.showSPMReviewPopup()
    },
    lastReviewRequestDate() {
      if (this.isApp()) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const a = window.TeamtipAppInterface.getAppScoreData()
            var b = ''
            try {
              const j = JSON.parse(a)
              b = j.lastReviewRequestDate
            } catch (e) {
              b = e.message
            }
            return b
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getAppScoreData')
            const c = window.localStorage.getItem('lastReviewRequestDate')
            return '' + c
          } else {
            return "TeamtipAppInterface unavailable"
          }
        } catch (e) {
          return "TeamtipAppInterface error " + e.message
        }
      } else {
        return 'not app'
      }
    },
    chatMessageCount() {
      return this.$store.state.chatMessageCount
    },
    chatParticipant() {
      return this.$store.state.chats_participant ? JSON.stringify(this.$store.state.chats_participant) : 'null'
    },
    chatParticipantCount() {
      return this.$store.state.chats_participant ? this.$store.state.chats_participant.length : 0
    },
    language() {
      return this.$store.state.language
    },
    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    localtime() {
      return this.nowToLocalTimeZone()
    },
    pushToken(){
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          return window.TeamtipAppInterface.getPushToken()
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getPushToken')
          return window.localStorage.getItem('pushToken')
        } else {
          return ""
        }
      } catch (e) {
        return "";
      }
    },
    appTrackingAuthorizationStatus(){
      return window.localStorage.getItem('trackingAuthorizationStatus')
    },
    appLanguage(){
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          return window.TeamtipAppInterface.getLocaleLanguage()
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getLang')
          return window.localStorage.getItem('preferredLanguage') + ' --- ' + window.localStorage.getItem('currentLanguageCode')
        } else {
          return ""
        }
      } catch (e) {
        return "";
      }
    },
    userSource() {
      var us = window.localStorage.getItem('userSource') || 'none'
      us += ' --- ' + this.$store.state.userSource 
      return us
    },
    apiURL() {
      return window.localStorage.getItem('spmau') + ' --- ' + document.domain
    },
    betgameInfo() {
      var t = window.localStorage.getItem('spmbgid') + ' --- ' 
      if(this.$store.state.betgame) {
        t += this.$store.state.betgame.id + ' --- '
      }
      if(this.$store.state.betgameId) {
        t += this.$store.state.betgameId
      }
      return t
    },
    lastTokenRefresh() {
      return window.localStorage.spmltr
    },
    lastTokenRefreshCheck() {
      return window.localStorage.spmltrc
    },
    tokenValidUntil() {
      if (this.$store.state.token) {
        try {
          const payload = jwt_decode(this.$store.state.token)
          const expDate = payload.exp * 1000
          return new Date(expDate)
        } catch (e) {
        }
      }
      return 'ValidUntil null'
    },
    chatTokenToString() {
      if (this.$store.state.chat_token) {
        try {
          const payload = jwt_decode(this.$store.state.chat_token)
          return JSON.stringify(payload)
        } catch (e) {
        }
      }
      return 'chatTokenToString null'
    },
    chatTokenExpires() {
      if (this.$store.state.chat_token) {
        try {
          const payload = jwt_decode(this.$store.state.chat_token)
          return new Date(payload.exp * 1000)
        } catch (e) {
        }
      }
      return 'chatTokenToString null'
    },
    debugOutput() {
      return document.domain + ' - ' + this.getBGValue('spmau')
    },
    currentBetgame() {
      return this.$store.state.betgame
    },
    userBetgames() {
      return this.$store.state.userBetgames
    },
    userId() {
      return this.$store.state.user.id
    },
    userData() {
      return this.$store.state.user
    },
    token() {
      return this.$store.state.token
    },
    exp() {
      const payload = jwt_decode(JSON.stringify(this.$store.state.token))
      const expDate = new Date(payload.exp * 1000)
      return (
        expDate.toLocaleDateString('de-DE') +
        ' ' +
        expDate.toLocaleTimeString('de-DE')
      )
    },
    payload() {
      return jwt_decode(JSON.stringify(this.$store.state.token))
    },
    lastActive() {
      return this.getBGValue('spmsla')
    },
    lastActiveApp() {
      return this.getBGValue('spmsala')
    }
  },
  methods: {
    showCMP() {
      if (this.isApp()) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            window.TeamtipAppInterface.showCMPNative()
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('showCMPNative')
          }
        } catch (e) {
        }
      } else {
        window.__cmp('showScreen')
      }
    },
    clearChat() {
      this.$store.dispatch('patchBetgame', { chat: '' })
      .then(() => {
      })
      .catch(function (error) {
      })
      .finally(function () {
      })
    },
    ratingSkipped() {
      this.overlayOpened = false
      this.$store.dispatch('addRating', {'rating': 0, 'date': new Date(), 
        'version': this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(),
        'reviewRequested': this.reviewRequestedCount(), 'info': '' } ).then(() => {})
    },
    ratingChanged(r) {
      this.rating = r
      this.overlayOpened = false
      this.$store.dispatch('addRating', {'rating': r, 'date': new Date(), 
        'version': this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(), 
        'reviewRequested': this.reviewRequestedCount(), 'info': '' } ).then(() => {})
      if(this.rating === 5) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const a = window.TeamtipAppInterface.requestAppStoreReview()
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('requestAppStoreReview')
          } else {
          }
        } catch (e) {
        }
      }
    },
    updateWidget() {
      if (this.isSPMUser()) {
        const mb = this.$store.state.missing_bets
        var result = 0
        for (var i = 0; i < mb.length; i++) {
          const item = mb[i]
          if (item.matchcount > item.betcount) {
            result += item.matchcount - item.betcount
          }
        }
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const c = window.webkit.messageHandlers.TeamtipAppInterface.postMessage({
              'user': this.$store.state.user.id,
              'token': this.$store.state.token,
              'missingBets': result
            })
          }
        } catch (e) {
        }
      }
    },
    requestReview() {
      this.overlayOpened = true
      /*
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          const a = window.TeamtipAppInterface.requestAppStoreReview()
        } else if (window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('requestAppStoreReview')
        } else {
        }
      } catch (e) {
      }
      */
    },
    openFAQ(name) {
      const l = this.selectedLanguage === 'de' ? 'de' : 'en-us'
      if(window.parent) {
        window.parent.open('https://teamtip.zendesk.com/hc/' + l, '_blank')
      } else {
        window.open('https://teamtip.zendesk.com/hc/' + l, '_blank')
      }
    },
    sendLogout() {
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          const a = window.TeamtipAppInterface.logout()
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('logout')
        } else {
        }
      } catch (e) {
      }
    },
    switchBetgame(betgame) {
      if(this.currentBetgame.id !== betgame) {
        this.setBGValue('spmbgid', betgame)
        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
          this.setBGValue('betgameId', betgame)
        }
        this.$store.dispatch('switchBetgame', betgame).then(() => {
          this.$router.push('/feed/dashboard')
        })
      } else {
        this.$router.push('/feed/dashboard')
      }
    },
    changeEmail: function() {
      this.$router.push('/profilechangeemail')
    },
    changePassword: function() {
      this.$router.push('/profilechangepassword')
    },
    deleteAccount: function() {
      this.$router.push('/profiledeleteaccount')
    },
    logout: function() {
      /*
      Vue.$gtag.event('logout', {
        'event_category': 'logout',
        'event_label': window.location.href,
        'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
        'dimension2': this.$store.state.user && this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
        'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
      })
      */
      this.$store.dispatch('logout').then(() => {
        this.sendLogout()
        this.$router.push('/').then(() => {
          window.location.reload()
        })
      })
    },
    showAchievement(achievement) {
      this.selectedAchievement = achievement
      this.$modal.show('achievement-modal');
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  word-wrap: break-word;
  max-width: 576px;
  margin-top: 8px;

  @media screen and (min-width: 768px) {
    margin: 36px auto 0;
  }
}

.table-view-cell {
  img {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    overflow: hidden;
    object-fit: cover;
  }
}

.achievements {
  display: flex;
  padding: 16px 16px 0 16px;
  margin: 0 -16px;
  overflow: scroll;

  @media screen and (min-width: 768px) {
    overflow: visible;
    flex-wrap: wrap;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-item {
    display: flex;
    flex-direction: column;
    padding: 0 0 7px 0;
    background: rgba($colorAchievement, .08);
    box-shadow: inset 0 0 0 2px $colorAchievement;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
    position: relative;
    max-width: 80px;

    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $colorWhite;
      border-radius: 8px;
    }

    :after {
      content: "";
      display: block;
      position: absolute;
      right: -16px;
      width: 16px;
      height: 1px;
    }

    img {
      margin: 0 8px;
    }

    &-label {
      font-size: 11px;
      font-weight: 500;
      color: rgba(241,92,83,1);
      letter-spacing: -0.32px;
      text-align: center;
      margin-top: -4px;
    }
  }
}

.achievement-modal {

  &-image {
    margin: -64px -16px 0;
    display: flex;
    justify-content: center;
    box-shadow: inset 0 0 0 1px rgba($colorGreyLight, .08);
  }

  &-content {
    color: $colorGreyDark;
    text-align: center;
    padding: 27px 0 0 0;
  }

  &-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  &-description {
    font-size: 14px;
  }

  &-bullets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 25px 0 13px 0;

    li {
      list-style-type: none;
      font-size: 11px;
      font-weight: 500;
      margin: 0 6px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        color: var(--color-primary);
      }
    }
  }
}

.router_link {
  text-decoration: none;
}
</style>
