import { render, staticRenderFns } from "./DashboardNew.vue?vue&type=template&id=96e448ae&scoped=true"
import script from "./DashboardNew.vue?vue&type=script&lang=js"
export * from "./DashboardNew.vue?vue&type=script&lang=js"
import style0 from "./DashboardNew.vue?vue&type=style&index=0&id=96e448ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96e448ae",
  null
  
)

export default component.exports