/* eslint-disable */
<template>

    <div :key="componentKey" class="betMatchList">

      <!-- Display only if betgame is displayed in iFrame -->
      <!--
      <div v-bind:class="betsEditable && showOdds ? 'betpromo--visible' : ''" class="betpromo betpromo--small" v-on:click="deepLinkClicked">
        <div class="betpromo__logo">
          <img src="../assets/icons/ic_bwin.svg" alt="bwin Logo">
        </div>
        <div class="betpromo__text">
          <div class="betpromo__label">
            Gewinne {{ totalOdds | formatNumber }}€
          </div>
          <div class="betpromo__sublabel">
            10€ Einsatz | 18+ | bzga.de
          </div>
        </div>
      </div>
      -->
      
      <div v-if="betmode === 2">
        <EditBetRowToto 
                    @guestInputFocusLost="guestInputFocusLost"
                    :matchIndex="index * 2"
                    :match="match"
                    :previousMatch="matches[index - 1 > 0 ? index - 1 : 0]"
                    :nextMatch="matches[index + 1 < matches.length ? index + 1 : matches.length - 1]"
                    v-for="(match, index) in matches"
                    :key="match.id"
                    :ref="match.id"
        ></EditBetRowToto>
      </div>
      <div v-else>
        <EditBetRow 
            @guestInputFocusLost="guestInputFocusLost"
            :matchIndex="index * 2"
            :match="match"
            :previousMatch="matches[index - 1 > 0 ? index - 1 : 0]"
            :nextMatch="matches[index + 1 < matches.length ? index + 1 : matches.length - 1]"
            :selectedTab="selectedBatgameTab"
            v-for="(match, index) in matches"
            :key="match.id"
            :ref="match.id"
        ></EditBetRow>
      </div>
      
      <small v-if="lastBetSaveSuccess && matches && matches.length > 0" style="margin-top: -5px; margin-bottom: 16px">
        {{ $t('last_data_save_success') }}: {{ lastBetSaveSuccess | formatDateDayMonth }} {{ $t('at_time') }} {{ lastBetSaveSuccess | formatTime }}
      </small>

      <a v-if="showOdds" :href="this.deepLink" target="_blank" class="betpromo" 
         v-bind:class="{'betpromo--visible': betsEditable && showOdds, 'betpromo--sticky': isBattle(), 'betpromo--sticky': isTeamsBetgame(), 'betpromo--sticky-withnav': isBetgame()}">
        <div class="betpromo__logo">
          <div class="betpromo__logo__content">
            <img src="../assets/icons/ic_bwin.svg" alt="bwin Logo">
          </div>
        </div>
        <div class="betpromo__text">
          <div class="betpromo__label">
            Gewinne {{ totalOdds | formatNumber }}€
          </div>
          <div class="betpromo__sublabel">
            Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a>
          </div>
        </div>
        <div class="betpromo__cta">
          <img src="../assets/icons/ic_12_next.svg" alt=""/>
        </div>
      </a>
    
      <!-- Display only if betgame is displayed in iFrame -->
      <!--
      <div v-if="betsEditable && showOdds" class="betpromo betpromo--large">
        <div class="betpromo__logo">
          <div class="betpromo__logo__content">
            <img src="../assets/icons/ic_bwin.svg" alt="bwin Logo">
          </div>
        </div>
        <div class="betpromo__text">
          <div class="betpromo__label">
            Gewinne {{ totalOdds | formatNumber }}€
          </div>
          <div class="betpromo__sublabel">
            ... {{ $t('with_only_10_euro_stake') }}
          </div>
        </div>
        <div class="button buttom--small">Jetzt Wette abschließen</div>
      </div>
      -->
      
      
      <!-- Display only if betgame is displayed in iFrame -->
      <!--
      <div v-if="betsEditable && showOdds" class="bet-box-infotext">
        {{ $t('adult_legal_hint') }} | <a target="_blank" href="http://bzga.de/" style="color: darkgray">www.bzga.de</a>
      </div>
      -->
      
    </div>
</template>

<script>
import EditBetRow from '../components/EditBetRow'
import EditBetRowToto from '../components/EditBetRowToto'
import NavigationBar from './../components/NavigationBar'
import { isNumeric } from '../vuex/helpers'
import { DEFAULT_BET } from '@/main'
import moment from 'moment'

export default {
        name: 'BetMatchList',
        props: {
          matchday: {
            type: Number,
            default: () => {
              return 1
            }
          },
          selectedTab: {
            type: String,
            default: () => {
              return 'next10Matches'
            }
          },
          selectedBatgameTab: {
            type: String,
            default: () => {
              return 'matchdayMatches'
            }
          },
        },
        data () {
          return {
            componentKey: 0,
            parsedMatches: []
          }
        },
        components: {
          EditBetRow, NavigationBar, EditBetRowToto
        },
        created () {
            if(this.isBattle() || this.isTeamsBetgame()) {
              this.parsedMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
            }
            if (this.$store.state.teams.length === 0) {
              this.$store.dispatch('fetchTeams', this.$store.state.leagueId)
            }
            if (this.$store.state.matches.length === 0) {
                this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
            }
            if(this.$store.state.user) {
                this.$store.dispatch('fetchBets', this.$store.state.user.id)
            }
            if(this.$store.state.betgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user &&
              this.$store.state.user.isadult === true && this.$store.state.betgame.competition) {
              this.$store.dispatch('fetchMatchBets', this.$store.state.betgame.competition.id)
            }
            //this.matchday = this.$store.state.currentMatchday
        },
        mounted() {
          this.componentKey += 1
        },
        computed: {
          betmode() {
            return this.$store.state.betgame.bet_mode || 1
          },
          deepLink() {
          // https://sports.bwin.de/de/sports
          // https://sports.bwin.de/de/sports/addbets?ResultIDs=1128478629&GameIDs=378033583
  
            const tempMatches = this.matches
            if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
              return 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
            }
            var options = ''
            for (var i = 0; i < tempMatches.length; i++) {
              const match = tempMatches[i]
              if(isNumeric(match.goalshome) || isNumeric(match.goalsguest) || isNumeric(match.livegoalshome) || isNumeric(match.livegoalsguest)) {
                continue
              }
              if(this.isPast(match.matchdate)) {
                continue
              }
              const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, match.id)
              if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
                continue
              }
              const bwinbets = this.$store.getters.getMatchBet(match.id)
              if(bwinbets.length > 0) {
                const bwinbet =  bwinbets[0]
                if(isNumeric(bwinbet.bet_1) && isNumeric(bwinbet.bet_2) && isNumeric(bwinbet.bet_x)) {
                  options += bwinbet.bet_id + '-' + bwinbet.result_market_id + '-'
                  if(this.betmode === 1) {
                    if (bet.goalshome > bet.goalsguest) {
                      options += bwinbet.bet_1_id + ','
                    } else if (bet.goalshome < bet.goalsguest) {
                      options += bwinbet.bet_2_id + ','
                    } else if (bet.goalshome === bet.goalsguest) {
                      options += bwinbet.bet_x_id + ','
                    }
                  } else if(this.betmode === 2) {
                    if (bet.goalshome === 1) {
                      options += bwinbet.bet_1_id + ','
                    } else if (bet.goalshome === 2) {
                      options += bwinbet.bet_2_id + ','
                    } else if (bet.goalshome === 0) {
                      options += bwinbet.bet_x_id + ','
                    }
                  }
                }
              }
            }
            if(options.length > 0) {
              return 'https://sports.bwin.de/de/sports/fussball-4/wetten?stake=10&wm=5047188&options=' + options.substring(0, options.length - 1)
            } else {
              return 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
            }
          },
          totalOdds() {
            const tempMatches = this.matches
            if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
              return 0
            }
            var q = DEFAULT_BET
            for (var i = 0; i < tempMatches.length; i++) {
              const match = tempMatches[i]
              if(isNumeric(match.goalshome) || isNumeric(match.goalsguest) || isNumeric(match.livegoalshome) || isNumeric(match.livegoalsguest)) {
                continue
              }
              if(this.isPast(match.matchdate)) {
                continue
              }
              const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, match.id)
              if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
                continue
              }
              const bwinbets = this.$store.getters.getMatchBet(match.id)
              if(bwinbets.length > 0) {
                const bwinbet =  bwinbets[0]
                if(isNumeric(bwinbet.bet_1) && isNumeric(bwinbet.bet_2) && isNumeric(bwinbet.bet_x)) {
                  if(this.betmode === 1) {
                    if (bet.goalshome > bet.goalsguest) {
                      q *= bwinbet.bet_1
                    } else if (bet.goalshome < bet.goalsguest) {
                      q *= bwinbet.bet_2
                    } else if (bet.goalshome === bet.goalsguest) {
                      q *= bwinbet.bet_x
                    }
                  } else if(this.betmode === 2) {
                    if (bet.goalshome === 1) {
                      q *= bwinbet.bet_1
                    } else if (bet.goalshome === 2) {
                      q *= bwinbet.bet_2
                    } else if (bet.goalshome === 0) {
                      q *= bwinbet.bet_x
                    }
                  }
                }
              }
            }
            if(isNaN(q)) {
              return DEFAULT_BET
            }
            return Math.round(q * 100) / 100
          },
          lastBetSaveSuccess() {
            return this.$store.state.lastBetSaveSuccess
          },
          matches () {
            if(this.isBattle()) {
              //const m = JSON.parse(JSON.stringify(this.$store.state.matches))
              if(this.selectedTab === 'nextMatches') {
                const d = moment().add(7, 'days')
                const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSameOrBefore(d, 'days') && this.parseMatchdate(t.matchdate).isAfter(moment(), 'days'))
                if(filtered.length > 0) {
                  const sorted = filtered.sort(function (a, b) {
                    return  a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted 
                } else {
                  const sorted = this.parsedMatches.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted.slice(0, 10)
                }
              } else if(this.selectedTab === 'lastMatches') {
                const d = moment().subtract(7, 'days')
                const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSameOrAfter(d, 'days') && this.parseMatchdate(t.matchdate).isBefore(moment(), 'days'))
                if(filtered.length > 0) {
                  const sorted = filtered.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted
                } else {
                  const sorted = this.parsedMatches.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted.slice(Math.max(sorted.length - 10, 0))
                }
              } else if(this.selectedTab === 'next10Matches') {
                const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isAfter(moment(), 'days'))
                if(filtered.length > 0) {
                  const sorted = filtered.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted.slice(0, 10)
                } else {
                  const sorted = this.parsedMatches.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted.slice(0, 10)
                }
              } else if(this.selectedTab === 'last10Matches') {
                const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isBefore(moment(), 'days'))
                if(filtered.length > 0) {
                  const sorted = filtered.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted.slice(Math.max(sorted.length - 10, 0))
                } else {
                  const sorted = this.parsedMatches.sort(function (a, b) {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  })
                  return sorted.slice(Math.max(sorted.length - 10, 0))
                }
              } else if(this.selectedTab === 'todaysMatches') {
                const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
                const sorted = filtered.sort(function (a, b) {
                  return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                })
                return sorted
              }else {
                const t = this.parsedMatches.sort(function (a, b) {
                  return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                })
                return t
              }
            } else if(this.isTeamsBetgame()) {
              const allmatches = this.parsedMatches //JSON.parse(JSON.stringify(this.$store.state.matches))
              if(this.selectedTab === 'todaysMatches') {
                const filtered = allmatches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
                const sorted = filtered.sort(function (a, b) {
                  return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                })
                return sorted
              } else if (parseInt(this.selectedTab) > 0) {
                const selectedcompetition = this.$store.state.competitions.filter(t => t.heimspielid === this.selectedTab)
                const filteredmatches = allmatches.filter(t => t.fk_competition === selectedcompetition[0].id)
                const sortedmatches = filteredmatches.sort(function (a, b) {
                  return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                })
                return sortedmatches
              }
              return []
            } else {
              if (this.selectedBatgameTab === 'matchdayMatches') {
                const m = this.$store.getters.getMatchesByMatchday(this.matchday).sort(function (a, b) {
                  if (a.status && (b.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' ||
                      a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung' || a.status.trim() === 'nicht ausgetragen')) {
                    return 1
                  } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' ||
                      b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung' || b.status.trim() === 'nicht ausgetragen')) {
                    return -1
                  }
                  return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                })
                return m
              } else {
                const m = this.$store.getters.getTodaysMatches.sort(function (a, b) {
                  if (a.status && (b.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' ||
                      a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung' || a.status.trim() === 'nicht ausgetragen')) {
                    return 1
                  } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' ||
                      b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung' || b.status.trim() === 'nicht ausgetragen')) {
                    return -1
                  }
                  if(Date.parse(a.matchdate) === Date.parse(b.matchdate)) {
                    return a.matchday - b.matchday
                  } else {
                    return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
                  }
                })
                return m
              }
            }
          },
          matchdayCount () {
            return this.$store.state.matchdayCount
          },
          showOdds() {
            return false
            /*
            if(this.$store.state.betgame && this.$store.state.user) {
              if(!this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true) {
                const tempMatches = this.matches
                if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
                  return false
                }
                for (var i = 0; i < tempMatches.length; i++) {
                  const match = tempMatches[i]
                  if((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
                      (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
                    continue
                  }
                  const r = this.$store.getters.getMatchBet(match.id)
                  const available = r !== null && r !== undefined && r.length > 0
                  if(available) {
                    const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, match.id)
                    if (bet && this.isValidBwinBet(r[0]) && this.isNumeric(bet.goalshome) && this.isNumeric(bet.goalsguest)) {
                      return true
                    }
                  }
                }
              }
            }
            return false
            */
          },
          betsEditable () {
            const tempMatches = this.matches
            if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
              return false
            }
            for (var i = 0; i < tempMatches.length; i++) {
              var match = tempMatches[i]
              if (this.isFuture(match.matchdate) && !this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
                !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
                return true
              }
            }
            return false
          },
        },
        methods: {
          deepLinkClicked() {
            if(this.deepLink) {
              this.$gtag.event('Kombiwette', {
                'event_category': 'bwin',
                'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
                'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
                'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
              })
              if(window.parent) {
                window.parent.open(this.deepLink, '_blank')
              } else {
                window.open(this.deepLink, '_blank')
              }
            }
          },
            openMatchdaySelect() {
                this.$emit('opemMatchdaySelect', {})
            },
            openMenu() {
              this.$modal.show('betgame-menu')
            },
            guestInputFocusLost (matchIndex) {
                const i = matchIndex / 2 + 1
                if (i < this.matches.length) {
                    const nextMatch = this.matches[i]
                    const nextRow = this.$refs[nextMatch.id]
                    if (nextRow.length > 0) {
                        const nextInput = nextRow[0].$refs['bet_input_home']
                        nextInput.focus()
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button {
        background: var(--color-button);
        font-size: 17px;
        font-weight: 500;
        border: 0;
        border-radius: 6px;
        padding: 14px 19px;
        a {
            color: white;
            text-decoration: none;
        }
    }
    small {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: $colorGreyMid;
        text-align: center;
        padding: 4px 0;
    }
    
    ul {
        list-style: none;
    }
    
    .betMatchList {
        display: block;
    }
    
    .col_team {
        width: 44%;
    }
    
    .col_goal {
        width: 5%;
    }
    
    .col_center {
        width: 2%;
    }

    .bet-kombibox {
        background: $colorWhite;
        box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
        border-radius: 11px;
        text-align: center;
        padding: 16px 16px 8px 16px;
        position: relative;
        max-width: 100%;

        &:before {
            content: '-W-';
            position: absolute;
            right: 2px;
            top: 8px;
            text-align: right;
            font-size: 9px;
            color: rgba($colorGreyLight, .18);
        }

        &-label {
            font-weight: 500;
            color: $colorGreyDark;
            font-size: 17px;
            margin-bottom: -2px;
        }

        &-sublabel {
            color: $colorGreyMid;
            font-size: 14px;
            margin-bottom: 10px;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-top: -4px;
                margin-left: 3px;
            }
        }
    }

    .bet-presented {
        color: $colorGreyMid;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    
        img {
            margin-top: -4px;
            margin-left: 3px;
        }
    }

    .bet-box {
        display: flex;
        flex-wrap: wrap;
        padding: 0 8px;
        margin: 0px auto 26px;
        justify-content: space-between;
        max-width: 592px;
        position: relative;
        &-infotext {
            font-size: 9px;
            color: $colorGreyMid;
            text-align: center;
            width: 100%;
            margin-top: 7px;
        }
    }

    .betpromo {
      display: flex;
      align-items: center;
      position: relative;
      background: $colorWhite;
      box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
      border-radius: 4px;
      padding: 8px;
      text-decoration: none;

      &:before {
        content: '-W-';
        position: absolute;
        right: 2px;
        top: 2px;
        text-align: right;
        font-size: 9px;
        color: rgba($colorGreyLight, .18);
      }

      &--sticky-withnav {
        position: sticky;
        bottom: 52px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 2px 8px 0 rgba(0,0,0,0.08), 0 12px 24px 0 rgba(49,49,55,0.08);
        cursor: pointer;
        margin: 0 -8px -52px;
        opacity: 0;
        transform: translateY(52px);
        transition: all .4s ease-in-out;

        &.betpromo--visible {
          margin-bottom: 0;
          transform: translateY(0);
          opacity: 1;
        }
      }

      &--sticky {
        position: sticky;
        bottom: 12px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 2px 8px 0 rgba(0,0,0,0.08), 0 12px 24px 0 rgba(49,49,55,0.08);
        cursor: pointer;
        margin: 0 -8px -52px;
        opacity: 0;
        transform: translateY(52px);
        transition: all .4s ease-in-out;

        &.betpromo--visible {
          margin-bottom: 0;
          transform: translateY(0);
          opacity: 1;
        }
      }

      &--small {
        margin-bottom: 24px;
        cursor: pointer;
        margin-top: calc(-52px + -24px);
        opacity: 0;
        transition: all .4s ease-in-out;

        &.betpromo--visible {
          margin-top: 0;
          opacity: 1;
        }
      }

      &--large {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 16px 16px;
        margin-top: 40px;

        &:before {
          top: 12px;
        }
      }

      &__logo {

        &__content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 36px;
          background: #000000;
          border-radius: 3px;

          .betpromo--large & {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin: -24px 0 16px;
          }
        }
      }

      &__text { 
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        text-align: center;
      }

      &__cta {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background: #FFCB04;
        margin: 0 6px 0 8px;
      }

      &__label {
        font-size: 15px;
        font-weight: 500;
        color: #000000;

        .betpromo--large & {
          font-size: 17px;
          margin-bottom: 4px;
        }
      }

      &__sublabel, a {
        font-size: 9px;
        font-weight: 400;
        color: rgba($colorGreyDark, .48);
        margin-top: 3px;
        padding-left: 10px;
        padding-right: 5px;
        
        .betpromo--large & {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }

      .button {
        font-size: 14px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        background: #FFCB04;
        color: #000000;
      }
    }
</style>
