<template>
  <div class="betgameEditMatchdayWinners" id="betgameEditMatchdayWinners">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('betgame_settings.matchday_winners') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <table id="bg_users_table" class="table">
      <tbody>
        <tr v-on:click="matchdaywinClicked(matchdaywin)" v-for="(matchdaywin, index) in matchdayWinsSorted">
          <td style="width: 100%">{{ matchdaywin.matchday }}. {{ $t('matchday_short') }}</td>
          <td><div class="table-view-cell-drilldown"></div></td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'
import Vue from 'vue'

export default {
  name: 'BetgameEditMatchdayWinners',
  components: { NavigationBar },
  data () {
    return {
    }
  },
  created () {
    if(this.$store.state.betgameId && parseInt(this.$store.state.betgameId) > 0) {
      if (this.$store.state.leagueId && !this.isTournament()) {
        Vue.$log.debug('BetgameEditMatchdayWinners created fetchMatchdayWins ' + this.$store.state.betgameId)
        this.$store.dispatch('fetchMatchdayWins', this.$store.state.betgameId).then(() => {
        })
      } 
    }
  },
  mounted () {
    if(this.$store.state.betgame) {
      this.$store.dispatch('fetchUserBetgamePoints', this.$store.state.betgame.id)
    }
  },
  computed: {
    matchdayWinsSorted() {
      const sorted = this.$store.state.matchDayWins.sort(function (a, b) {
        return a.matchday - b.matchday
      })
      var grouped = []
      for (let i = 0; i < sorted.length; i++) {
        if (i === 0 || sorted[i].matchday !== sorted[i - 1].matchday) {
          grouped.push({ matchday: sorted[i].matchday })
        }
      }
      return grouped
    }
  },
  methods: {
    matchdaywinClicked(matchday) {
      this.$router.push({ name: 'betgameeditmatchdaywinner', params: { matchday: matchday }})
    },
    goBack () {
      this.$router.push('/betgameedit')
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    margin-top: 16px !important;

    @media screen and (min-width: 768px) {
      max-width: 576px;
      margin: 24px auto 0 !important;
      border-radius: 11px;
      padding: 16px;
      background: $colorWhite;
      box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
    }

    td {
      cursor: pointer;
    }
  }
</style>
