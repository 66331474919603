<template>
  <div class="chat__answer" v-on:click="answerClicked">
    <div class="chat__answer__text">
      <span>{{ username }}</span>
      <p>{{ text }}</p>
      <p v-if="chatItem && chatItem.embeds && imageEmbeds.length > 0 && !itemDeleted"><img src="@/assets/chat/ic_20_camera.svg">Foto</p>
      <p v-if="chatItem && chatItem.embeds && fileEmbeds.length > 0 && !itemDeleted">{{ fileEmbeds[0].filename }}</p>
      <p v-else-if="chatItem && chatItem.embeds && imageEmbeds.length > 0 && itemDeleted">{{ $t('feed.message_deleted') }}</p>
    </div>
    <img v-if="chatItem && chatItem.embeds && imageEmbeds.length > 0 && !itemDeleted" :src="fileURL(imageEmbeds[0].uri)">
    <div v-else-if="chatItem && chatItem.embeds && fileEmbeds.length > 0 && !itemDeleted" class="chat__answer__file"></div>
    <div v-if="closeable" v-on:click="answerClosed" class="chat__answer__close"></div>
  </div>
</template>

<script>
  	import { CHAT_TEAMTIP } from '@/main'

    export default {
	    name: 'ChatAnswer',
	    props: {
        username: String,
        text: String,
        closeable: Boolean,
        chatItem: Object
	    },
    	data () {
 			return {}
    	},
    	beforeCreate () {
    	},
    	methods: {
        fileURL(file) {
          return CHAT_TEAMTIP + file
        },
        answerClicked() {
          this.$emit('answerClicked', {  })
        },
        answerClosed(emoji) {
          this.showAllEmojis = false
          this.$emit('answerClosed', { } )
        },
      },
    	computed: {
        imageEmbeds() {
          return this.chatItem.embeds.filter((embed) => {
            return embed.mimetype.startsWith('image/')
          })
        },
        fileEmbeds() {
          return this.chatItem.embeds.filter((embed) => {
            return !embed.mimetype.startsWith('image/')
          })
        },
        itemDeleted() {
          return this.chatItem && this.chatItem.status === -1
        },
      }
  	}
</script>

<style lang="scss">
  .chat__answer {
    display: flex;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 8px -8px 8px -8px;
    background: rgba(var(--color-primary-rgb), .08);
    padding: 12px 8px 10px 16px;
    border-radius: 4px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      background: rgba(var(--color-primary-rgb), .12);
      cursor: pointer;
    }

    /* If displayed inside input field */
    .chat__input & {
      border-radius: 12px;
      margin: 0 12px 12px;
      z-index: 2;
    }

    /* If displayed inside chatitem from sender */
    .chat__item--sender & {
      background: rgba($colorWhite, .24);

      &:before {
        background: $colorWhite;
      }
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background: var(--color-primary);
    }

    &__close {
      width: 32px;
      height: 32px;
      top: 4px;
      right: 4px;
      position: absolute;
      cursor: pointer;

      &:hover {
        &:before, &:after {
          background: #000;
        }
      }

      &:before, &:after {
        position: absolute;
        content: '';
        top: calc(50% - .75px);
        left: calc(50% - 6px);
        width: 12px;
        height: 1.5px;
        transform: rotate(45deg);
        background: $colorGreyDark;
        transition: all .2s ease-in-out;
      }

      &:before {
        transform: rotate(-45deg);
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      span {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: var(--color-primary);
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        /* If displayed inside chatitem from sender */
        .chat__item--sender & {
          color: $colorWhite;
        }
      }

      p {
        display: flex;
        font-size: 14px !important;
        line-height: 18px !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        img {
          width: 16px;
          height: 16px;
          margin: -3px 4px -1px 0;
          transform: translateY(2px)
        }
      }
    }

    > img {
      width: 48px;
      height: 48px;
      margin: -4px 0;
      border-radius: 4px;
      object-fit: cover;
      margin-left: 8px;

      .chat__input & {
        margin-right: 24px;
      }
    }

    &__file {
      width: 40px;
      height: 40px;
      padding: 10px;
      box-sizing: border-box;
      content: url('../../assets/chat/ic_20_file_primary.svg');
      background: $colorWhite;
      border-radius: 4px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

      .chat__input & {
        margin-right: 24px;
      }
    }
  }
</style>
