<template>
  <div class="betgameEditMtchdayWinner" id="betgameEditMtchdayWinner">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        <div class="navigation_title--headline">
          {{ $t('betgame_settings.matchday_winner') }}
        </div>
        <div class="navigation_title--subline">
          {{ matchday.matchday }}. {{ $t('matchday_short') }}
        </div>
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div v-for="(matchdaywinner, index) in matchdayWinners" class="input-container">
      <div class="input-element">
        <input v-model="matchdaywinner.user_name" type="email" name="usernickname" id="usernickname" placeholder="usernickname" value disabled />
        <label for="usernickname">{{ $t('user_name') }}</label>
      </div>
      <div class="input-element">
        <input v-on:click="coypEmailClicked" v-clipboard:copy="matchdaywinner.user_email" v-model="matchdaywinner.user_email" type="email" name="useremail" id="useremail" placeholder="useremail" value />
        <label for="useremail">{{ $t('email') }}</label>
      </div>
    </div>



  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'

export default {
  name: 'BetgameEditMtchdayWinner',
  components: { NavigationBar },
  data () {
    return {
      matchday: 0
    }
  },
  created () {
    this.matchday = this.$route.params.matchday
  },
  mounted () {
    if (this.matchday === null || this.matchday === 0) {
      this.goBack()
    }
  },
  computed: {
    matchdayWinners() {
      const t = this.$store.state.matchDayWins.filter((matchdayWin) => {
        return matchdayWin.matchday === this.matchday.matchday
      })
      for(let i = 0; i < t.length; i++) {
        t[i].user_email = this.getUserEmail(t[i].fk_user)
      }
      return t
    }
  },
  methods: {
    getUserEmail(user) {
      const t = this.$store.state.betgameUsers.filter((u) => {
        return u.fk_user === user
      })
      if (t.length > 0) {
        return t[0].email
      }
    },
    coypEmailClicked() {
      this.$toast.open({ message:  this.$i18n.t('betgame_settings.email_copied_to_clipboard'), position: 'top', duration: 1000, type: 'info' })
    },
    contactUser () {
      if(this.betgameUser && this.betgameUser.email) {
        window.location.href = 'mailto:' + this.betgameUser.email
      }
    },
    goBack () {
      this.$router.push('/betgameeditmatchdaywinners')
    },
    isNullOrUndefined (data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  margin: 16px auto 0;
}

button {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
