<template>
	<div class="leaguebetgame">

		<!-- HEADER -->
    <div class="header" id="header">
      <div class="container">

        <nav>
          <div class="navigation-logo">
            <a href="https://teamtip.net">
              <img src="../assets/landingpage/logo-teamtip.svg" alt="">
            </a>
            <div class="navigation-divider"></div>
            <a href="https://www.sportplatz-media.com" target="_blank">
              <img src="../assets/landingpage/logo-sportplatzmedia-2022.svg" alt="">
            </a>
          </div>
          <ul class="navigation-list">
            <li v-if="!this.isApp()" class="navigation-list-item">
              <a href="/tipprunde/1/kostenlose-bundesliga-tipprunde">{{ $t('home.bundesliga') }}</a>
            </li>
            <li v-if="!this.isApp()" class="navigation-list-item">
              <a href="/#whitelabel_id">{{ $t('home.whitelabel') }}</a>
            </li>
            <li class="navigation-list-language">
              <a href="#" @click="languageSelected( 'de')" v-bind:class="{ 'navigation-list-language-item--selected': selectedLanguage === 'de' }" 
                 class="navigation-list-language-item">
                <img src="../assets/icons/ic_flag_germany.svg" class="navigation-list-language-flag">
              </a>
              <a href="#" @click="languageSelected( 'en')" v-bind:class="{ 'navigation-list-language-item--selected': (!selectedLanguage || selectedLanguage !== 'de') }" 
                 class="navigation-list-language-item">
                <img src="../assets/icons/ic_flag_england.svg" class="navigation-list-language-flag">
              </a>
            </li>
            <router-link to="/login" class="router_link">
              <li class="navigation-list-button">
                <LoginIcon></LoginIcon>
                Login
              </li>
            </router-link>
          </ul>
        </nav>

        <div class="header__headline">
          <span>{{ this.leagueName }}</span>
          <h1>{{ $t('league_betgame.header_title') }}<br>{{ this.leagueName }}!</h1>
          <ul class="leaguebullets">
          	<li>{{ $t('league_betgame.header_for_free') }}</li>
          	<li>{{ $t('league_betgame.header_unlimited_players') }}</li>
          	<li>{{ $t('league_betgame.header_app_and_web') }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="leaguebetgame__button">
      <div class="container">
        <router-link to="/createuserbetgame" class="button button-white">
          <img src="../assets/icons/ic_12_plus_blue.svg" class="button-icon--left">
          {{ $t('home.create_betgame') }}
        </router-link>
      </div>
    </div>

    <div class="leaguebetgame__content">
    	<div class="container">
  			<div class="textblock textblock--imageRight textblock--mobileImageTop">
          <div class="textblock__text">
            <h3>{{ this.leagueName }}</h3>
            <p v-if="this.league === 1">{{ $t('league_betgame.1_bundesliga') }}</p>
            <p v-else-if="this.league === 2">{{ $t('league_betgame.2_bundesliga') }}</p>
            <p v-else-if="this.league === 3">{{ $t('league_betgame.3_bundesliga') }}</p>
            <p v-else-if="this.league === 13">{{ $t('league_betgame.bundesliga_at') }}</p>
            <p v-else-if="this.league === 14">{{ $t('league_betgame.woman_bundesliga') }}</p>
            <p v-else-if="this.league === 19">{{ $t('league_betgame.champions_league') }}</p>
            <p v-else-if="this.league === 132">Europa League</p>
            <p v-else-if="this.league === 6395">Europa Conference League</p>
            <p v-else-if="this.league === 91">{{ $t('league_betgame.premier_league_eng') }}</p>
            <p v-else-if="this.league === 97">{{ $t('league_betgame.la_liga_esp') }}</p>
            <p v-else-if="this.league === 10700">{{ $t('league_betgame.em_football') }}</p>
            <p v-else-if="this.league === 36">{{ $t('league_betgame.em_football') }}</p>
            <p v-else-if="this.league === 111">{{ $t('league_betgame.serie_a_ita') }}</p>
            <p v-else-if="this.league === 115">{{ $t('league_betgame.super_league_swi') }}</p>
            <p v-else-if="this.league === 116">{{ $t('league_betgame.super_lig_tur') }}</p>
            <p v-else-if="this.league === 71">{{ $t('league_betgame.ligue_1_fra') }}</p>
            <p v-else-if="this.league === 76">{{ $t('league_betgame.otp_bank_liga_hu') }}</p>
            <p v-else-if="this.league === 87">{{ $t('league_betgame.olypic_tournament_men') }}</p>
            <p v-else-if="this.league === 1379">{{ $t('league_betgame.olypic_tournament_women') }}</p>
            <p v-else-if="this.league === 533">{{ $t('league_betgame.em_handball') }}</p>
            <p v-else-if="this.league === 139">{{ $t('league_betgame.wm_football') }}</p>
            <p v-else-if="this.league === 1110">{{ $t('league_betgame.women_wm_football') }}</p>
            <p v-else-if="this.league === 1180">{{ $t('league_betgame.em_football_woman') }}</p>
            <p v-else-if="this.league === 107"></p>
            <p v-else-if="this.league === 564"></p>
            <p v-else-if="this.league === 2255"></p>
            <p v-else-if="this.league === 2256"></p>
            <p v-else-if="this.league === 2257"></p>
            <p v-else-if="this.league === 4228"></p>
            <p v-else-if="this.league === 588">{{ $t('league_betgame.wm2026_football_europe_quali') }}</p>
            <p v-else-if="this.league === 1235">{{ $t('league_betgame.klubwm') }}</p>
            <div class="button-group">
            	<router-link to="/createuserbetgame" class="button button-inline">
            		<img src="../assets/icons/ic_12_plus_white.svg" class="button-icon--left">
                {{ $t('home.create_betgame') }}
            	</router-link>
            </div>
          </div>
          <div class="textblock__image">
            <img v-if="this.league === 1" src="../assets/wettbewerb/Bundesliga_1_400x416.jpg" srcset="../assets/wettbewerb/Bundesliga_1_400x416@2x.jpg 2x" alt="">
            <img v-else-if="this.league === 2" src="../assets/wettbewerb/Bundesliga_2_400x416.jpg" srcset="../assets/wettbewerb/Bundesliga_2_400x416@2x.jpg 2x" alt="">
            <img v-else-if="this.league === 36" src="../assets/wettbewerb/EM2024.png" srcset="../assets/wettbewerb/EM2024@2x.png 2x" alt="">
            <img v-else-if="this.league === 139" src="../assets/wettbewerb/WM22_400x416.jpg" srcset="../assets/wettbewerb/WM22_400x416@2x.jpg 2x" alt="">
            <img v-else-if="this.league === 1110" src="../assets/wettbewerb/WM22_400x416.jpg" srcset="../assets/wettbewerb/WM22_400x416@2x.jpg 2x" alt="">
            <img v-else src="../assets/wettbewerb/Bundesliga_1_400x416.jpg" srcset="../assets/wettbewerb/Bundesliga_1_400x416@2x.jpg 2x" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="appdownload">
    	<div class="container">
	    	<h2>{{ $t('league_betgame.download_app_title') }}</h2>
	    	<p>{{ $t('league_betgame.download_app_description') }}</p>
	    	<div class="appdownload__buttons">
          <a href="https://apps.apple.com/de/app/teamtip/id1496608495" target="_blank">
	        <div class="download__button">
            <div class="download__button__icon">
              <img src="@/assets/landingpage/ic_appstore_apple.svg" alt="Download Apple AppStore" />
            </div>
            <div class="download__button__text">
              Download on the
              <span>AppStore</span>
            </div>
	        </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.sportplatzmedia.teamtip" target="_blank">
            <div class="download__button">
              <div class="download__button__icon">
                <img src="@/assets/landingpage/ic_appstore_google.svg" alt="Download Apple AppStore" />
              </div>
              <div class="download__button__text">
                Get it on
                <span>GooglePlay</span>
              </div>
            </div>
          </a>
	      </div>
	    </div>
    </div>

    <!-- App vs. Web -->
    <div class="highlight">
      <div class="container">
        <div class="highlight__content">
          <div class="highlight__text">
            <h2>{{ $t('league_betgame.app_web_title') }}</h2>
            <p>{{ $t('league_betgame.app_web_description') }}</p>
            <div class="button-group">
              <router-link to="/createuserbetgame" class="button">
                <img src="../assets/landingpage/ic_24_add.svg" class="button-icon--left">{{ $t('home.create_betgame') }}
              </router-link>
            </div>
          </div>
          <!--<div class="highlight__image">
            <img v-if="this.league === 1" src="../assets/images/leaguebetgame/1_3.jpg" alt="">
            <img v-else-if="this.league === 2" src="../assets/images/leaguebetgame/2_3.jpg" alt="">
            <img v-else-if="this.league === 139" src="../assets/images/leaguebetgame/139_3.jpg" alt="">
            <img v-else src="../assets/images/leaguebetgame/1_3.jpg" alt="">
          </div>-->
        </div>
      </div>
    </div>

    <div class="leaguebetgame__content">
      <div class="container">
        <div class="textblock textblock--imageLeft textblock--mobileImageTop">
          <div class="textblock__text">
            <h3>{{ $t('league_betgame.whitelabel_header') }}</h3>
            <p>{{ $t('league_betgame.whitelabel_description') }}</p>
            <ul class="whitelabel-list">
              <li>{{ $t('league_betgame.whitelabel_list_1') }}</li>
              <li>{{ $t('league_betgame.whitelabel_list_2') }}</li>
              <li>{{ $t('league_betgame.whitelabel_list_3') }}</li>
              <li>{{ $t('league_betgame.whitelabel_list_4') }}</li>
              <li>{{ $t('league_betgame.whitelabel_list_5') }}</li>
            </ul>
            <div class="button-group">
              <a href="mailto:kontakt@teamtip.net" class="button button-inline">
                <img src="../assets/icons/ic_12_plus_white.svg" class="button-icon--left">
                {{ $t('league_betgame.whitelabel_betgame_for_companies') }}
              </a>
            </div>
          </div>
          <div class="textblock__image">
            <img v-if="this.league === 1" src="../assets/wettbewerb/Bundesliga_1_Whitelabel_400x416.jpg" srcset="../assets/wettbewerb/Bundesliga_1_Whitelabel_400x416@2x.jpg 2x" alt="">
            <img v-else-if="this.league === 2" src="../assets/wettbewerb/Bundesliga_2_Whitelabel_400x416.jpg" srcset="../assets/wettbewerb/Bundesliga_2_Whitelabel_400x416@2x.jpg 2x" alt="">
            <img v-else-if="this.league === 139" src="../assets/wettbewerb/WM22_Whitelabel_400x416.jpg" srcset="../assets/wettbewerb/WM22_Whitelabel_400x416@2x.jpg 2x" alt="">
            <img v-else-if="this.league === 1110" src="../assets/wettbewerb/WM22_Whitelabel_400x416.jpg" srcset="../assets/wettbewerb/WM22_Whitelabel_400x416@2x.jpg 2x" alt="">
            <img v-else src="../assets/wettbewerb/Bundesliga_1_Whitelabel_400x416.jpg" srcset="../assets/wettbewerb/Bundesliga_1_Whitelabel_400x416@2x.jpg 2x" alt="">
          </div>
        </div>
      </div>
    </div>

	</div>
</template>

<script>

import Vue from 'vue'
import Numeral from 'numeral'
import moment from 'moment'
import LoginIcon from './../components/icons/LoginIcon'

export default {
  name: 'LeagueBetgame',
  components: {
    LoginIcon
  },
  data() {
    return {
      league: 0,
      leagueName: '',
      description: '',
      keywords: ''
    }
  },
  metaInfo () {
    return {
      title: this.leagueName + ' Tippspiel',
      meta: [
        { vmid: 'description', name: 'description', content: this.description },
        { vmid: 'keywords', name: 'keywords', content: this.keywords }
      ]
    }
  },
  created() {
    if(this.$route.params && this.$route.params.id && parseInt(this.$route.params.id) > 0) {
      this.league = parseInt(this.$route.params.id)
      if(this.available) {
        this.setLeagueName()
        this.setDescription()
      } else {
        if(this.$store.getters.loggedIn) {
          this.$router.push('/mybetgames')
        } else {
          this.$router.push('/').then(() => {
            window.location.reload()
          })
        }
      }
    } else {
      if(this.$store.getters.loggedIn) {
        this.$router.push('/mybetgames')
      } else {
        this.$router.push('/').then(() => {
          window.location.reload()
        })
      }
    }
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.language
    },
    available() {
      if(this.league === 1) {
        return true
      } else if(this.league === 2) {
        return true
      } else if(this.league === 3) {
        return true
      } else if(this.league === 13) {
        return true
      } else if(this.league === 14) {
        return true
      } else if(this.league === 19) {
        return true
      } else if(this.league === 132) {
        return true
      } else if(this.league === 6395) {
        return true
      } else if(this.league === 33) {
        return true
      } else if(this.league === 71) {
        return true
      } else if(this.league === 76) {
        return true
      } else if(this.league === 91) {
        return true
      } else if(this.league === 97) {
        return true
      } else if(this.league === 10700) {
        return false
      } else if(this.league === 36) {
        return false
      } else if(this.league === 111) {
        return true
      } else if(this.league === 115) {
        return true
      } else if(this.league === 116) {
        return true
      } else if(this.league === 533) {
        return false
      } else if(this.league === 139) {
        return false
      } else if(this.league === 1110) {
        return false
      } else if(this.league === 1180) {
        return true
      } else if(this.league === 107) {
        return true
      } else if(this.league === 564) {
        return true
      } else if(this.league === 2255) {
        return true
      } else if(this.league === 2256) {
        return true
      } else if(this.league === 2257) {
        return true
      } else if(this.league === 188) {
        return false
      } else if(this.league === 189) {
        return false
      } else if(this.league === 87) {
        return true
      } else if(this.league === 1379) {
        return true
      } else if(this.league === 4228) {
        return true
      } else if(this.league === 588) {
        return true
      } else if(this.league === 1235) {
        return true
      }
      return false
    },
  },
  mounted () {
    if(this.league === 1) {
      document.body.classList.add('bundesliga');
    } else if(this.league === 2) {
      document.body.classList.add('bundesliga2');
    } else if(this.league === 139) {
      document.body.classList.add('weltmeisterschaft');
    } else if(this.league === 1110) {
      document.body.classList.add('weltmeisterschaft');
    }
  },
  methods: {
    languageSelected(lang) {
      this.$store.dispatch('setLanguage', lang)
      .then(() => {
        Vue.$log.debug('languageSelected ok + ' + this.$store.state.language)
        this.$i18n.locale = lang
        Numeral.locale(lang)
        moment.locale(lang)
      }).catch(function (error) {
        Vue.$log.debug('languageSelected error ' + error)
      })
    },
    setLeagueName() {
      if(this.league === 1) {
        this.leagueName = '1. Bundesliga'
      } else if(this.league === 2) {
        this.leagueName = '2. Bundesliga'
      } else if(this.league === 3) {
        this.leagueName = '3. Liga'
      } else if(this.league === 13) {
        this.leagueName = this.$i18n.t('create_betgame.competition_bundesliga_austria')
      } else if(this.league === 14) {
        this.leagueName = this.$i18n.t('create_betgame.competition_bundesliga_women')
      } else if(this.league === 19) {
        this.leagueName = 'Champions League'
      } else if(this.league === 132) {
        this.leagueName = 'Europa League'
      } else if(this.league === 6395) {
        this.leagueName = 'Europa Conference League'
      } else if(this.league === 33) {
        this.leagueName = 'DFB-Pokal'
      } else if(this.league === 91) {
        this.leagueName = 'Premier League'
      } else if(this.league === 97) {
        this.leagueName = 'La Liga'
      } else if(this.league === 10700) {
        this.leagueName = 'EURO 2020'
      } else if(this.league === 36) {
        this.leagueName = 'EURO 2024'
      } else if(this.league === 111) {
        this.leagueName = 'Serie A'
      } else if(this.league === 115) {
        this.leagueName = this.$i18n.t('create_betgame.competition_superleague_swiss')
      } else if(this.league === 116) {
        this.leagueName = 'SüperLig'
      } else if(this.league === 71) {
        this.leagueName = 'Ligue 1'
      } else if(this.league === 76) {
        this.leagueName = this.$i18n.t('create_betgame.competition_league_hungary')
      } else if(this.league === 533) {
        this.leagueName = 'Handball EM'
      } else if(this.league === 139) {
        this.leagueName = this.$i18n.t('create_betgame.competition_2022_fifa_world_cup')
      } else if(this.league === 1110) {
        this.leagueName = this.$i18n.t('create_betgame.competition_2023_fifa_women_world_cup')
      } else if(this.league === 1180) {
        this.leagueName = this.$i18n.t('create_betgame.competition_uefa_women_euro_2022')
      } else if(this.league === 107) {
        this.leagueName = 'Regionalliga Nord'
      } else if(this.league === 564) {
        this.leagueName = 'Regionalliga West'
      } else if(this.league === 2255) {
        this.leagueName = 'Regionalliga Nordost'
      } else if(this.league === 2256) {
        this.leagueName = 'Regionalliga Südwest'
      } else if(this.league === 2257) {
        this.leagueName = 'Regionalliga Bayern'
      } else if(this.league === 87) {
        this.leagueName = this.$i18n.t('create_betgame.competition_olypic_tournament_men')
      } else if(this.league === 1379) {
        this.leagueName = this.$i18n.t('create_betgame.competition_olypic_tournament_women')
      } else if(this.league === 4228) {
        this.leagueName = 'Nations League'
      } else if(this.league === 588) {
        this.leagueName = this.$i18n.t('league_betgame.wm2026_football_europe_quali')
      } else if(this.league === 1235) {
        this.leagueName = this.$i18n.t('league_betgame.klubwm')
      }
      if(this.leagueName) {
        this.keywords = this.leagueName + ', Tippspiel, Kostenlos, Tipprunde, Freunde, Familie, Kollegen, Unbegrenzte Teilnehmerzahl, Live-Ergebnisse, Web, App, iOS, Android'
        try {
          document.title = this.leagueName + ' Tippspiel'
        } catch (e) {
        }
      }
    },
    setDescription() {
      this.description = ''
      if(this.league === 1) {
        this.description = this.$i18n.t('league_betgame.1_bundesliga')
      } else if(this.league === 2) {
        this.description = this.$i18n.t('league_betgame.2_bundesliga')
      } else if(this.league === 3) {
        this.description = this.$i18n.t('league_betgame.3_bundesliga')
      } else if(this.league === 13) {
        this.description = this.$i18n.t('league_betgame.bundesliga_at')
      } else if(this.league === 14) {
        this.description = this.$i18n.t('league_betgame.woman_bundesliga')
      } else if(this.league === 19) {
        this.description = this.$i18n.t('league_betgame.champions_league')
      } else if(this.league === 132) {
        this.description = ''
      } else if(this.league === 6395) {
        this.description = ''
      } else if(this.league === 91) {
        this.description = this.$i18n.t('league_betgame.premier_league_eng')
      } else if(this.league === 97) {
        this.description = this.$i18n.t('league_betgame.la_liga_esp')
      } else if(this.league === 10700) {
        this.description = this.$i18n.t('league_betgame.em_football')
      } else if(this.league === 36) {
        this.description = this.$i18n.t('league_betgame.em_football')
      } else if(this.league === 111) {
        this.description = this.$i18n.t('league_betgame.serie_a_ita')
      } else if(this.league === 115) {
        this.description = this.$i18n.t('league_betgame.super_league_swi')
      } else if(this.league === 116) {
        this.description = this.$i18n.t('league_betgame.super_lig_tur')
      } else if(this.league === 71) {
        this.description = this.$i18n.t('league_betgame.ligue_1_fra')
      } else if(this.league === 76) {
        this.description = this.$i18n.t('league_betgame.otp_bank_liga_hu')
      } else if(this.league === 533) {
        this.description = this.$i18n.t('league_betgame.em_handball')
      } else if(this.league === 139 ) {
        this.description = this.$i18n.t('league_betgame.wm_football')
      } else if(this.league === 1110 ) {
        this.description = this.$i18n.t('league_betgame.women_wm_football')
      } else if(this.league === 1180) {
        this.description = this.$i18n.t('league_betgame.em_football_woman')
      } else if(this.league === 107) {
        this.description = ''
      } else if(this.league === 564) {
        this.description = ''
      } else if(this.league === 2255) {
        this.description = ''
      } else if(this.league === 2256) {
        this.description = ''
      } else if(this.league === 2257) {
        this.description = ''
      } else if(this.league === 87) {
        this.description = ''
      } else if(this.league === 1379) {
        this.description = ''
      } else if(this.league === 4228) {
        this.description = ''
      } else if(this.league === 588) {
        this.description = ''
      } else if(this.league === 1235) {
        this.description = ''
      }
    },  
    createBetgame() {
      this.$router.push('/createuserbetgame')
    },
    flagSelected(id) {
      this.league = id
      this.setLeagueName()
      this.setDescription()
    },
    teamtipLogoClicked() {
      if(window.parent) {
        window.parent.open('https://teamtip.net', '_blank')
      } else {
        window.open('https://teamtip.net', '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
	.leaguebetgame {
    padding: 0 !important;

    &__content {
      padding: 32px 0 72px;
      background: #F8F8F8;

      @media screen and (max-width: 767px) {
        padding: 24px 0 48px;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      background: #F8F8F8;
      margin-bottom: -44px;

      .button {
        margin: 0px auto;
        transform: translateY(-50%);
      }
    }
  }

  .textblock p {
    @media screen and (min-width: 768px) {
      font-size: 17px;
      line-height: 24px;
    }
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 1108px;
    margin: 0px auto;
    padding: 0 16px;
    box-sizing: border-box;
  }

  /* HEADER OVERWRITES */
  .header {
    background-image: url('../assets/landingpage/header-background.jpg');
    background-position: center center;
    height: auto;
    padding: 36px 0 16px;

    .bundesliga & {
      background-image: url('../assets/wettbewerb/header-background-bundesliga.jpg');
    }

    .bundesliga2 & {
      background-image: url('../assets/wettbewerb/header-background-bundesliga2.jpg');
    }

    .weltmeisterschaft & {
      background-image: url('../assets/wettbewerb/header-background-wm.jpg');
    }

    @media screen and (max-width: 767px) {
      padding: 18px 0 8px;
      min-height: inherit;
    }
  }

  .leaguebullets {
  	text-align: center;
  	list-style-type: none;
  	padding: 0;

  	@media screen and (min-width: 768px) {
  		display: flex;
  		justify-content: center;
  	}

  	li {
	  	display: flex;
	  	justify-content: center;
	  	color: $colorWhite;
	  	font-weight: 500;
	  	font-size: 14px;
	  	padding: 14px 0;
	  	margin: 0 16px;

	  	@media screen and (max-width: 767px) {
	  		margin: 0;
        padding: 6px 0;

		  	&:not(:last-child) {
			  	box-shadow: inset 0 -1px 0 0 rgba($colorWhite, .04);
			  }
			}

	  	&:before {
	  		content: url('../assets/icons/ic_12_check_white.svg');
	  		margin-right: 8px;
	  	}
  	}
  }

  .whitelabel-list {
  	display: flex;
  	flex-wrap: wrap;
  	padding: 0;
  	list-style-type: none;

  	li {
  		margin-right: 12px;
  		font-size: 14px;
  		line-height: 24px;
  		font-weight: 500;
  		color: $colorGreyDark;

  		&:before {
  			content: '✔';
  			margin-right: 4px;
  		}
  	}
  }

  .highlight {
  	background: url('../assets/landingpage/header-background.jpg');
  	background-size: cover;
  	background-repeat: no-repeat;

    .bundesliga & {
      background-image: url('../assets/wettbewerb/stoerer-background-bundesliga.jpg');
    }

    .bundesliga2 & {
      background-image: url('../assets/wettbewerb/stoerer-background-bundesliga2.jpg');
    }

    .weltmeisterschaft & {
      background-image: url('../assets/wettbewerb/stoerer-background-wm.jpg');
    }

  	&__content {
  		display: flex;
  		align-items: center;
      justify-content: center;
  		width: 100%;
  		max-width: 940px;
  		margin: 0px auto;
  		padding: 128px 0;

  		@media screen and (max-width: 767px) {
  			flex-direction: column-reverse;
  			padding: 64px 0;
  		}
  	}

  	&__text {
  		width: 100%;
      text-align: center;
      max-width: 756px;

  		@media screen and (max-width: 767px) {
  			width: 100%;
        text-align: left;
  		}

  		h2 {
  			font-size: 36px;
  			color: $colorWhite;
  			margin-top: 0;

  			@media screen and (max-width: 767px) {
	  			font-size: 24px;
	  		}
  		}

  		p {
  			font-size: 17px;
  			line-height: 24px;
  			color: $colorWhite;
  		}

  		.button {
  			display: flex;
  			align-items: center;
  			background: $colorWhite;
  			color: var(--color-primary);

        @media screen and (min-width: 768px) {
          margin: 0px auto;
        }
  		}
  	}

  	&__image {
  		width: 50%;

  		@media screen and (max-width: 767px) {
  			width: 100%;
  			margin-bottom: 24px;
  		}

  		img {
  			width: 100%;
  			min-height: 50px;
  		}
  	}
  }

  .appdownload {
  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	text-align: center;
    background: #F8F8F8;
    padding: 128px 0;
    box-shadow: inset 0 1px 0 0 rgba($colorGreyDark, .08);
    background: $colorBackground;

    @media screen and (max-width: 767px) {
    	padding: 80px 0;
    }

  	h2 {
  		font-size: 28px;
  		line-height: 48px;
  		color: $colorGreyDark;
  		margin: 0 0 12px;

  		@media screen and (max-width: 767px) {
  			font-size: 24px;
  		}
  	}

  	p {
  		font-size: 17px;
  		line-height: 24px;
  		color: rgba($colorGreyDark, .64);
  		max-width: 640px;
  		margin: 0px auto;
  	}

  	&__buttons {
  		justify-content: center;
  		margin-top: 42px;

  		@media screen and (max-width: 767px) {
	  		flex-direction: column;
	  		margin-top: 27px;
  		}
  	}

  	.download__button {
			display: inline-flex;
			align-items: center;
			padding: 12px 20px 12px 12px;
			margin: 6px 0;
			background: $colorPrimary;
			box-shadow: 0 12px 24px 0 rgba($colorPrimary,0.16);
			border-radius: 8px;
			text-align: left;
			text-decoration: none;
			transition: all .2s ease-in-out;
			width: 192px;
			justify-content: center;

			@media screen and (min-width: 768px) {
				padding: 16px 20px 16px 12px;
				margin: 0 12px;
			}

			&:hover {
				box-shadow: 0 12px 24px 0 rgba($colorPrimary,0.24);
			}

			&__icon {
				display: flex;
				align-items: center;
				flex-shrink: 0;
				margin-right: 12px;
				width: 24px;
				height: 24px;

				img {
					width: 100%;
					height: 100%;
				}

				@media screen and (min-width: 768px) {
					margin-right: 16px;
					width: 32px;
					height: 32px;
				}
			}

			&__text {
				display: flex;
				flex-direction: column;
				font-size: 8px;
				line-height: 14px;
				font-weight: 700;
				text-transform: uppercase;
				color: rgba($colorWhite, .64);
				margin-top: -6px;
				margin-bottom: 0px;

				@media screen and (min-width: 768px) {
					font-size: 11px;
					line-height: 17px;
					margin-top: -5px;
					margin-bottom: -1px;
				}

				span {
					font-size: 17px;
					color: rgba($colorWhite, 1);
					text-transform: initial;

					@media screen and (min-width: 768px) {
						font-size: 20px;
					}
				}
			}
		}
  }
</style>
