import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store, { menumutations } from './vuex/store'
import SmartTable from 'vuejs-smart-table'
import VModal from 'vue-js-modal'
import vfmPlugin  from 'vue-final-modal'
import InfiniteLoading from 'vue-infinite-loading'
import VueCookies from 'vue-cookies'
import i18n from './i18n'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import Vue2TouchEvents from 'vue2-touch-events'
import FlagIcon from 'vue-flag-icon'
//import SocialSharing from 'vue-social-sharing'
import Multiselect from 'vue-multiselect'
import TextareaAutosize from 'vue-textarea-autosize'
import VueClipboard from 'vue-clipboard2'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueLogger from 'vuejs-logger'
import VueToast from 'vue-toast-notification'
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css'
import './assets/styles/theme-sugar.css'
import VueGtag from 'vue-gtag'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import visibility from 'vue-visibility-change'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Numeral from 'numeral'
import SmartBanner from 'smart-app-banner'
import 'smart-app-banner/dist/smart-app-banner.css'
import VueSocialSharing from 'vue-social-sharing'
import StarRating from 'vue-star-rating'
import { ObserveVisibility } from "vue-observe-visibility"
import { longClickDirective } from 'vue-long-click'

Vue.use(VueGtag, {
  config: { id: "G-0L3VGS20WF" },
  bootstrap: false
}, router);

const options = {
  isEnabled: true,
  logLevel : process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : false,
  showMethodName : false,
  separator: '',
  showConsoleColors: false
};

Vue.directive('longclick', longClickDirective({delay: 400, interval: 0}))
Vue.directive("observe-visibility", ObserveVisibility)
Vue.use(vfmPlugin)
Vue.component('star-rating', StarRating)
Vue.use(VueSocialSharing)
Vue.use(Numeral)
Vue.use(VueAwesomeSwiper, { autoHeight: true } )
Vue.use(visibility)
Vue.use(Loading)
Vue.use(VueLogger, options);
Vue.use(VueToast);
Vue.use(vueSmoothScroll)
Vue.use(VueClipboard)
Vue.use(TextareaAutosize)
Vue.component('multiselect', Multiselect)
//Vue.use(SocialSharing);
Vue.use(FlagIcon);
Vue.use(Vue2TouchEvents)
Vue.component('v-select', vSelect)
Vue.use(VueFormWizard)
Vue.use(VueCookies)
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(VModal)
Vue.use(SmartTable)

Numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€'
  }
})

Vue.filter("formatNumber", function (value) {
  if(isNaN(value)) {
    return ''
  }
  var v = value
  if(v > 1000000000000000) {
    v = v/1000000000000000
    return Numeral(v).format("0,00")  + ' Brd. '
  } else if(v > 1000000000) {
    v = v/1000000000
    return Numeral(v).format("0,00") + ' Bio. '
  } else if(v > 1000000000) {
    v = v/1000000000
    return Numeral(v).format("0,00")  + ' Mrd. '
  } else if(v > 1000000) {
    v = v/1000000
    return Numeral(v).format("0,00") + ' Mio. '
  }
  return Numeral(value).format("0,00.00")
});
Vue.filter('formatDateShort', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), 'Europe/Berlin')
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('DD.MM.YYYY LT') + ' Uhr'
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.YYYY LT') + ' Uhr'
    } else {
      return toTimeZone.format('L LT')
      //return moment(String(value)).format('L LT')
    }
  }
});
Vue.filter('formatDate', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), "Europe/Berlin")
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('dddd, DD.MM.YYYY')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('dddd, DD.MM.YYYY')
    } else {
      return toTimeZone.format('dddd, L')
      //return moment(String(value)).format('dddd, L')
    }
  }
});
Vue.filter('formatTime', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), "Europe/Berlin")
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('LT') + ' Uhr'
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('LT') + ' Uhr'
    } else {
      return toTimeZone.format('LT')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('LT')
    }
  }
});
Vue.filter('formatDateSlider', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), "Europe/Berlin")
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('DD.MM.YY')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.YY')
    } else {
      return toTimeZone.format('MM/DD/YY')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('MM/DD/YY')
    }
  }
});
Vue.filter('formatDateDayMonth', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), "Europe/Berlin")
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('DD.MM.')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.')
    } else {
      return toTimeZone.format('MM/DD')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('MM/DD')
    }
  }
});
Vue.filter('formatDateTime', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), "Europe/Berlin")
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('DD.MM.YYYY - LT')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.YY')
    } else {
      return toTimeZone.format('MM/DD/YYYY - LT')
      //return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('MM/DD/YY')
    }
  }
});
Vue.filter('formatTimeChat', function(value) {
  if (value) {
    const valueFromDB = new Date(value)
    const tvalue = valueFromDB.toLocaleTimeString()
    if(tvalue.length > 3) {
      return tvalue.slice(0, -3)
    }
  }
});
Vue.filter('formatDateChat', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), 'Europe/Berlin')
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('DD.MM.YYYY')
    } else {
      return toTimeZone.format('L')
    }
  }
});
Vue.filter('formatDateTimeShort', function(value) {
  if (value) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if(!timeZone) {
      timeZone = 'Europe/Berlin'
    }
    const valueFromDB = moment.tz(String(value), "Europe/Berlin")
    const toTimeZone = valueFromDB.clone().tz(timeZone)
    if(moment.locale() === 'de') {
      return toTimeZone.format('DD.MM.YY, LT')
    } else {
      return toTimeZone.format('MM/DD/YY, LT')
    }
  }
});
Array.prototype.removeDuplicates = function () {
  return this.filter(function (item, index, self) {
    return self.indexOf(item) === index;
  });
};

Vue.mixin({
  methods: {
    getChatEmbedInfo(embed) {
      var filesize = embed.size ? (embed.size / 1024).toFixed(2) : 0
      if(filesize > 1024) {
        filesize = (filesize / 1024).toFixed(2) + ' MB'
      } else {
        filesize = filesize + ' KB'
      }
      if(embed.mimetype && embed.mimetype.endsWith('pdf')) {
        return "PDF" + ' - ' + filesize
      } else if(embed.mimetype && embed.mimetype.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        return "Excel" + ' - ' + filesize
      } else if(embed.mimetype && embed.mimetype.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        return "Word" + ' - ' + filesize
      } else if (embed.mimetype && embed.mimetype.startsWith('application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
        return "Powerpoint" + ' - ' + filesize
      }
      return embed.mimetype + ' - ' + filesize
    },
    firebaseInstallationId(){
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          return window.TeamtipAppInterface.getFirebaseInstallationId() || ''
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getFirebaseInstallationId')
          return window.localStorage.getItem('firebaseInstallationId') || ''
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    cmpInfo(){
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          return window.TeamtipAppInterface.getCMPInfo() || ''
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getCMPInfo')
          return window.localStorage.getItem('exportCMPInfo') || ''
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getDateFormattedChat(date) {
      if(this.isToday(date)) {
        return this.$t('today')
      } else if(this.isYesterday(date)) {
        return this.$t('yesterday')
      }
      return moment(date).format('DD.MM.YYYY')
    },
    isYesterday(date) {
      const today = new Date()
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
      const matchDate = new Date(date)
      return yesterday.getDate() === matchDate.getDate() &&
        yesterday.getMonth() === matchDate.getMonth() &&
        yesterday.getFullYear() === matchDate.getFullYear()
    },
    isBetgameAdmin() {
      const userid = this.$store.state.user.id
      if(userid !== undefined && this.$store.state.betgame && this.$store.state.betgame.fk_user === userid) {
        return true
      }
      return false
    },
    specialQuestionMissingSolutions() {
      if(!this.$store.state.questions) {
        return false
      }
      const quests = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgame.id))
      if(!quests || quests.length === 0) {
        return false
      }
      var result = false
      for(var i=0; i<quests.length; i++) {
        const eu = this.parseMatchdate(quests[i].editable_until)
        if (eu < Date.now() && this.$store.state.solutions) {
          const solutions = this.$store.state.solutions.filter(s => s.fk_question === quests[i].id)
          result = !solutions || solutions.length < quests[i].answer_count
          if (result) {
            return true
          }
        }
      }
      return false
    },
    isBattleFirst(user) {
      if(this.isBattle() || this.isTeamsBetgame()) {
        const user_is_first = this.$store.state.liveRankings.filter(function (item) {
          return item.position_int === 1 && item.fk_user === user;
        })
        return user_is_first && user_is_first.length === 1
      } else {
        const user_is_first = this.$store.state.betgameRankings.filter(function (item) {
          return item.position_int === 1 && item.fk_user === user;
        })
        return user_is_first && user_is_first.length === 1
      }
    },
    isBattleSecond(user) {
      if(this.isBattle() || this.isTeamsBetgame()) {
        const user_is_second = this.$store.state.liveRankings.filter(function (item) {
          return item.position_int === 2 && item.fk_user === user;
        })
        return user_is_second && user_is_second.length === 1
      } else {
        const user_is_second = this.$store.state.betgameRankings.filter(function (item) {
          return item.position_int === 2 && item.fk_user === user;
        })
        return user_is_second && user_is_second.length === 1
      }
    },
    isBattleThird(user) {
      if(this.isBattle() || this.isTeamsBetgame()) {
        const user_is_third = this.$store.state.liveRankings.filter(function (item) {
          return item.position_int === 3 && item.fk_user === user;
        })
        return user_is_third && user_is_third.length === 1
      } else {
        const user_is_third = this.$store.state.betgameRankings.filter(function (item) {
          return item.position_int === 3 && item.fk_user === user;
        })
        return user_is_third && user_is_third.length === 1
      }
    },
    isBattlePlace(user) {
      if(this.isBattle() || this.isTeamsBetgame()) {
        const user_is_placed = this.$store.state.liveRankings.filter(function (item) {
          return item.position_int > 3 && item.fk_user === user;
        })
        return user_is_placed && user_is_placed.length === 1
      } else {
        const user_is_placed = this.$store.state.betgameRankings.filter(function (item) {
          return item.position_int > 3 && item.fk_user === user;
        })
        return user_is_placed && user_is_placed.length === 1
      }
    },
    getDateFormatted(date) {
      if(this.isToday(date)) {
        return this.$t('today') + ', ' + moment(date).format('LT') 
      } else if(this.isTomorrow(date)) {
        return this.$t('tomorrow') + ', ' + moment(date).format('LT')
      }
      return moment(date).format('DD.MM.YY, LT')
    },
    isToday(date) {
      const today = new Date()
      const matchDate = new Date(date)
      return today.getDate() === matchDate.getDate() &&
        today.getMonth() === matchDate.getMonth() &&
        today.getFullYear() === matchDate.getFullYear()
    },
    isTomorrow(date) {
      const today = new Date()
      const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      const matchDate = new Date(date)
      return tomorrow.getDate() === matchDate.getDate() &&
        tomorrow.getMonth() === matchDate.getMonth() &&
        tomorrow.getFullYear() === matchDate.getFullYear()
    },
    isAdmin(betgame) {
      if(!this.$store.state.betgames_owner) {
        return false
      }
      const t = this.$store.state.betgames_owner.filter(function (item) {
        return item.id === betgame
      });
      return t.length > 0
    },
    getBetgameType(betgame) {
      if(this.isBattle()) {
        return 'Battle'
      } else if(this.isTeamsBetgame()) {
        return 'Team'
      } else if(this.isBetgame()) {
        return 'Competition'
      }
      return 'Unknown type ' + (betgame ? betgame.type : '')
    },
    getNotificationPreferences() {
      var notification_preferences = ''
      if(this.$store.state.user) {
        if(this.$store.state.user.email_notifications !== undefined && this.$store.state.user.email_notifications === true) {
          notification_preferences += 'MReminder,'
        }
        if(this.$store.state.user.push_notifications !== undefined && this.$store.state.user.push_notifications === true) {
          notification_preferences += 'PReminder,'
        }
        if(this.$store.state.user.news_email_notifications !== undefined && this.$store.state.user.news_email_notifications === true) {
          notification_preferences += 'MNews,'
        }
        if(this.$store.state.user.news_push_notifications !== undefined && this.$store.state.user.news_push_notifications === true) {
          notification_preferences += 'PNews,'
        }
        if(this.$store.state.user.chat_push_notifications !== undefined && this.$store.state.user.chat_push_notifications === true) {
          notification_preferences += 'PChat,'
        }
      }
      return notification_preferences
    },
    reverse(str) {
      var newString = ''
      for (var i = str.length - 1; i >= 0; i--) {
        newString += str[i]
      }
      return newString
    },
    appOpenedCount() {
      if (this.isApp()) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const a = window.TeamtipAppInterface.getAppScoreData()
            var b = ''
            try {
              const j = JSON.parse(a)
              b = j.appOpenedCount
            } catch (e) {
              b = e.message
            }
            return b
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getAppScoreData')
            const c = window.localStorage.getItem('appOpenedCount')
            return '' + c
          } else {
            return "TeamtipAppInterface unavailable"
          }
        } catch (e) {
          return "TeamtipAppInterface error " + e.message
        }
      } else {
        return 0
      }
    },
    reviewRequestedCount() {
      if (this.isApp()) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const a = window.TeamtipAppInterface.getAppScoreData()
            var b = ''
            try {
              const j = JSON.parse(a)
              b = j.reviewRequestedCount
            } catch (e) {
              b = e.message
            }
            return b
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getAppScoreData')
            const d = window.localStorage.getItem('reviewRequestedCount')
            return '' + d
          } else {
            return "TeamtipAppInterface unavailable"
          }
        } catch (e) {
          return "TeamtipAppInterface error " + e.message
        }
      } else {
        return 0
      }
    },
    isApp() {
      return navigator.userAgent.indexOf('TEAMTIP') > 0
    },
    appVersion() {
      const ua = navigator.userAgent
      if(ua.includes('TEAMTIP')) {
        const appVersion = ua.split('TEAMTIP/')[1]
        if(appVersion) {
          return appVersion.split(' ')[0]
        }
      }
      return '0.0.0.0'
    },
    appBuild() {
      const av = this.appVersion()
      if(av.includes('.') ) {
        const ab = av.split('.')
        if(ab || ab.length === 4) {
          try {
            const t = parseInt(ab[3])
            return t
          } catch (e) {
            return 0
          }
        }
      }
      return 0
    },
    userAgent() {
      return navigator.userAgent
    },
    buildVersion() {
      return this.$store.getters.packageVersion
    },
    hasFiveStarRating() {
      if(this.$store.state.ratings && this.$store.state.ratings.length > 0) {
        for(var i = 0; i < this.$store.state.ratings.length; i++) {
          const rating = this.$store.state.ratings[i]
          if(rating.rating && parseInt(rating.rating) === 5) {
            return true
          }
        }
      }
      return false
    },
    showSPMReviewPopup() {
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        var appOpenedCount = 0
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const appData = window.TeamtipAppInterface.getAppScoreData()
            try {
              const data = JSON.parse(appData)
              appOpenedCount = data.appOpenedCount
            } catch (e) {
              appOpenedCount = 0
            }
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const tmp = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getAppScoreData')
            appOpenedCount = window.localStorage.getItem('appOpenedCount')
          } else {
            appOpenedCount = 0
          }
        } catch (e) {
          appOpenedCount = 0
        }
        
        //const appOpenedCount = window.localStorage.getItem('appOpenedCount')
        if(appOpenedCount !== null && appOpenedCount !== undefined && parseInt(appOpenedCount) > 30 && parseInt(appOpenedCount) % 31 === 0) {
          if(this.$store.state.reviewPopupShown === 1) {
            return false
          }
          /*
          const lastReviewRequest = new Date(window.localStorage.getItem('spmlrr'))
          if(lastReviewRequest === undefined || 
            lastReviewRequest.getDate() !== new Date().getDate() || 
            lastReviewRequest.getFullYear() !== new Date().getFullYear() || 
            lastReviewRequest.getMonth() !== new Date().getMonth()) {
            window.localStorage.setItem('spmlrr', new Date())
            return true
          }
          */
          return true
        }
      } 
      return false
    },
    isPlaceholderTeam: function (teamid) {
      const t = this.$store.state.teams.filter(function (item) {
        return item.id === teamid
      })
      if(t.length === 1) {
        return t[0].is_placeholder === 1
      }
      if((teamid >= 817 && teamid <= 848) || (teamid === 982) || (teamid >= 985 && teamid <= 995)) {
        return true
      } else {
        return false
      }
    },
    allMatchesFinished() {
      if(this.$store.state.betgame && this.$store.state.betgame.competition &&
        this.$store.state.betgame.competition.type === 'TOURNAMENT') {
        const notScheduled = []
        const finishedCompetitions = [36,87,139,188,189,1110,1379]
        if(finishedCompetitions.includes(this.$store.state.betgame.competition.id)) {
          return true
        }
        if(this.$store.state.betgame.season.id === 14) {
          return true
        }
        if(this.$store.state.betgame.season.id === 15) {
          return false
        }
        if(this.$store.state.tournamentRounds && this.$store.state.tournamentMatches) {
          if(this.$store.state.tournamentRounds.length === 0 || this.$store.state.tournamentMatches.length === 0) {
            if(this.$store.state.betgame.season) {
              this.$store.dispatch('fetchTournamentRounds', { fk_competition: this.$store.state.betgame.competition.id,
                fk_season: this.$store.state.betgame.season.id }).then(() => {
              })
            }
            return false
          } else {
            const rounds = this.$store.state.tournamentRounds
            const tmatches = this.$store.state.tournamentMatches
            const matches = this.$store.state.matches
            for (var i = 0; i < rounds.length; i++) {
              const round = rounds[i]
              const rmatches = tmatches.filter(function (item) {
                return item.fk_tournament_round === round.id;
              })
              if (rmatches.length === 0) {
                return false
              } else {
                for (var j = 0; j < rmatches.length; j++) {
                  const rmatch = rmatches[j]
                  const tempmatches = matches.filter(function (item) {
                    return item.id === rmatch.fk_match;
                  })
                  if (tempmatches.length > 0) {
                    const match = tempmatches[0]
                    if (!this.isNumeric(match.goalshome) || !this.isNumeric(match.goalsguest)) {
                      return false
                    }
                  }
                }
              }
            }
            return true
          }
        } else {
          return false
        }
      } else {
        const matches = this.$store.state.matches
        if (matches === undefined || matches === null || matches.length === 0) {
          return false
        }
        for (var i = 0; i < matches.length; i++) {
          const match = matches[i]
          if(match.status === 'annulliert') {
            continue
          }
          if (!this.isNumeric(match.goalshome) || !this.isNumeric(match.goalsguest)) {
            return false
          }
        }
        return true
      }
    },
    isCompetitionAvailable(competition) {
      /*
      return this.$store.state.competitions && this.$store.state.competitions.length > 0 && this.$store.state.competitions.find(competition => competition.id === parseInt(id))
       */
      const competitions = [1,2,3,13,14,19,33,36,49,71,76,87,91,97,107,111,115,116,132,564,588,1180,1235,1379,2255,2256,2257,4228,6395]
      return competitions.includes(parseInt(competition))
    },
    isValidCompetition(competition) {
      /*
      return this.$store.state.competitions && this.$store.state.competitions.length > 0 && this.$store.state.competitions.find(competition => competition.id === parseInt(id))
       */
      const competitions = [1,2,3,13,14,19,33,36,49,71,76,87,91,97,107,111,115,116,132,139,188,189,533,541,564,588,1108,1110,1122,1180,1235,1379,2255,2256,2257,4228,6395,10700]
      return competitions.includes(parseInt(competition))
    },
    isFutureSeason() {
      const competitionsSeason15 = [1,2,3,13,14,19,33,71,76,87,91,97,107,111,115,116,132,188,189,564,588,998,999,1180,1235,1379,2255,2256,2257,4228,6395]
      if (this.$store.state.betgame && this.$store.state.betgame.competition && competitionsSeason15.includes(parseInt(this.$store.state.betgame.competition.id))) {
        return false
      }
      return this.$store.state.betgame && this.$store.state.betgame.season && this.$store.state.betgame.season.id > this.$store.state.currentSeason.id
    },
    isPastSeason() {
      return this.$store.state.betgame && this.$store.state.betgame.season && this.$store.state.betgame.season.id < this.$store.state.currentSeason.id
    },
    isCurrentSeason() {
      return this.$store.state.betgame && this.$store.state.betgame.season && this.$store.state.betgame.season.id === this.$store.state.currentSeason.id
    },
    hasSchedule() {
      return this.$store.state.matches && this.$store.state.matches.length > 0
    },
    formatBetgameDescription(id,text) {
      if(id && parseInt(id) === 23916) {
        return 'Tippe beim SpielerPlus Tippspiel Battle alle Spiele des 34. Spieltags der 1. und 2. Bundesliga und gewinne tolle Preise! 🎉<br><br>Platz 1: Apple Watch SE<br>Platz 2 - 3: Das neue Trikot deines Lieblingsvereins<br>Platz 4 - 10: Ein SportlerPlus Nutrition Paket<br><br>*Auszahlung & Rückgabe der Produkte sind ausgeschlossen. Die ausgewählten Gewinner:innen werden per E-Mail über ihren Gewinn informiert. Bei Gleichstand entscheidet das Los.<br>'
      } else {
        return text
      }
    },
    isTournament() {
      const tournments = [19,33,36,87,132,139,188,189,533,541,588,999,1110,1180,1235,1379,4228,6395,10700]
      if(this.$store.state.betgame && this.$store.state.betgame.competition) {
        return tournments.includes(parseInt(this.$store.state.betgame.competition.id))
      } else {
        if(this.$store.state.leagueId && parseInt(this.$store.state.leagueId) > 0) {
          return tournments.includes(parseInt(this.$store.state.leagueId))
        } else {
          return false
        }
      }
    },
    matchdayTextGlobal(matchday, competition) {
      if(matchday === undefined || matchday === null || matchday < 1) {
        return ''
      }
      if(competition === 19 || competition === 132) {
        if(matchday === 9) {
          return this.$i18n.t('ko_round_playoffs_first_legs')
        } else if(matchday === 10) {
          return this.$i18n.t('ko_round_playoffs_second_legs')
        } else if(matchday === 11) {
          return this.$i18n.t('ko_round_of_16_first_legs')
        } else if(matchday === 12) {
          return this.$i18n.t('ko_round_of_16_second_legs')
        } else if(matchday === 13) {
          return this.$i18n.t('ko_round_quarter_finals_first_legs')
        } else if(matchday === 14) {
          return this.$i18n.t('ko_round_quarter_finals_second_legs')
        } else if(matchday === 15) {
          return this.$i18n.t('ko_round_semi_finals_first_legs')
        } else if(matchday === 16) {
          return this.$i18n.t('ko_round_semi_finals_second_legs')
        } else if(matchday === 17) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 6395) {
        if(matchday === 7) {
          return this.$i18n.t('ko_round_playoffs_first_legs')
        } else if(matchday === 8) {
          return this.$i18n.t('ko_round_playoffs_second_legs')
        } else if(matchday === 9) {
          return this.$i18n.t('ko_round_of_16_first_legs')
        } else if(matchday === 10) {
          return this.$i18n.t('ko_round_of_16_second_legs')
        } else if(matchday === 11) {
          return this.$i18n.t('ko_round_quarter_finals_first_legs')
        } else if(matchday === 12) {
          return this.$i18n.t('ko_round_quarter_finals_second_legs')
        } else if(matchday === 13) {
          return this.$i18n.t('ko_round_semi_finals_first_legs')
        } else if(matchday === 14) {
          return this.$i18n.t('ko_round_semi_finals_second_legs')
        } else if(matchday === 15) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 10700 || competition === 36 || competition === 999) {
        if(matchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: matchday })
        } else if(matchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: matchday })
        } else if(matchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: matchday })
        } else if(matchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(matchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(matchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(matchday === 7) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 533) {
        const nextMatchday = matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_fifth_place_short')
        } else if(nextMatchday === 9) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 10) {
          return this.$i18n.t('ko_round_third_place_short')
        } else if(nextMatchday === 11) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 139 || competition === 1110) {
        const nextMatchday = matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_third_place_short')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 33) {
        const nextMatchday = matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('cup_round', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('cup_round', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('ko_round_third_place_short')
        } 
      } else if(competition === 1180) {
        const nextMatchday = matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 87 || competition === 1379) {
        const nextMatchday = matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('ko_round_third_place')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(competition === 4) {
        if(matchday === 1) {
          return this.$i18n.t('ko_round_first_leg')
        } else if(matchday === 2) {
          return this.$i18n.t('ko_round_second_leg')
        } 
      } else if(competition === 5) {
        if(matchday === 1) {
          return this.$i18n.t('ko_round_first_leg')
        } else if(matchday === 2) {
          return this.$i18n.t('ko_round_second_leg')
        }
      } else if(competition === 6) {
        if(matchday === 15) {
          return this.$i18n.t('ko_round_final')
        } 
      } else if(competition === 7) {
        if(matchday === 15) {
          return this.$i18n.t('ko_round_final')
        } 
      } else if(competition === 999) {
        if(matchday === 11) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(matchday === 12) {
          return this.$i18n.t('ko_round_final')
        }
      }
      return this.$i18n.t('matchday', { matchday: matchday })
    },
    hideBetgame(betgame) {
      const battle = this.$store.state.battles.filter(function(item) {
        return item.id === betgame;
      })
      if(battle.length === 1) {
        const b = battle[0]
        if(b.count > b.finished) {
          return false
        } else {
          return b.count === b.finished
        }
      }
      const end = this.getEndDate(betgame)
      if(end && end !== '') {
        const enddate = this.parseMatchdate(end)
        const d = moment().subtract(7, 'days')
        return enddate.isBefore(d)
      }
      return false
    },
    getEndDate(betgame) {
      if(!this.$store.state.betgameInfos) {
        return ''
      }
      const c = this.$store.state.betgameInfos.filter(b => parseInt(b.fk_betgame) === parseInt(betgame))
      if(c.length > 0) {
        return c[0].enddate
      }
      return ''
    },
    getCompetitionId(betgame) {
      if(!this.$store.state.betgameCompetitions) {
        return 0
      }
      const c = this.$store.state.betgameCompetitions.filter(b => parseInt(b.fk_betgame) === parseInt(betgame))
      if(c.length > 0) {
        return c[0].bg_competition.id
      }
      return 0
    },
    getSeasonId(betgame) {
      if(!this.$store.state.betgameCompetitions) {
        return 0
      }
      const c = this.$store.state.betgameCompetitions.filter(b => parseInt(b.fk_betgame) === parseInt(betgame))
      if(c.length > 0) {
        return c[0].bg_season.id
      }
      return 0
    },
    getCompetition(betgame) {
      if(!this.$store.state.betgameCompetitions) {
        return ''
      }
      const c = this.$store.state.betgameCompetitions.filter(b => parseInt(b.fk_betgame) === parseInt(betgame))
      if(c.length > 0) {
        return c[0].bg_competition.name
      }
      return ''
    },
    getSeason(betgame) {
      if(!this.$store.state.betgameCompetitions) {
        return ''
      }
      const c = this.$store.state.betgameCompetitions.filter(b => parseInt(b.fk_betgame) === parseInt(betgame))
      if(c.length > 0) {
        return c[0].bg_season.name
      }
      return ''
    },
    competitionFinished(fk_competition, fk_season, fk_betgame) {
      if(fk_competition === 0) {
        const battle = this.$store.state.battles.filter(function(item) {
          return item.id === fk_betgame;
        })
        if(battle.length === 1) {
          const b = battle[0]
          return b.count === b.finished
        }
        return false
      }
      if(fk_season === 12 || fk_season === 13) {
        return true
      }
      const leagues = [1,2,3,13,14,49,71,76,91,97,107,111,115,116,564,998,2255,2256,2257]
      const tournments = [19,33,36,87,132,139,188,189,533,541,588,999,1110,1180,1235,1379,4228,6395,10700]
      const finished = [139,1110]
      if(finished.includes(fk_competition)) {
        return true
      }
      if(leagues.includes(fk_competition)) {
        return fk_season < this.$store.state.currentSeason.id
      }
      if(tournments.includes(fk_competition)) {
        return fk_season < this.$store.state.currentSeason.id
      }
      // Vue.$log.debug('competitionFinished ' + fk_competition + ' --- ' + fk_season)
      return false
    },
    isBattleWinner(user) {
      if(!this.$store.state.userNeighbourRankings) {
        return false
      }
      const user_is_first = this.$store.state.userNeighbourRankings.filter(function(item) { 
        return item.position === 1 && item.fk_user === user; 
      })
      if(user_is_first && user_is_first.length === 1) {
        return true
      } else {
        const winner = this.$store.state.userNeighbourRankings.filter(function(item) {
          return item.position === 1;
        })
        if(winner.length > 0) {
          const multiple_winners = this.$store.state.userNeighbourRankings.filter(function (item) {
            return item.points_total === winner[0].points_total && item.exact === winner[0].exact &&
              item.goaldiff === winner[0].goaldiff;
          })
          if (multiple_winners && multiple_winners.length === 1) {
            return false
          } else {
            const user_in_multiple_winners = multiple_winners.filter(function (item) {
              return item.fk_user === user;
            })
            if (user_in_multiple_winners && user_in_multiple_winners.length === 1) {
              return true
            }
          }
        } else {
          return false
        }
      }
      return false
    },
    isBundesliga202324() {
      return this.$store.state.betgame && this.$store.state.betgame.competition && parseInt(this.$store.state.betgame.competition.id) === 1 &&
        this.$store.state.betgame.season && parseInt(this.$store.state.betgame.season.id) === 14
    },
    isWM2022() {
      return this.$store.state.betgame && this.$store.state.betgame.competition && parseInt(this.$store.state.betgame.competition.id) === 139
    },
    isWM2023() {
      return this.$store.state.betgame && this.$store.state.betgame.competition && parseInt(this.$store.state.betgame.competition.id) === 1110
    },
    isEM2024() {
      return this.$store.state.betgame && this.$store.state.betgame.competition && parseInt(this.$store.state.betgame.competition.id) === 36 &&
        this.$store.state.betgame.season && parseInt(this.$store.state.betgame.season.id) === 14
    },
    isBattle() {
      return this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_BATTLE_ID 
    },
    isTeamsBetgame() {
      return this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_TEAMS_ID
    },
    isBetgame() {
      return this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_BETGAME_ID
    },
    getTeamNameByKickerId(kickerId) {
      const team = this.$store.getters.getTeamByKickerId(kickerId)
      if(team != null) {
        if(this.$i18n.locale === 'de' && team.short !== null && team.short.length > 0) {
          return team.short
        } else {
          return team.short_int
        }
      }
      return ''
    },
    isAndroidTeamtipApp() {
      if(navigator && navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('teamtip') > -1 && navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        return true
      }
      return false
    },
    appTrackingAuthorized() {
      /* case notDetermined = 0, case restricted = 1, case denied = 2, case authorized = 3 */
      const a = window.localStorage.getItem('trackingAuthorizationStatus')
      if(a === null || a === undefined || a.trim() === '' || a.trim().length !== 1) {
        return true
      }
      return a.trim() === '3'
    },
    hasBetgameUsers(betgameid) {
      const a = this.$store.state.betgameUsers ? this.$store.state.betgameUsers.filter(function(item) { return parseInt(item.fk_betgame) === parseInt(betgameid); }) : []
      const b = this.$store.state.liveRankings ? this.$store.state.liveRankings.filter(function(item) { return parseInt(item.fk_betgame) === parseInt(betgameid); }) : []
      return a.length > 1 || b.length > 1
    },
    isBetgameOwnerOrAuthor(betgameid) {
      const o = this.$store.state.betgames_owner.filter(function(item) { return item.id === betgameid; })
      const a = this.$store.state.betgames_author.filter(function(item) { return item.id === betgameid; })
      return o.length > 0 || a.length > 0
    },
    hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    },
    isValidBwinBet(bet) {
      if(bet === undefined || bet === null) {
        return false
      }
      return (this.isNumeric(bet.bet_1) && parseFloat(bet.bet_1) > 1.0) &&
        (this.isNumeric(bet.bet_x) && parseFloat(bet.bet_x) > 1.0) &&
        (this.isNumeric(bet.bet_2) && parseFloat(bet.bet_2) > 1.0)
    },    
    countryBlocked() {
      if(this.$store.state.geoData !== null && this.$store.state.geoData.Iso !== null && this.$store.state.geoData.Result === 'ok') {
        const isocode = this.$store.state.geoData.Iso
        if(isocode.toUpperCase() === 'DE' || isocode.toUpperCase() === 'AT') {
          return false
        }
      }
      return true
    },
    isSPMUser () {
      if(!this.$store.state.user || !this.$store.state.user.email) {
        return false
      }
      if(this.$store.state.user.email.includes('appstoreconnect')) {
        return false
      }
      return this.$store.state.user.email.includes('@spm.de') || this.$store.state.user.email.includes('@sportplatz-media.com') ||
        this.$store.state.user.email.includes('@spm.spm') || this.$store.state.user.email.includes('hellmann.hamburg') ||
        this.$store.state.user.email.includes('@fussifreunde.de') || this.$store.state.user.email.includes('@merten-hellmann.de') || 
        this.$store.state.user.email.includes('@marcelhager.de') || this.$store.state.user.email.includes('niklas.fahrenholz@web.de') ||
        this.$store.state.user.email.includes('philippreddigau+teamtip@googlemail.com')
    },
    isAdminUser () {
      if(!this.$store.state.user || !this.$store.state.user.email) {
        return false
      }
      if(this.$store.state.user.email.includes('appstoreconnect')) {
        return false
      }
      return this.$store.state.user.email.includes('@spm.de') || this.$store.state.user.email.includes('hamann@sportplatz-media.com') ||
        this.$store.state.user.email.includes('@spm.spm')
    },
    isValidIcon(icon) {
      return icon !== undefined && icon !== null && icon.length > 0
    },
    buildIconPath(icon, width) {
      return this.getBGValue('spmau') + '/icons/' + width + '/' + icon
    },
    buildAvatarPath(icon, width) {
      return this.getBGValue('spmau') + '/avatar/' + width + '/' + icon
    },
    getAvatar(user) {
      return this.$store.getters.getUserAvatar(user)
    },
    hasHistory () { 
      return window.history.length > 2 
    },
    hasSeasonStarted () {
      return this.$store.getters.startedMatches > 0
    },
    openMenu() {
      if(this.$route && this.$route.name && this.$route.name === 'feed') {
        //this.$store.dispatch('setUnreadCount', 0)
      } else {
        this.$store.dispatch('fetchChatItems', true)
      }
      if(this.$store.state.missing_bets === undefined || this.$store.state.missing_bets.length > 0) {
        if(this.$store.state.updateUserBetgames) {
          this.$store.dispatch('updateUserBetgames', false).then(function (response) {
          })
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
        }
      }
  
      const lastFetch = this.getBGValue('spmlfq')
      const duration = (new Date() - new Date(lastFetch)) / 1000
      if (duration > 60) {
        if(this.$store.state.betgameId && this.$store.state.betgame && this.$store.state.betgame.season) {
          const that = this
          this.$store.dispatch('fetchQuestions', []).then(function (response) {
            that.setBGValue('spmlfq', new Date())
          })
        }
      }
      
      if((!this.$store.state.matches || this.$store.state.matches.length === 0) && this.$store.state.betgame) {
        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
    
        })
      }
      
      menumutations.setIsNavOpen(true)
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    hostReachable () {
      // Handle IE and more capable browsers
      var xhr = new (window.ActiveXObject || XMLHttpRequest)('Microsoft.XMLHTTP')
      // Open new request as a HEAD to the root hostname with a random param to bust the cache
      xhr.open('HEAD', '//' + window.location.hostname + '/?rand=' + Math.floor((1 + Math.random()) * 0x10000), false)
      // Issue request and handle response
      try {
        xhr.send()
        return (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304))
      } catch (error) {
        return false
      }
    },
    hexToRgbA(hex, a){
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + a + ')';
      }
      throw new Error('Bad Hex');
    },
    clearBGValue(key) {
      window.localStorage.removeItem(key)
    },
    setBGValue(key, value) {
      window.localStorage.setItem(key, value)
    },
    getBGValue(key) {
      return window.localStorage.getItem(key)
    },
    isFuture(date) {
      return this.nowToLocalTimeZone() < this.parseMatchdateToLocalTimeZone(date)
    },
    isPast(date) {
      return this.nowToLocalTimeZone() > this.parseMatchdateToLocalTimeZone(date)
    },
    nowToLocalTimeZone() {
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if(!timeZone) {
        timeZone = 'Europe/Berlin'
      }
      return moment().tz(timeZone)
    },
    parseMatchdateToLocalTimeZone(matchdate) {
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if(!timeZone) {
        timeZone = 'Europe/Berlin'
      }
      const valueFromDB = moment.tz(String(matchdate), "Europe/Berlin")
      return valueFromDB.clone().tz(timeZone)
    },
    parseMatchdate(matchdate) {
      return moment(matchdate, MATCH_DATE_FORMAT, true)
    },
    isNumeric: function (n) {
      if(n === null || n === undefined) {
        return false
      }
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
    getTeamName(teamId, teamName) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null) {
        if(this.$i18n.locale === 'de' && team.shortname !== null && team.shortname.length > 0) {
          return team.shortname
        } else {
          return team.shortname_int
        }
      }
      return teamName
    },
    getTeamFullName(teamId, teamName) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null) {
        if(this.$i18n.locale === 'de' && team.name !== null && team.name.length > 0) {
          return team.name
        } else {
          return team.name_int
        }
      }
      return teamName
    },
    getTeamShort(teamId, teamName) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null) {
        if(this.$i18n.locale === 'de' && team.short !== null && team.short.length > 0) {
          return team.short
        } else {
          return team.short_int
        }
      }
      return teamName
    },
    getTeamColor(teamId) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.color !== null && team.color.length > 0) {
        return team.color
      }
      return '#0C3EC1'
    },
    getTeamLogo(teamId) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.logo !== null && team.logo.length > 0) {
        return team.logo
      }
      return null
    },
    getPoints(bet, match) {
      if(bet === null || match === null) {
        return 0
      }
      if(match.status && match.status === 'Wertung') {
        return -1
      }
      if(!this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
        !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
        return 0
      }
      var goalshome = null
      var goalsguest = null
      if(match.livegoalshome !== null && match.livegoalsguest !== null &&
        match.goalshome === null && match.goalsguest === null) {
        goalshome = match.livegoalshome
        goalsguest = match.livegoalsguest
      } else if(match.goalshome !== null && match.goalsguest !== null) {
        goalshome = match.goalshome
        goalsguest = match.goalsguest
      }
      if(goalshome !== null && goalsguest !== null) {
        if(!this.$store.state.betgame.bet_mode || this.$store.state.betgame.bet_mode === 1) {
          if ((bet.goalshome === goalshome) && (bet.goalsguest === goalsguest)) {
            return this.$store.getters.pointsBetResult
          } else if ((bet.goalshome - bet.goalsguest) === (goalshome - goalsguest)) {
            return this.$store.getters.pointsBetGoaldiff
          } else if ((bet.goalshome > bet.goalsguest) && (goalshome > goalsguest)) {
            return this.$store.getters.pointsBetTendency
          } else if ((bet.goalshome < bet.goalsguest) && (goalshome < goalsguest)) {
            return this.$store.getters.pointsBetTendency
          }
        } else if(this.$store.state.betgame.bet_mode === 2) {
          if ((bet.goalshome === 1) && (goalshome > goalsguest)) {
            return this.$store.getters.pointsBetResult
          } else if ((bet.goalshome === 0) && (goalshome === goalsguest)) {
            return this.$store.getters.pointsBetResult
          } else if ((bet.goalshome === 2) && (goalshome < goalsguest)) {
            return this.$store.getters.pointsBetResult
          } 
        }
      }
      return 0
    },
    getPointsBy(betgame, bet, match) {
      if(betgame === null || bet === null || match === null) {
        return 0
      }
      const resultmode = betgame.result_mode || RESULT_MODE_TOTAL
      const betmode = betgame.bet_mode || BETMODE_RESULT
      var goalshome = null
      var goalsguest = null
      if(resultmode === RESULT_MODE_90M && match.goalshome_90 !== null && match.goalsguest_90 !== null) {
        goalshome = parseInt(match.goalshome_90)
        goalsguest = parseInt(match.goalsguest_90)
      } else if(resultmode === RESULT_MODE_120M && match.goalshome_90 !== null && match.goalsguest_90 !== null &&
        match.goalshome_120 === null && match.goalsguest_120 === null && match.goalshome !== null && match.goalsguest !== null) {
        goalshome = parseInt(match.goalshome)
        goalsguest = parseInt(match.goalsguest)
      } else if(resultmode === RESULT_MODE_120M && match.goalshome_90 !== null && match.goalsguest_90 !== null &&
        match.goalshome_120 !== null && match.goalsguest_120 !== null) {
        goalshome = parseInt(match.goalshome_120)
        goalsguest = parseInt(match.goalsguest_120)
      } else if(match.livegoalshome !== null && match.livegoalsguest !== null &&
        match.goalshome === null && match.goalsguest === null) {
        goalshome = parseInt(match.livegoalshome)
        goalsguest = parseInt(match.livegoalsguest)
      } else if(match.goalshome !== null && match.goalsguest !== null) {
        goalshome = parseInt(match.goalshome)
        goalsguest = parseInt(match.goalsguest)
      }
      if(goalshome !== null && goalsguest !== null) {
        const betgoalshome = parseInt(bet.goalshome)
        const betgoalsguest = parseInt(bet.goalsguest)
        if(betmode === BETMODE_RESULT) {
          if ((betgoalshome === goalshome) && (betgoalsguest === goalsguest)) {
            return betgame.points_bet_result
          } else if ((betgoalshome - betgoalsguest) === (goalshome - goalsguest)) {
            return betgame.points_bet_goaldiff
          } else if ((betgoalshome > betgoalsguest) && (goalshome > goalsguest)) {
            return betgame.points_bet_tendency
          } else if ((betgoalshome < betgoalsguest) && (goalshome < goalsguest)) {
            return betgame.points_bet_tendency
          }
        } else if(betmode === BETMODE_TOTO) {
          if ((betgoalshome === 1) && (goalshome > goalsguest)) {
            return betgame.points_bet_result
          } else if ((betgoalshome === 0) && (goalshome === goalsguest)) {
            return betgame.points_bet_result
          } else if ((betgoalshome === 2) && (goalshome < goalsguest)) {
            return betgame.points_bet_result
          }
        }
      }
      return 0
    },
  }
})

Vue.config.productionTip = false
// Vue.config.silent = true

Vue.config.errorHandler = err => {
  Vue.$log.debug('ERROR: ' + router.currentRoute.path + ' --- ' + err.stack)
}

export const RANKINGS_PAGE_SIZE = 20
export const TOP_TEN = 2500
export const MATCH_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const DASHBOARD_RANKINGS_SIZE = 5
export const PADDING_BOTTOM_DEFAULT = 0
export const PADDING_BOTTOM_DSP = 200
export const PADDING_BOTTOM_FUNKE = 0
export const DEFAULT_BET = 10.0
export const API_TIMEOUT = 10000
export const TOAST_DURATION = 3000
export const DEFAULT_COMPETITION = 1
export const BATTLE_COMPETITION = 0
export const TYPE_BETGAME = 'betgame'
export const TYPE_BATTLE = 'battle'
export const TYPE_TEAMS = 'teams'
export const TYPE_BETGAME_ID = 1
export const TYPE_BATTLE_ID = 2
export const TYPE_TEAMS_ID= 3
export const BETMODE_RESULT = 1
export const BETMODE_TOTO = 2
export const RESULT_MODE_90M = 3
export const RESULT_MODE_120M = 2
export const RESULT_MODE_TOTAL = 1
export const TEAM_TYPE_MEN = 1
export const TEAM_TYPE_WOMEN = 2
export const TEAM_TYPE_OLYMPIC = 3
export const SPORTS_TYPE_FOOTBALL = 1
export const SPORTS_TYPE_HANDBALL = 2
export const SPORTS_TYPE_HOCKEY = 3
export const CHAT_TEAMTIP = 'https://chat.teamtip.net'

// https://vuejs.org/v2/api/#vm-mount
// https://vuejs.org/v2/api/#render
new Vue({
  name: 'app',
  el: '#betgame-container',
  router,
  store,
  i18n,
  created () {
    if(navigator && navigator.userAgent && navigator.userAgent.indexOf('TEAMTIP') === -1) {
      new SmartBanner({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        title: 'TEAMTIP',
        author: 'SPM SPORTPLATZ MEDIA GMBH',
        button: 'VIEW',
        store: {
          android: 'In Google Play'
        },
        price: {
          android: 'FREE'
        },
        theme: 'ios' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
      })
    }
  },
  render(h) {
    return h(App, {
      props: {
        //betgameId: this.$el.attributes.betgame.value,
        //betgameName: this.$el.attributes.bgname.value,
        //betgameHost: this.$el.attributes.bghost.value
      }
    })
  }
})
