<template>

  <div class="dashboard-container">
    <!-- TODO @Lennard: Add class'.dashboard-matchday__match--won' only in case of a finale and after match has finished -->
    <div class="dashboard-matchday__match">

      <!-- Match: Header -->
      <div class="dashboard-matchday__match__title">
        <div class="dashboard-matchday__match__title__left">
          {{ getTeamName(match.fk_team_home, '') }}
        </div>
        <!-- If match or battle -->
        <div v-if="matchRunning" class="dashboard-matchday__match__title__center">
          <span v-if="livepoints(this.$store.state.user.id) === 1">1 {{ $t('point') }}</span>
          <span v-else>{{ livepoints(this.$store.state.user.id) }} {{ $t('points') }}</span>
        </div>
        <!-- If Final -->
        <!--<div class="dashboard-matchday__match__title__center">
          Finale
          <span>LIVE</span>
        </div>-->
        <div class="dashboard-matchday__match__title__right">
          {{ getTeamName(match.fk_team_guest, '') }}
        </div>
      </div>

      <!-- Match: Teams & Result -->
      <div class="dashboard-matchday__match__teams">

        <!-- Home Team -->
        <div class="dashboard-matchday__match__teams__left">
          <img v-if="teamCode(match.fk_team_home) && teamCode(match.fk_team_home).length > 6" :src="teamLogo(match.fk_team_home)">
          <flag v-else-if="teamCode(match.fk_team_home) && teamCode(match.fk_team_home).length <= 6" :iso="teamCode(match.fk_team_home)" />
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match.fk_team_home, true)}"></path>
          </svg>
        </div>

        <!-- Result -->
        <div class="dashboard-matchday__match__teams__center">

          <!-- Result -->
          <div v-if="this.$store.state.betgame.bet_mode === 1" class="dashboard-matchday__match__bet">
            <div class="dashboard-matchday__match__bet__left">
              {{ getBetGoalsHome(match, this.$store.state.user ) }}
            </div>
            <div class="dashboard-matchday__match__bet__center">
              :
            </div>
            <div class="dashboard-matchday__match__bet__right">
              {{ getBetGoalsGuest(match, this.$store.state.user ) }}
            </div>
          </div>
          <div v-else-if="this.$store.state.betgame.bet_mode === 2" class="dashboard-matchday__match__toto">
            <div class="dashboard-matchday__match__toto__item" v-bind:class="getClass(match, this.$store.state.user.id, 1)">
              1
            </div>
            <div class="dashboard-matchday__match__toto__item" v-bind:class="getClass(match, this.$store.state.user.id, 0)">
              0
            </div>
            <div class="dashboard-matchday__match__toto__item" v-bind:class="getClass(match, this.$store.state.user.id, 2)">
              2
            </div>
          </div>
          
          <!-- Bet -->
          <div v-if="isNumeric(match.goalshome) || isNumeric(match.livegoalshome)" :class="getMatchLiveResultClass()" class="dashboard-matchday__match__result">
            <div class="dashboard-matchday__match__result__left">
              {{ getGoalsHome(match) }}
            </div>
            <div class="dashboard-matchday__match__result__center">
              :
            </div>
            <div class="dashboard-matchday__match__result__right">
              {{ getGoalsGuest(match) }}
            </div>
          </div>
          <div v-else class="dashboard-matchday__match__result">
            {{ getMatchTime(match) }}
          </div>
        </div>

        <!-- Away Team -->
        <div class="dashboard-matchday__match__teams__right">
          <img v-if="teamCode(match.fk_team_guest) && teamCode(match.fk_team_guest).length > 6" :src="teamLogo(match.fk_team_guest)">
          <flag v-else-if="teamCode(match.fk_team_guest) && teamCode(match.fk_team_guest).length <= 6" :iso="teamCode(match.fk_team_guest)" />
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match.fk_team_guest, false)}"></path>
          </svg>
        </div>

      </div>

    </div>

    <!-- Optional: Navigation for switching between active games (in case of battle) -->
    <!-- TODO @Lennard: Add class ".dashboard-navigation__item--disabled" only to left/right arrow in case of first or last match of battle -->
    <!--
    <div class="dashboard-navigation">
      <div class="dashboard-navigation__item dashboard-navigation__item--disabled">
      </div>
      <div class="dashboard-navigation__item">
      </div>
    </div>
    -->

    <div class="dashboard-text" style="padding-top: 0pc;">
      <button v-if="getBetGoalsHome(match, this.$store.state.user) === '-'" v-on:click="betButtonClicked">{{ $t('show_edit_bets') }}</button>
      <button v-else v-on:click="rankingsButtonClicked">{{ $t('show_matchday_rankings') }}</button>
    </div>

  </div>
</template>

<script>
  import { isNumeric } from '@/vuex/helpers'

  export default {
    name: 'DashboardLiveStatisticsOneMatch',
    data() {
      return {
      }
    },
    props: {
      match: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    methods: {
      getColor(teamId, home) {
        const colors = [
          {
            "id": 190,
            "h": "#dce6fc",
            "a": "#81287f"
          },
          {
            "id": 356,
            "h": "#1b345f",
            "a": "#eeeae3"
          },
          {
            "id": 174,
            "h": "#ae000e",
            "a": "#d1dced"
          },
          {
            "id": 358,
            "h": "#94252a",
            "a": "#e9edf1"
          },
          {
            "id": 980,
            "h": "#df2f4d",
            "a": "#04202b"
          },
          {
            "id": 172,
            "h": "#338de6",
            "a": "#e5e3fd"
          },
          {
            "id": 180,
            "h": "#bec3cf",
            "a": "#0934b5"
          },
          {
            "id": 186,
            "h": "#f3312a",
            "a": "#e4e98c"
          },
          {
            "id": 175,
            "h": "#dd1418",
            "a": "#f2f4f4"
          },
          {
            "id": 179,
            "h": "#dddcd8",
            "a": "#45444b"
          },
          {
            "id": 455,
            "h": "#870218",
            "a": "#eeeee9"
          },
          {
            "id": 981,
            "h": "#e6effe",
            "a": "#034fb2"
          },
          {
            "id": 189,
            "h": "#4351ea",
            "a": "#d2d3d0"
          },
          {
            "id": 182,
            "h": "#ee6615",
            "a": "#181ca9"
          },
          {
            "id": 181,
            "h": "#e82830",
            "a": "#ccd4d8"
          },
          {
            "id": 185,
            "h": "#e7e8f3",
            "a": "#e92c2f"
          },
          {
            "id": 177,
            "h": "#a01224",
            "a": "#75b5e1"
          },
          {
            "id": 984,
            "h": "#e6da00",
            "a": "#c72b37"
          },
          {
            "id": 357,
            "h": "#035bf7",
            "a": "#deecfa"
          },
          {
            "id": 183,
            "h": "#fce252",
            "a": "#1a4687"
          },
          {
            "id": 997,
            "h": "#cfd0de",
            "a": "#ba1e2c"
          },
          {
            "id": 188,
            "h": "#d11e1f",
            "a": "#cedee2"
          },
          {
            "id": 184,
            "h": "#96000e",
            "a": "#e8e8e8"
          },
          {
            "id": 171,
            "h": "#f6f8fc",
            "a": "#cb1d25"
          }
        ]
        const c = colors.filter(function(item) { return item.id === teamId; })
        if (c && c.length === 1) {
          return home ? c[0].h : c[0].a 
        }
        const team = this.$store.getters.getTeamById(teamId)
        if(team != null && team.color !== null && team.color.length > 0) {
          return team.color
        }
        return '#0C3EC1'
      },
      getMatchLiveResultClass() {
        return { 'dashboard-matchday__match__result--live': (this.isNumeric(this.match.livegoalshome) &&
              this.isNumeric(this.match.livegoalsguest)) }
      },
      getClass(match, user, bettype ) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
        if(bet !== undefined && bet !== null) {
          return { 'dashboard-matchday__match__toto__item--selected': bet.goalshome === bettype }
        }
        return { 'dashboard-matchday__match__toto__item--selected': false }
      },
      livepoints (user) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, this.match.id, user)
        const p = this.getPoints(bet, this.match)
        if (p > -1) {
          return p
        }
        return 0 
      },
      isToday(date) {
        const today = new Date()
        const matchDate = new Date(date)
        return today.getDate() === matchDate.getDate() &&
            today.getMonth() === matchDate.getMonth() &&
            today.getFullYear() === matchDate.getFullYear()
      },
      getMatchTime(match) {
        if(match.matchdate !== null) {
          if(this.isToday(match.matchdate)) {
            return match.matchdate.substring(11, 16)
          } else {
            return match.matchdate.substring(5, 10)
          }
        }
        return null
      },
      getGoalsHome(match) {
        if(isNumeric(match.livegoalshome)) {
          return match.livegoalshome
        } else if(isNumeric(match.goalshome)) {
          return match.goalshome
        }
        return null
      },
      getGoalsGuest(match) {
        if(isNumeric(match.livegoalsguest)) {
          return match.livegoalsguest
        } else if(isNumeric(match.goalsguest)) {
          return match.goalsguest
        }
        return null
      },
      getBetGoalsHome(match, user) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user.id)
        if(bet !== undefined && bet !== null) {
          return bet.goalshome
        }
        return '-'
      },
      getBetGoalsGuest(match, user) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user.id)
        if(bet !== undefined && bet !== null) {
          return bet.goalsguest
        }
        return '-'
      },
      rankingsButtonClicked() {
        if(!this.match) {
          this.$router.push({ name: 'rankingsbymatchday' })
        } else {
          this.$router.push({ name: 'rankingsbymatchday', params: { matchday: this.match.matchday } })
        }
      },
      betButtonClicked() {
        if(!this.match) {
          this.$router.push({ name: 'bet' })
        } else {
          this.$router.push({ name: 'bet', params: { matchday: this.match.matchday } })
        }
      },
      teamCode(team) {
        const l = this.getTeamLogo(team)
        if(l) {
          return l
        }
        return null
      },
      teamLogo(team) {
        const l = this.getTeamLogo(team)
        if(l) {
          return this.getBGValue('spmau') + '/images/jerseys/72/' + l
        }
        return null
      },
    },
    computed: {
      matchRunning() {
        return (this.isNumeric(this.match.livegoalshome) && this.isNumeric(this.match.livegoalsguest)) ||
            (this.isNumeric(this.match.goalshome) && this.isNumeric(this.match.goalsguest))
      }
    }
  }
</script>

<style lang="scss">
  .dashboard-matchday {

    &__match {
      position: relative;
      background: rgba($colorGreyDark, .04);
      overflow: hidden;
      box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

      &--won {
        background: rgba($colorYellow, .08);

        &:before {
          content: '';
          position: absolute;
          background: url('../assets/confetti.png');
          background-size: cover;
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        display: flex;
        font-size: 14px;
        color: $colorGreyDark;
        justify-content: space-between;
        align-items: center;
        padding: 15px 16px 8px;

        &__left, &__right {
          flex: 1;
        }

        &__right {
          text-align: right;
        }

        &__center {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: 500;
          color: $colorGreyDark;

          span {
            display: inline-flex;
            font-size: 10px;
            font-weight: 700;
            padding: 4px 6px;
            border-radius: 3px;
            background: $colorLive;
            color: $colorWhite;
            margin: 0 6px;
          }
        }
      }

      &__teams {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -40px;

        &__left {
          margin-left: -68px;

          img, svg {
            width: 172px;
            height: 172px;
            flex-shrink: 0;
          }

          .flag-icon, span {
            width: 72px !important;
            height: 72px !important;
            margin: 28px 0 76px calc(68px + 32px);
            border-radius: 50%;
          }
        }

        &__center {
          flex: 1;
          margin-top: -48px;
        }

        &__right {
          margin-right: -68px;

          img, svg {
            width: 172px;
            height: 172px;
            flex-shrink: 0;
          }

          .flag-icon, span {
            width: 72px !important;
            height: 72px !important;
            margin: 28px calc(68px + 32px) 76px 0;
            border-radius: 50%;
          }
        }
      }

      &__bet {
        display: flex;
        justify-content: center;
        font-size: 48px;
        font-weight: 500;
        color: $colorGreyDark;

        &__left, &__center, &__right {
          flex: 1;
        }

        &__left {
          text-align: right;
        }

        &__center {
          transform: translateY(-3px);
          max-width: 20px;
          text-align: center;
        }

        &__right {
          text-align: left;
        }
      }

      &__toto {
        display: flex;
        justify-content: center;
        margin: 16px auto 8px;

        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          font-size: 20px;
          font-weight: 500;
          color: rgba($colorGreyDark, .24);
          background: #F1F1F5;
          border-radius: 4px;

          &:nth-child(2) {
            margin: 0 8px;
          }

          &--selected {
            color: $colorWhite;
            background: var(--color-primary);
          }
        }
      }

      &__result {
        display: flex;
        justify-content: center;
        font-size: 17px;
        font-weight: 500;
        color: rgba($colorGreyDark, .48);

        &--live {
          color: $colorLive;
        }

        &__left, &__center, &__right {
          flex: 1;
        }

        &__left {
          text-align: right;
        }

        &__center {
          transform: translateY(-1px);
          max-width: 12px;
          text-align: center;
        }

        &__right {
          text-align: left;
        }
      }
    }
  }

  .dashboard-navigation {
    display: flex;
    width: 64px;
    margin: -16px auto -8px;
    position: relative;
    background: $colorWhite;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba($colorGreyDark, .08);
    transition: all .2s ease-in-out;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      cursor: pointer;
      &:hover {
        background: rgba($colorGreyDark, .04);
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('../assets/icons/ic_12_next.svg') no-repeat center center;

      }

      &:first-of-type {
        box-shadow: inset -1px 0 0 0 rgba($colorGreyDark, .08);

        &:before {
          transform: scale(-1);
        }
      }

      &--disabled {
        cursor: inherit;

        &:before {
          opacity: .24;
        }

        &:hover {
          background: none;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .dashboard-container {
    margin-bottom: 24px;
  }
</style>
