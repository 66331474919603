<template>

  <div class="dashboard-container">

    <div class="dashboard-matchday__list">

      <!-- List: Title -->
      <div class="dashboard-matchday__list__title">
        <div v-if="this.isBetgame()" class="dashboard-matchday__list__title__left">
          {{ matchdayTextGlobal(match1.matchday, match1.fk_competition) }}
          <span v-if="this.$store.getters.getLiveMatch" style="max-height: 12px;">Live</span>
        </div>
        <div v-else-if="this.isBattle() || this.isTeamsBetgame()" class="dashboard-matchday__list__title__left">
          {{ $t('today') }}
          <span v-if="this.$store.getters.getLiveMatch" style="max-height: 12px;">Live</span>
        </div>
        <!--
        <div class="dashboard-matchday__list__title__right">
          <span>{{ matchdayPoints }}</span>/{{ maxPoints }}
        </div>
        -->
      </div>

      <div class="dashboard-matchday__list__teams">

        <!-- Match 1 -->
        <div class="dashboard-matchday__list__teams__item" v-bind:class="getMatchClass(match1)">

          <img v-if="teamCode(match1.fk_team_home) && teamCode(match1.fk_team_home).length > 6" :src="teamLogo(match1.fk_team_home)" width="24">
          <flag v-else-if="teamCode(match1.fk_team_home) && teamCode(match1.fk_team_home).length <= 6" :iso="teamCode(match1.fk_team_home)" />
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match1.fk_team_home, true)}"></path>
          </svg>
          
          <div class="dashboard-matchday__list__teams__item__center">

            <!-- Bet -->
            <div v-if="this.$store.state.betgame.bet_mode === 1" class="dashboard-matchday__list__teams__item__bet">
              <div class="dashboard-matchday__list__teams__item__bet__left">
                {{ getBetGoalsHome(match1, this.$store.state.user) }}
              </div>
              <div class="dashboard-matchday__list__teams__item__bet__center">
                :
              </div>
              <div class="dashboard-matchday__list__teams__item__bet__right">
                {{ getBetGoalsGuest(match1, this.$store.state.user) }}
              </div>
            </div>

            <!-- Bet: Toto -->
            <div v-else-if="this.$store.state.betgame.bet_mode === 2" class="dashboard-matchday__list__teams__item__toto">
              <div class="dashboard-matchday__list__teams__item__toto__item" v-bind:class="getClass(match1, this.$store.state.user.id, 1)">
                1
              </div>
              <div class="dashboard-matchday__list__teams__item__toto__item" v-bind:class="getClass(match1, this.$store.state.user.id, 0)">
                0
              </div>
              <div class="dashboard-matchday__list__teams__item__toto__item" v-bind:class="getClass(match1, this.$store.state.user.id, 2)">
                2
              </div>
            </div>

            <!-- Result -->
            <!-- TODO @Lennard: Add class ". dashboard-matchday__list__teams__item__result--live" only if match is currently running -->
            <div v-if="getGoalsHome(match1) != null && getGoalsGuest(match1) != null" v-bind:class="getLiveClass(match1)" class="dashboard-matchday__list__teams__item__result">
              <div class="dashboard-matchday__list__teams__item__result__left">
                {{ getGoalsHome(match1) }}
              </div>
              <div class="dashboard-matchday__list__teams__item__result__center">
                :
              </div>
              <div class="dashboard-matchday__list__teams__item__result__right">
                {{ getGoalsGuest(match1) }}
              </div>
            </div>
            <div v-else class="dashboard-matchday__list__teams__item__result">
              {{ getMatchTime(match1) }}
            </div>
          </div>

          <img v-if="teamCode(match1.fk_team_guest) && teamCode(match1.fk_team_guest).length > 6" :src="teamLogo(match1.fk_team_guest)" width="24">
          <flag v-else-if="teamCode(match1.fk_team_guest) && teamCode(match1.fk_team_guest).length <= 6" :iso="teamCode(match1.fk_team_guest)" />
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match1.fk_team_guest, false)}"></path>
          </svg>
          
          <!-- Points -->
          <div v-if="matchStarted(match1)" class="dashboard-matchday__list__teams__item__points">
            <span>{{ getMatchPoints(match1) }}</span>
          </div>
        </div>

        <!-- Match 2 -->
        <div class="dashboard-matchday__list__teams__item" v-bind:class="getMatchClass(match2)">

          <img v-if="teamCode(match2.fk_team_home) && teamCode(match2.fk_team_home).length > 6" :src="teamLogo(match2.fk_team_home)" width="24">
          <flag v-else-if="teamCode(match2.fk_team_home) && teamCode(match2.fk_team_home).length <= 6" :iso="teamCode(match2.fk_team_home)" />
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match2.fk_team_home, true)}"></path>
          </svg>

          <div class="dashboard-matchday__list__teams__item__center">

            <!-- Bet -->
            <div v-if="this.$store.state.betgame.bet_mode === 1" class="dashboard-matchday__list__teams__item__bet">
              <div class="dashboard-matchday__list__teams__item__bet__left">
                {{ getBetGoalsHome(match2, this.$store.state.user) }}
              </div>
              <div class="dashboard-matchday__list__teams__item__bet__center">
                :
              </div>
              <div class="dashboard-matchday__list__teams__item__bet__right">
                {{ getBetGoalsGuest(match2, this.$store.state.user) }}
              </div>
            </div>

            <!-- Bet: Toto -->
            <div v-else-if="this.$store.state.betgame.bet_mode === 2" class="dashboard-matchday__list__teams__item__toto">
              <div class="dashboard-matchday__list__teams__item__toto__item" v-bind:class="getClass(match2, this.$store.state.user.id, 1)">
                1
              </div>
              <div class="dashboard-matchday__list__teams__item__toto__item" v-bind:class="getClass(match2, this.$store.state.user.id, 0)">
                0
              </div>
              <div class="dashboard-matchday__list__teams__item__toto__item" v-bind:class="getClass(match2, this.$store.state.user.id, 2)">
                2
              </div>
            </div>

            <!-- Result -->
            <!-- TODO @Lennard: Before match has started show this (time of kickoff if match will run on same day or something like "Morgen" or "08.12." if match will start on next day or later) -->
            <div v-if="getGoalsHome(match2) != null && getGoalsGuest(match2) != null" v-bind:class="getLiveClass(match2)" class="dashboard-matchday__list__teams__item__result">
              <div class="dashboard-matchday__list__teams__item__result__left">
                {{ getGoalsHome(match2) }}
              </div>
              <div class="dashboard-matchday__list__teams__item__result__center">
                :
              </div>
              <div class="dashboard-matchday__list__teams__item__result__right">
                {{ getGoalsGuest(match2) }}
              </div>
            </div>
            <div v-else class="dashboard-matchday__list__teams__item__result">
              {{ getMatchTime(match2) }}
            </div>
          </div>

          <img v-if="teamCode(match2.fk_team_guest) && teamCode(match2.fk_team_guest).length > 6" :src="teamLogo(match2.fk_team_guest)" width="24">
          <flag v-else-if="teamCode(match2.fk_team_guest) && teamCode(match2.fk_team_guest).length <= 6" :iso="teamCode(match2.fk_team_guest)" />
          <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match2.fk_team_guest, false)}"></path>
          </svg>

          <!-- Points -->
          <div v-if="matchStarted(match2)" class="dashboard-matchday__list__teams__item__points">
            <span>{{ getMatchPoints(match2) }}</span>
          </div>
          
        </div>
      </div>

    </div>

    <div class="dashboard-ranking">
      <!--
      <div v-for="(ranking, index) in matchdayRankingShort" class="dashboard-ranking__item">
        <div v-if="index === 0 || matchdayRankingShort[index-1].position < matchdayRankingShort[index].position" class="dashboard-ranking__item__position">
          {{ ranking.position }}.
        </div>
        <div v-else class="dashboard-ranking__item__position">
        </div>
        <div class="dashboard-ranking__item__name">
          {{ ranking.user_name }}
        </div>
        <div class="dashboard-ranking__item__points">
          {{ ranking.points_total }}
        </div>
      </div>
      -->
      <button v-if="pendingTipsCountCurrentBetgame === 0" v-on:click="rankingsButtonClicked">{{ $t('show_matchday_rankings') }}</button>
      <button v-else v-on:click="betsButtonClicked">{{ $t('show_edit_bets') }}</button>
    </div>

  </div>

</template>

<script>
  import { isNumeric } from '@/vuex/helpers'

  export default {
    name: 'DashboardLiveStatisticsTwoMatches',
    data() {
      return {
        matchcount: 2
      }
    },
    props: {
      matches: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    methods: {
      matchStarted(match) {
        if(this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
            return true
        }
        if(this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) {
          return true
        }
        return false
      },
      getMatchPoints(match) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, this.$store.state.user.id)
        if(bet) {
          const p = this.getPoints(bet, match)
          return p
        }
        return 0
      },
      getMatchClass(match ) {
        if(isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest) && !match.goalshome && !match.goalsguest) {
          return { 'dashboard-matchday__list__teams__item--live': true }
        }
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, this.$store.state.user.id)
        if(bet !== undefined && bet !== null && isNumeric(match.goalshome) && isNumeric(match.goalsguest)) {
          if(match.goalshome === bet.goalshome && match.goalsguest === bet.goalsguest) {
            return { 'dashboard-matchday__list__teams__item--exact': true }
          } else if((match.goalshome > match.goalsguest && bet.goalshome > bet.goalsguest) ||
              (match.goalshome < match.goalsguest && bet.goalshome < bet.goalsguest) ||
              (match.goalshome === match.goalsguest && bet.goalshome === bet.goalsguest)) {
            return { 'dashboard-matchday__list__teams__item--result': true }
          } else if(match.goalshome - match.goalsguest === bet.goalshome - bet.goalsguest) {
            return { 'dashboard-matchday__list__teams__item--tendency': true }
          } else {
            return { 'dashboard-matchday__list__teams__item--lost': true }
          }
        }
        return { 'dashboard-matchday__list__teams__item--lost': true }
      },
      getColor(teamId, home) {
        const colors = [
          {
            "id": 190,
            "h": "#dce6fc",
            "a": "#81287f"
          },
          {
            "id": 356,
            "h": "#1b345f",
            "a": "#eeeae3"
          },
          {
            "id": 174,
            "h": "#ae000e",
            "a": "#d1dced"
          },
          {
            "id": 358,
            "h": "#94252a",
            "a": "#e9edf1"
          },
          {
            "id": 980,
            "h": "#df2f4d",
            "a": "#04202b"
          },
          {
            "id": 172,
            "h": "#338de6",
            "a": "#e5e3fd"
          },
          {
            "id": 180,
            "h": "#bec3cf",
            "a": "#0934b5"
          },
          {
            "id": 186,
            "h": "#f3312a",
            "a": "#e4e98c"
          },
          {
            "id": 175,
            "h": "#dd1418",
            "a": "#f2f4f4"
          },
          {
            "id": 179,
            "h": "#dddcd8",
            "a": "#45444b"
          },
          {
            "id": 455,
            "h": "#870218",
            "a": "#eeeee9"
          },
          {
            "id": 981,
            "h": "#e6effe",
            "a": "#034fb2"
          },
          {
            "id": 189,
            "h": "#4351ea",
            "a": "#d2d3d0"
          },
          {
            "id": 182,
            "h": "#ee6615",
            "a": "#181ca9"
          },
          {
            "id": 181,
            "h": "#e82830",
            "a": "#ccd4d8"
          },
          {
            "id": 185,
            "h": "#e7e8f3",
            "a": "#e92c2f"
          },
          {
            "id": 177,
            "h": "#a01224",
            "a": "#75b5e1"
          },
          {
            "id": 984,
            "h": "#e6da00",
            "a": "#c72b37"
          },
          {
            "id": 357,
            "h": "#035bf7",
            "a": "#deecfa"
          },
          {
            "id": 183,
            "h": "#fce252",
            "a": "#1a4687"
          },
          {
            "id": 997,
            "h": "#cfd0de",
            "a": "#ba1e2c"
          },
          {
            "id": 188,
            "h": "#d11e1f",
            "a": "#cedee2"
          },
          {
            "id": 184,
            "h": "#96000e",
            "a": "#e8e8e8"
          },
          {
            "id": 171,
            "h": "#f6f8fc",
            "a": "#cb1d25"
          }
        ]
        const c = colors.filter(function(item) { return item.id === teamId; })
        if (c && c.length === 1) {
          return home ? c[0].h : c[0].a
        }
        const team = this.$store.getters.getTeamById(teamId)
        if(team != null && team.color !== null && team.color.length > 0) {
          return team.color
        }
        return '#0C3EC1'
      },
      getLiveClass(match) {
        if(isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)) {
          return { 'dashboard-matchday__list__teams__item__result--live': true }
        } else {
          return { 'dashboard-matchday__list__teams__item__result--live': false }
        }
      },
      getClass(match, user, bettype ) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
        if(bet !== undefined && bet !== null) {
          return { 'dashboard-matchday__list__teams__item__toto__item--selected': bet.goalshome === bettype }
        }
        return { 'dashboard-matchday__list__teams__item__toto__item--selected': false }
      },
      betsButtonClicked() {
        if(!this.match1 || this.match1.matchday === 0) {
          this.$router.push({ name: 'bet' })
        } else {
          this.$router.push({ name: 'bet', params: { matchday: this.match1.matchday } })
        }
      },
      rankingsButtonClicked() {
        if(!this.match1 || this.match1.matchday === 0) {
          this.$router.push({ name: 'rankingsbymatchday' })
        } else {
          this.$router.push({ name: 'rankingsbymatchday', params: { matchday: this.match1.matchday } })
        }
      },
      isToday(date) {
        const today = new Date()
        const matchDate = new Date(date)
        return today.getDate() === matchDate.getDate() &&
            today.getMonth() === matchDate.getMonth() &&
            today.getFullYear() === matchDate.getFullYear()
      },
      getMatchTime(match) {
        if(match.matchdate !== null) {
          if(this.isToday(match.matchdate)) {
            return match.matchdate.substring(11, 16)
          } else {
            return match.matchdate.substring(5, 10)
          }
        }
        return null
      },
      getGoalsHome(match) {
        if(isNumeric(match.livegoalshome)) {
          return match.livegoalshome
        } else if(isNumeric(match.goalshome)) {
          return match.goalshome
        }
        return null
      },
      getGoalsGuest(match) {
        if(isNumeric(match.livegoalsguest)) {
          return match.livegoalsguest
        } else if(isNumeric(match.goalsguest)) {
          return match.goalsguest
        }
        return null
      },
      getBetGoalsHome(match, user) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user.id)
        if(bet !== undefined && bet !== null) {
          return bet.goalshome
        }
        return '-'
      },
      getBetGoalsGuest(match, user) {
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user.id)
        if(bet !== undefined && bet !== null) {
          return bet.goalsguest
        }
        return '-'
      },
      teamCode(team) {
        const l = this.getTeamLogo(team)
        if(l) {
          return l
        }
        return null
      },
      teamLogo(team) {
        const l = this.getTeamLogo(team)
        if(l) {
          return this.getBGValue('spmau') + '/images/jerseys/24/' + l
        }
        return null
      }
    },
    computed: {
      pendingTipsCountCurrentBetgame() {
        if(this.$store.state.missing_bets !== undefined) {
          const mb = this.$store.state.missing_bets
          var result = 0
          for (var i = 0; i < mb.length; i++) {
            const item = mb[i]
            if (item.matchcount > item.betcount && item.id === this.$store.state.betgame.id) {
              result += item.matchcount - item.betcount
            }
          }
          return result
        } else {
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
          return 0
        }
      },
      matchdayRankingShort() {
        const rankings = this.$store.state.liveRankings
        if(rankings && rankings.length > 3) {
          var t = []
          var hasPoints = false
          for(var i = 0; i < rankings.length; i++) {
            const lp = this.matchdayPoints
            if(lp > 0) {
              hasPoints = true
            }
            hasPoints = true
            if(i > 0 && rankings[i].points_total === rankings[i - 1].points_total) {
              //rankings[i].position = rankings[i - 1].position
            } else {
              //rankings[i].position = i + 1
            }
            if(rankings[i].fk_user === this.$store.state.user.id) {
              if(i === 0) {
                t.push(rankings[i])
                t.push(rankings[i + 1])
                t.push(rankings[i + 2])
              } else if (i > 0) {
                if(i === rankings.length - 1 && rankings.length > 2) {
                  t.push(rankings[i - 2])
                  t.push(rankings[i - 1])
                  t.push(rankings[i])
                } else {
                  t.push(rankings[i - 1])
                  t.push(rankings[i])
                  if (i < rankings.length - 1) {
                    t.push(rankings[i + 1])
                  }
                }
              }
            }
          }
          if(!hasPoints) {
            const bu = this.$store.state.betgameUsers || []
            var res = []
            for (var j = 0; j < bu.length; j++) {
              if(bu[j].fk_user === this.$store.state.user.id) {
                if (j > 0) {
                  res.push(bu[j - 1])
                }
                res.push(bu[j])
                if (j < bu.length - 1) {
                  res.push(bu[j + 1])
                }
              }
            }
            return res
          }
          t = t.sort(function (a, b) {
            return b.points_total - a.points_total
          })
          return t
        } else {
          return rankings
        }
      },
      match1() {
        return this.matches[0]
      },
      match2() {
        return this.matches[1]
      },
      maxPoints() {
        return this.matchcount * this.$store.state.betgame.points_bet_result
      },
      matchdayPoints () {
        var result = 0
        const bet1 = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, this.match1.id, this.$store.state.user.id)
        if (bet1 !== undefined && bet1 !== null) {
          const p = this.getPoints(bet1, this.match1)
          result += p
        }
        const bet2 = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, this.match2.id, this.$store.state.user.id)
        if (bet2 !== undefined && bet2 !== null) {
          const p = this.getPoints(bet2, this.match2)
          result += p
        }
        return result
      }
    }
  }
</script>

<style lang="scss">
  .dashboard-matchday {

    &__list {

      &__title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 16px;

        &__left {
          display: flex;
          font-size: 17px;
          color: $colorGreyDark;
          font-weight: 500;

          span {
            display: inline-flex;
            font-size: 10px;
            font-weight: 700;
            padding: 4px 6px;
            border-radius: 3px;
            background: $colorLive;
            color: $colorWhite;
            margin: 0 6px;
            text-transform: uppercase;
          }
        }

        &__right {
          position: relative;
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          color: $colorGreyDark;
          margin-top: 9px;

          span {
            font-size: 62px;
          }

          &:before {
            content: 'Pkt.';
            position: absolute;
            top: -11px;
            right: 0;
            font-size: 14px;
            font-weight: 400;
            color: $colorGreyMid;
          }
        }
      }

      &__teams {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(1, 1fr); 
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        padding: 0 16px;

        &__item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: $colorWhite;
          box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .08), 0 8px 24px 0 rgba($colorGreyDark, .08);
          padding: 12px 12px;
          border-radius: 4px;
          overflow: hidden;

          &--exact {
            box-shadow: inset 0 0 0 2px $colorPositive, 0 8px 24px 0 rgba($colorGreyDark, .08);
          }

          &--tendency {
            box-shadow: inset 0 0 0 2px $colorYellow, 0 8px 24px 0 rgba($colorGreyDark, .08);
          }

          &--result {
            box-shadow: inset 0 0 0 2px $colorGreyDark, 0 8px 24px 0 rgba($colorGreyDark, .08);
          }

          &--live {
            box-shadow: inset 0 0 0 2px $colorLive, 0 8px 24px 0 rgba($colorGreyDark, .08);
          }

          img, svg {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
          }

          .flag-icon span {
            width: 28px !important;
            height: 28px;
            margin: 6px;
            border-radius: 50%;
            flex-shrink: 0;
            box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .12)
          }

          &__center {
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          &__bet {
            display: flex;
            justify-content: center;
            font-size: 17px;
            font-weight: 500;
            color: $colorGreyDark;

            @media screen and (min-width: 768px) {
              font-size: 20px;
            }

            &__left, &__center, &__right {
              flex: 1;
            }

            &__left {
              text-align: right;
            }

            &__center {
              transform: translateY(-1px);
              max-width: 10px;
              text-align: center;
            }

            &__right {
              text-align: left;
            }
          }

          &__toto {
            display: flex;
            justify-content: center;
            margin: 2px auto 2px;

            &__item {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 16px;
              height: 16px;
              font-size: 11px;
              font-weight: 500;
              color: rgba($colorGreyDark, .24);
              background: #F1F1F5;
              border-radius: 3px;

              &:nth-child(2) {
                margin: 0 2px;
              }

              &--selected {
                color: $colorWhite;
                background: var(--color-primary);
              }
            }
          }

          &__result {
            display: flex;
            justify-content: center;
            font-size: 10px;
            font-weight: 500;
            color: rgba($colorGreyDark, .48);

            &--live {
              color: $colorLive;
            }

            &__left, &__center, &__right {
              flex: 1;
            }

            &__left {
              text-align: right;
            }

            &__center {
              transform: translateY(-0.5px);
              max-width: 6px;
              text-align: center;
            }

            &__right {
              text-align: left;
            }
          }

          &__points {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            display: flex;
            justify-content: center;

            span {
              position: relative;
              z-index: 2;
              font-size: 14px;
              font-weight: 500;
              color: $colorGreyDark;
              text-align: center;
              margin-top: 3px;

              .dashboard-matchday__list__teams__item--exact &,
              .dashboard-matchday__list__teams__item--tendency &,
              .dashboard-matchday__list__teams__item--result &,
              .dashboard-matchday__list__teams__item--live & {
                color: $colorWhite;
              }
            }

            &:before {
              content: '';
              position: absolute;
              z-index: 1;
              top: 0;
              right: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-color: rgba($colorGreyDark, .08) transparent transparent transparent;
              border-width: 36px 0 0 36px;

              .dashboard-matchday__list__teams__item--exact & {
                border-color: $colorPositive transparent transparent transparent;
              }

              .dashboard-matchday__list__teams__item--tendency & {
                border-color: $colorYellow transparent transparent transparent;
              }

              .dashboard-matchday__list__teams__item--result & {
                border-color: $colorGreyDark transparent transparent transparent;
              }

              .dashboard-matchday__list__teams__item--live & {
                border-color: $colorLive transparent transparent transparent;
              }
            }
          }
        }
      }

      &__chart {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        min-height: 120px;
        padding: 0 10px 16px;
        margin-bottom: -16px;
        overflow-x: scroll;

        &__item {
          width: 100%;
          min-width: 24px;
          max-width: 64px;
          margin: 0 6px;

          &__bar {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            border-radius: 2px;
            font-size: 14px;
            line-height: 9px;
            font-weight: 600;
            color: rgba($colorGreyDark, .16);
            background: rgba($colorGreyDark, .24);
            height: 2px;
            margin-bottom: 7px;
            box-sizing: border-box;

            span {
              margin-bottom: 8px;
            }

            .dashboard-matchday__list__chart__item--exact & {
              background: $colorPositive;
              height: 96px;
              color: $colorWhite;
            }

            .dashboard-matchday__list__chart__item--tendency & {
              background: $colorYellow;
              height: 64px;
              color: $colorWhite;
            }

            .dashboard-matchday__list__chart__item--result & {
              background: $colorGreyDark;
              height: 32px;
              color: $colorWhite;
            }

            .dashboard-matchday__list__chart__item--lost & {
              background: $colorGreyDark;
              height: 2px;
              color: $colorGreyDark;
            }

            .dashboard-matchday__list__chart__item--live & {
              background: $colorLive !important;
            }
          }

          &__label {
            font-size: 11px;
            font-weight: 500;
            line-height: 10px;
            color: $colorGreyMid;
            text-align: center;
            opacity: .48;

            .dashboard-matchday__list__chart__item--live & {
              color: $colorLive;
              opacity: 1;
            }

            &--highlight {
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .dashboard-container {
    margin-bottom: 24px;
  }
</style>
