<template>
    <div class="matchday matchday--toto">
        <div v-if="previousMatch.id === match.id || previousMatch.matchdate !== match.matchdate || previousMatch.matchday !== match.matchday || previousMatch.fk_competition !== match.fk_competition" class="edit_bet_row">
            <div class="column matchdate">
                <span>{{ match.matchdate | formatDate }}</span>
                <span v-if="isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)" class="matchdate_time match_result_live">Live</span>
                <span v-else class="matchdate_time">{{ match.matchdate | formatTime }}</span>
            </div>
        </div>
        <div class="matchday_list" v-bind:class="{ matchday_list_single: firstInGroup && lastInGroup,
        matchday_list_first: firstInGroup && !lastInGroup, matchday_list_last: lastInGroup && !firstInGroup,
         bet_exact_result: points === pointsBetResult, bet_goal_diff: points === pointsBetGoaldiff, bet_result_tendency: points === pointsBetTendency, bet_wrong_result: points === 0 }">
            <div class="match">
                <div class="match-home">
                    <div class="team-logo">
                      <img v-if="homeTeamCode && homeTeamCode.length > 6" :src="homeTeamLogo" width="24">
                      <flag v-else-if="homeTeamCode && homeTeamCode.length <= 6" :iso="homeTeamCode" />
                      <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_home)}"></path>
                      </svg>
                    </div>
                    <div class="match-teamname">{{ getTeamName(match.fk_team_home, match.team_home) }}</div>
                </div>
                <div class="match-goals" style="padding: 8px 0px; display: flex; flex-direction: column">
                  <div class="toto" v-bind:class="[ betEditable ? '' : 'match-finished' ]">
                    <button :disabled="!betEditable" id="toto1Button" v-on:click="toto1Clicked($event)" 
                            :class="{ toto_button: true, toto_button_selected: this.bet.goalshome === 1, toto_button_win: totoWin(1), toto_button_lose: totoLose(1) }">1</button>
                    <button :disabled="!betEditable" id="toto0Button" v-on:click="toto0Clicked($event)" 
                            :class="{ toto_button: true, toto_button_selected: this.bet.goalshome === 0, toto_button_win: totoWin(0), toto_button_lose: totoLose(0) }">0</button>
                    <button :disabled="!betEditable" id="toto2Button" v-on:click="toto2Clicked($event)" 
                            :class="{ toto_button: true, toto_button_selected: this.bet.goalshome === 2, toto_button_win: totoWin(2), toto_button_lose: totoLose(2) }">2</button>
                  </div>
                  <div v-if="match.status && match.status.length > 0" class="toto_result">
                    <div class="match_result_home">{{ match.status }}</div>
                  </div>
                  <div v-else-if="isOvertimeResult" class="toto_result">
                    <div class="match_result_home">{{ overtimeResultString }}</div>
                  </div>
                  <div v-else class="toto_result">
                    <div v-if="isNumeric(match.goalshome)" class="match_result_home">{{ match.goalshome }}</div>
                    <div v-else-if="isNumeric(match.livegoalshome)" class="match_result_home">{{ match.livegoalshome }}</div>
                    <div v-if="isNumeric(match.goalshome) && isNumeric(match.goalsguest)" class="match_result_divider">:</div>
                    <div v-else-if="isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)" class="match_result_divider match_result_live">:</div>
                    <div v-if="isNumeric(match.goalsguest)" class="match_result_guest">{{ match.goalsguest }}</div>
                    <div v-else-if="isNumeric(match.livegoalsguest)" class="match_result_guest">{{ match.livegoalsguest }}</div>
                  </div>
                </div>
                <div class="match-guest">
                    <div class="match-teamname">{{ getTeamName(match.fk_team_guest, match.team_guest) }}</div>
                    <div class="team-logo">
                        <img v-if="guestTeamCode && guestTeamCode.length > 6" :src="guestTeamLogo" width="24">
                        <flag v-else-if="guestTeamCode && guestTeamCode.length <= 6" :iso="guestTeamCode" />
                        <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_guest)}"></path>
                        </svg>
                    </div>
                </div>
                <div v-if="betEditable && showOdds && getMatchBet && isValidBet" class="match-quotes">
                    <div class="match-quotes-item" v-bind:class="[ bet_home_wins ? 'active' : '' ]">
                        {{ getMatchBet.bet_1 }}
                    </div>
                    <div class="match-quotes-item" v-bind:class="[ bet_draw ? 'active' : '' ]">
                        {{ getMatchBet.bet_x }}
                    </div>
                    <div class="match-quotes-item" v-bind:class="[ bet_guest_wins ? 'active' : '' ]">
                        {{ getMatchBet.bet_2 }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { isNumeric } from '../vuex/helpers'
import moment from 'moment'
import { RESULT_MODE_120M, RESULT_MODE_90M, RESULT_MODE_TOTAL } from '@/main'

export default {
        name: 'EditBetRowToto',
        data () {
          return {
            betSaved: 0,
            homeBetOldValue: null,
            guestBetOldValue: null
          }
        },
        props: {
            match: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            previousMatch: {
                type: Object,
                default: () => {
                    return {}
                }
            },
          nextMatch: {
            type: Object,
            default: () => {
              return {}
            }
          },
            matchIndex: {
                type: Number,
                default: () => {
                    return 1
                }
            }
        },
        mounted() {
          this.homeBetOldValue = this.bet.goalshome
          this.guestBetOldValue = this.bet.goalsguest
        },
        computed: {
          resultmode() {
            return this.$store.state.betgame.result_mode || RESULT_MODE_TOTAL
          },
          betmode() {
            return this.$store.state.betgame.bet_mode || 1
          },
          isOvertimeResult() {
            return isNumeric(this.match.goalshome_90) && isNumeric(this.match.goalsguest_90) &&
                parseInt(this.match.goalshome_90) >= 0 && parseInt(this.match.goalsguest_90) >= 0
          },
          overtimeResultString() {
            var result = ''
            if (isNumeric(this.match.goalshome) && isNumeric(this.match.goalsguest) &&
                parseInt(this.match.goalshome) >= 0 && parseInt(this.match.goalsguest) >= 0) {
              result = this.match.goalshome + ':' + this.match.goalsguest
            } else if (isNumeric(this.match.livegoalshome) && isNumeric(this.match.livegoalsguest) &&
                parseInt(this.match.livegoalshome) >= 0 && parseInt(this.match.livegoalsguest) >= 0) {
              result = this.match.livegoalshome + ':' + this.match.livegoalsguest
            }
            if (isNumeric(this.match.goalshome_120) && isNumeric(this.match.goalsguest_120) &&
                parseInt(this.match.goalshome_120) >= 0 && parseInt(this.match.goalsguest_120) >= 0) {
              result += ' ' + this.$i18n.t('penalty_result_short') + ' (' + this.match.goalshome_120 + ':' + this.match.goalsguest_120 + ", " +
                  this.match.goalshome_90 + ':' + this.match.goalsguest_90 + ')'
            } else if (isNumeric(this.match.goalshome_90) && isNumeric(this.match.goalsguest_90) &&
                parseInt(this.match.goalshome_90) >= 0 && parseInt(this.match.goalsguest_90) >= 0) {
              result += ' ' + this.$i18n.t('overtime_result_short') + ' (' + this.match.goalshome_90 + ':' + this.match.goalsguest_90 + ')'
            }
            return result
          },
          isValidBet() {
            const b = this.getMatchBet
            if(b !== null) {
              return this.isValidBwinBet(b)
            }
            return false
          },
          pointsBetResult() {
            return this.$store.state.betgame.points_bet_result
          },
          pointsBetGoaldiff() {
            return this.$store.state.betgame.points_bet_goaldiff
          },
          pointsBetTendency() {
            return this.$store.state.betgame.points_bet_tendency
          },
          bet_home_wins() {
            return isNumeric(this.bet.goalshome) && this.bet.goalshome === 1
          },
          bet_guest_wins() {
            return isNumeric(this.bet.goalshome) && this.bet.goalshome === 2
          },
          bet_draw() {
            return isNumeric(this.bet.goalshome) && this.bet.goalshome === 0
          },
          getMatchBet() {
            if(this.match) {
              const r = this.$store.getters.getMatchBet(this.match.id)
              if(r.length > 0) {
                return r[0]
              }
            }
            return null
          },
          homeTeamCode() {
            const l = this.getTeamLogo(this.match.fk_team_home)
            if(l) {
              return l
            }
            return null
          },
          guestTeamCode() {
            const l = this.getTeamLogo(this.match.fk_team_guest)
            if(l) {
              return l
            }
            return null
          },
          homeTeamLogo() {
            const l = this.getTeamLogo(this.match.fk_team_home)
            if(l) {
              return this.getBGValue('spmau') + '/images/jerseys/24/' + l
            }
            return null
          },
          guestTeamLogo() {
            const l = this.getTeamLogo(this.match.fk_team_guest)
            if(l) {
              return this.getBGValue('spmau') + '/images/jerseys/24/' + l
            }
            return null
          },
            showOdds() {
              if(this.$store.state.betgame && this.$store.state.user) {
                return !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
              }
              return false
            },
            firstInGroup() {
              return this.previousMatch.matchdate !== this.match.matchdate || this.previousMatch.id === this.match.id || 
                  this.previousMatch.matchday !== this.match.matchday || this.previousMatch.fk_competition !== this.match.fk_competition
            },
            lastInGroup() {
              return this.nextMatch.matchdate !== this.match.matchdate || this.nextMatch.id === this.match.id || 
                  this.nextMatch.matchday !== this.match.matchday || this.nextMatch.fk_competition !== this.match.fk_competition
            },
            points () {
              if(this.match === undefined || this.match == null) {
                return -1
              }
              if(this.betEditable) {
                return -1;
              }
              if(!this.isNumeric(this.match.goalshome) && !this.isNumeric(this.match.goalsguest) &&
                !this.isNumeric(this.match.livegoalshome) && !this.isNumeric(this.match.livegoalsguest)) {
                return -1
              }
              if(this.match.status && (this.match.status.trim() === 'annulliert' || this.match.status.trim() === 'abgesagt'
                || this.match.status.trim() === 'verlegt' || this.match.status.trim() === 'Wertung'
                || this.match.status.trim() === 'abgebrochen' || this.match.status.trim() === 'nicht ausgetragen')) {
                return -1
              }
              const userId = this.$store.state.user.id
              const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, this.match.id, userId)
              if(bet !== undefined && bet !== null) {
                return this.getPoints(bet, this.match)
              } else {
                return -1
              }
            },
            betEditable () {
                //const md = Date.parse(this.match.matchdate)
                if(this.isPast(this.match.matchdate) && (this.match.status === null || this.match.status.trim() === '')) {
                  return false;
                }
                if(this.match.status && (this.match.status.trim() === 'annulliert' || this.match.status.trim() === 'abgesagt'
                  || this.match.status.trim() === 'verlegt' || this.match.status.trim() === 'Wertung' 
                  || this.match.status.trim() === 'abgebrochen' || this.match.status.trim() === 'nicht ausgetragen')) {
                  return false
                }
                if(this.isPlaceholderTeam(this.match.fk_team_home) || this.isPlaceholderTeam(this.match.fk_team_guest)) {
                  return false
                }
                return !this.isNumeric(this.match.goalshome) && !this.isNumeric(this.match.goalsguest) &&
                  !this.isNumeric(this.match.livegoalshome) && !this.isNumeric(this.match.livegoalsguest)
            },
            bet () {
                const b = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, this.match.id)
                if (b != null) {
                    return b
                } else {
                    const nb = {
                        'fk_user': parseInt(this.$store.state.user.id),
                        'fk_match': parseInt(this.match.id),
                        'fk_betgame': parseInt(this.$store.state.betgameId)
                    }
                    this.$store.dispatch('addBet', nb)
                    return nb
                }
            },
        },
        methods: {
          getLink() {
            return 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
          },
          oddClicked(data) {
            var url = ''
            const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, this.match.id)
            if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
              url = this.getLink()
            }
            var options = ''
            const bwinbets = this.$store.getters.getMatchBet(this.match.id)
            if(bwinbets.length > 0) {
              const bwinbet =  bwinbets[0]
              if(isNumeric(bwinbet.bet_1) && isNumeric(bwinbet.bet_2) && isNumeric(bwinbet.bet_x)) {
                options += bwinbet.bet_id + '-' + bwinbet.result_market_id + '-'
                if (data === 1) {
                  options += bwinbet.bet_1_id
                  //this.checkUserLoggedIn()
                  //this.saveBet(1)
                } else if (data === 2) {
                  options += bwinbet.bet_2_id
                  //this.checkUserLoggedIn()
                  //this.saveBet(2)
                } else if (data === 0) {
                  options += bwinbet.bet_x_id
                  //this.checkUserLoggedIn()
                  //this.saveBet(0)
                }
              }
            }
            if(options.length > 0) {
              url = 'https://sports.bwin.de/de/sports/fussball-4/wetten?stake=10&wm=5047188&options=' + options
            } else {
              url = this.getLink()
            }
            if(window.parent) {
              window.parent.open(url, '_blank')
            } else {
              window.open(url, '_blank')
            }
          },
          totoWin (betbutton) {
            if (this.betEditable || (this.match && this.match.status && this.match.status.length > 0)) {
              return false
            }
            if (this.bet.goalshome > 2 || this.bet.goalsguest > 2) {
              return false
            }
            if (this.bet.goalshome === parseInt(betbutton)) {
              var goalshome = null
              var goalsguest = null
              if(this.resultmode === RESULT_MODE_90M && this.match.goalshome_90 !== null && this.match.goalsguest_90 !== null) {
                goalshome = parseInt(this.match.goalshome_90)
                goalsguest = parseInt(this.match.goalsguest_90)
              } else if(this.resultmode === RESULT_MODE_120M && this.match.goalshome_120 !== null && this.match.goalsguest_120 !== null) {
                goalshome = parseInt(this.match.goalshome_120)
                goalsguest = parseInt(this.match.goalsguest_120)
              } else if(this.match.livegoalshome !== null && this.match.livegoalsguest !== null &&
                  this.match.goalshome === null && this.match.goalsguest === null) {
                goalshome = parseInt(this.match.livegoalshome)
                goalsguest = parseInt(this.match.livegoalsguest)
              } else if(this.match.goalshome !== null && this.match.goalsguest !== null) {
                goalshome = parseInt(this.match.goalshome)
                goalsguest = parseInt(this.match.goalsguest)
              }
              if (this.bet.goalshome === 1) {
                if (this.isNumeric(goalshome) && this.isNumeric(goalsguest)) {
                  return goalshome > goalsguest
                } else if (this.isNumeric(goalshome) && this.isNumeric(goalsguest)) {
                  return goalshome > goalsguest
                } else {
                  return false
                }
              } else if (this.bet.goalshome === 0) {
                if (this.isNumeric(goalshome) && this.isNumeric(goalsguest)) {
                  return goalshome === goalsguest
                } else if (this.isNumeric(goalshome) && this.isNumeric(goalsguest)) {
                  return livegoalshome === goalsguest
                } else {
                  return false
                }
              } else if (this.bet.goalshome === 2) {
                if (this.isNumeric(goalshome) && this.isNumeric(goalsguest)) {
                  return goalshome < goalsguest
                } else if (this.isNumeric(goalshome) && this.isNumeric(goalsguest)) {
                  return goalshome < goalsguest
                } else {
                  return false
                }
              }
            } else {
              return false
            }
          },
          totoLose(betbutton) {
            if (this.betEditable || (this.match && this.match.status && this.match.status.length > 0)) {
              return false
            }
            if (this.bet.goalshome > 2 || this.bet.goalsguest > 2) {
              return false
            }
            if (this.bet.goalshome === parseInt(betbutton)) {
              return !this.totoWin(betbutton)
            } else {
              return false
            }
          },
          toto1Clicked(event) {
            this.checkUserLoggedIn()
            this.saveBet(1)
          },
          toto0Clicked(event) {
            this.checkUserLoggedIn()
            this.saveBet(0)
          },
          toto2Clicked(event) {
            this.checkUserLoggedIn()
            this.saveBet(2)
          },
          checkUserLoggedIn() {
            if(!this.$store.getters.loggedIn) {
              alert(this.$i18n.t('errors.login_expired') + '\n' + this.$i18n.t('errors.please_login_again'))
              this.$store.dispatch('logout').then(() => {
                this.$router.push('/').then(() => {
                  window.location.reload()
                })
              })
            }
          },
          saveBet(val) {
            const bet = {
              'fk_user': this.$store.state.user.id,
              'fk_match': this.match.id,
              'goalshome': val,
              'goalsguest': val,
              'fk_betgame': parseInt(this.$store.state.betgameId)
            }
            this.betSaved = 3
            this.$store.dispatch('updateBet', bet).then(response => {
              this.homeBetOldValue = this.bet.goalshome
              this.guestBetOldValue = this.bet.goalsguest
              this.betSaved = 1
              this.$store.dispatch('setLastBetSaveDate', moment().format('YYYY-MM-DD HH:mm:ss'))
            }, error => {
              this.bet.goalshome = this.homeBetOldValue
              this.bet.goalsguest = this.guestBetOldValue
              this.betSaved = 2
              if(navigator.onLine === false) {
                alert(this.$i18n.t('errors.save_bet_error_not_connected'))
              } else {
                // const md = Date.parse(this.match.matchdate)
                if (this.isPast(this.match.matchdate)) {
                  alert(this.$i18n.t('errors.save_bet_error_match_running'))
                } else {
                  alert(this.$i18n.t('errors.save_bet_error_try_again_later'))
                }
              }
            })
          },
          getPoints(bet, match) {
            if(bet === null || match === null) {
              return 0
            }
            var goalshome = null
            var goalsguest = null
            if(this.resultmode === RESULT_MODE_90M && match.goalshome_90 !== null && match.goalsguest_90 !== null) {
              goalshome = parseInt(match.goalshome_90)
              goalsguest = parseInt(match.goalsguest_90)
            } else if(this.resultmode === RESULT_MODE_120M && match.goalshome_120 !== null && match.goalsguest_120 !== null) {
              goalshome = parseInt(match.goalshome_120)
              goalsguest = parseInt(match.goalsguest_120)
            } else if(match.livegoalshome !== null && match.livegoalsguest !== null &&
                match.goalshome === null && match.goalsguest === null) {
              goalshome = parseInt(match.livegoalshome)
              goalsguest = parseInt(match.livegoalsguest)
            } else if(match.goalshome !== null && match.goalsguest !== null) {
              goalshome = parseInt(match.goalshome)
              goalsguest = parseInt(match.goalsguest)
            }
            if(goalshome !== null && goalsguest !== null) {
              if ((bet.goalshome === 1) && (goalshome > goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome === 0) && (goalshome === goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome === 2) && (goalshome < goalsguest)) {
                return this.$store.getters.pointsBetResult
              } 
            }
            return 0
          }
        }
    }
</script>

<style lang="scss" scoped>
    
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type=number] {
        -moz-appearance: textfield;
    }
    
    @keyframes live {
        0%   { opacity: .24; transform: scale(.95); }
        50%  { opacity: 1; transform: scale(1);}
        100% { opacity: .24; transform: scale(.95);}
    }

    .matchday {

        .matchdate{
            font-size: 14px;
            color: $colorGreyMid;
            margin: -3px 0 8px;

            .matchdate_time {
                float: right;
            }

            .match_result_live {
                color: var(--color-primary);
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
                padding-right: 12px;
                font-size: 14px;
                opacity: .24;
                transform: scale(.2);
                animation: live 3.4s infinite;

                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 5px;
                    width: 6px;
                    height: 6px;
                    border-radius: 3px;
                    background: var(--color-primary);
                }
            }
        }

        &_list, &_list_first, &_list_last {
            background: $colorWhite;
            box-shadow: 0 8px 24px 0 rgba(49,49,55,0.04);
            position: relative;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 16px;
                width: calc(100% - 32px);
                height: 1px;
                background: rgba($colorGreyDark, 0.08);
            }

            &_first {
                border-radius: 11px 11px 0 0;
            }

            &_last {
                border-radius: 0 0 11px 11px;
                margin-bottom: 16px;

                &:before {
                    display: none;
                }
            }
    
            &_single {
                border-radius: 11px 11px 11px 11px;
                margin-bottom: 16px;
    
                &:before {
                    display: none;
                }
            }
        }
    
        .bet_exact_result {
            background: #EEFDF5;
        }
        .bet_goal_diff {
            background: #FFFAF0;
        }
        .bet_result_tendency {
            background: #F5F5F5;
        }
        .bet_wrong_result {
            background: #FFF1F0;
        }
    }

    .match {
        display: flex;
        flex-wrap: wrap;
        padding: 0 8px;
        align-items: center;
        position: relative;

        .matchday--toto & {
          align-items: flex-start;
        }

        &-home, &-guest {
            display: flex;
            align-items: center;
            width: calc(50% - 42px);

            .matchday--toto & {
              margin-top: 6px;
            }

            img, svg {
                margin-bottom: -2px;
            }

            span {
              width: 16px;
              height: 16px;
              border-radius: 8px;
              overflow: hidden;
              box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .08);
              margin: 4px;
            }

            .team-logo {
                padding: 2px;
                margin-right: 8px;
                width: 24px;
                height: 24px;

                @media screen and (max-width: 320px) {
                    margin-right: 4px;
                }
            }
        }

        &-guest {
            justify-content: flex-end;

            .team-logo {
                margin-right: 0;
                margin-left: 8px;

                @media screen and (max-width: 320px) {
                    margin-left: 4px;
                }
            }
        }

        &-teamname {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            color: $colorGreyDark;

            .match-home & {
              padding-right: 4px;
            }

            .match-guest & {
              padding-left: 8px;
              text-align: right;
            }
        }

        &-goals {
            width: 84px;
            display: flex;
            padding: 8px 0;

            .bet_wrong_result &, .bet_result_tendency &, .bet_goal_diff &, .bet_exact_result & {
                padding: 3px 0 5px;
            }

            input {
                width: 28px;
                height: 28px;
                border-radius: 3px;
                border: 0;
                background: #F1F1F5;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                font-family: 'Rubik';
                padding: 0;
                color: $colorGreyDark;

                &:focus {
                    outline: #000;
                    box-shadow: 0 0 0 2px $colorGreyDark;
                }

                .bet_wrong_result &, .bet_result_tendency &, .bet_goal_diff &, .bet_exact_result & {
                    background: none;
                    color: inherit;
                    opacity: 1;
                }
            }

            &-divider {
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                font-family: 'Rubik';
                width: 14px;
                color: $colorGreyDark;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 6px;
                    height: 6px;
                    left: 4px;
                }
                &.bet_saved {
                    &:before {
                        background: url(../assets/icons/ic_bet_saved.svg);
                    }
                }
                &.bet_not_saved {
                    &:before {
                        background-color: red;
                    }
                }
                &.bet_saving {
                    &:before {
                        background: url(../assets/icons/ic_24_update.svg);
                    }
                }
            }

            /* Match Results */
            .match_result_home, .match_result_guest {
                margin-top: -5px;
                font-size: 11px;
                font-family: 'Rubik';
                color: rgba($colorGreyDark, .64);
                font-weight: 500;
                text-align: right;
                white-space: nowrap;
            }
            .match_result_guest {
                text-align: left;
            }
            .match_result_divider {
                margin-top: -2px;
                font-size: 11px;
                color: rgba($colorGreyDark, .64);
                font-weight: 500;
                text-align: center;
            }
            .match_result_live {
                color: rgba(var(--color-primary-rgb), .64);
            }
        }

        &-quotes {
            display: flex;
            flex: 100%;
            justify-content: center;
            margin-bottom: 8px;

            &-item {
                position: relative;
                font-size: 11px;
                font-weight: 500;
                color: $colorGreyDark;
                border: 2px solid rgba($colorGreyLight, .08);
                border-radius: 4px;
                padding: 2px 4px 2px 20px;
                margin: 0 8px;
                cursor: pointer;
                transition: .2s all ease-in-out;

                &:hover {
                  border: 2px solid rgba($colorGreyLight, .12);

                  &:before {
                    background: rgba($colorGreyLight, .12);
                  }
                }

                &.active {
                  border: 2px solid #D1F4CD;

                  &:before {
                    background: #D1F4CD;
                    color: #26BA15;
                  }
                }

                &:before {
                    content: 'X';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 11px;
                    font-weight: 500;
                    color: $colorGreyMid;
                    background: rgba($colorGreyLight, .08);
                    transition: all .2s ease-in-out;
                }

                &:first-child {
                    margin-left: 0;

                    &:before {
                        content: '1';
                    }

                    &:after {
                        position: absolute;
                        left: -28px;
                        top: 3px;
                        text-align: right;
                        font-size: 9px;
                        color: rgba($colorGreyLight, .18);
                    }
                }

                &:last-child {
                    margin-right: 0;

                    &:before {
                        content: '2';
                    }
                }
            }
        }
    }
    
    .guest_team {
        width: 41%;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .bet_input_home {
        text-align: right;
    }
    
    .bet_input_guest {
        text-align: left;
    }
    

    .toto {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_button {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        background: #F1F1F5;
        color: $colorGreyDark;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Rubik';
        padding: 0;
        border-radius: 3px;

        .match-finished & {
          color: rgba($colorGreyDark, .24);
          background: rgba(#000, .04)
        }

        &_selected {
          background: var(--color-primary) !important;
          color: $colorWhite;
          box-shadow: none !important;
        }

        &_win {
          background: $colorPositive !important;
          color: $colorWhite !important;
          box-shadow: none !important;
        }

        &_lose {
          background: $colorNegative !important;
          color: $colorWhite !important;
          box-shadow: none !important;
        }
      }

      &_result {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        margin-bottom: -4px;

        > div {
          margin-top: 0 !important;
        }
      }
    }
    
</style>
