<template>
  <div class="mybetgames" v-bind:style="{ paddingBottom: '32px' }">
  
    <keep-alive>
      <NavigationBar id="mybetgamesNavigationBarId" ref="mybetgamesNavigationBarRef">
        <template v-slot:center>
          {{ $t('my_betgames.my_betgames') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <div v-if="incomingBetgameTransfers.length > 0" class="list p-top-none mybetgameslist">
      <div class="table-view-title">
        {{ $t('my_betgames.transfer_betgame') }}
      </div>
      <a v-for="(transfer, index) in incomingBetgameTransfers" :key="transfer.bg_betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === transfer.bg_betgame.id }"
         v-on:click.stop.prevent="finishBetgameTransfer(transfer.bg_betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(transfer.icon)" v-bind:src="buildIconPath(transfer.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ transfer.bg_betgame.name }}
        </div>
        <div class="table-view-cell-drilldown"></div>
      </a>
    </div>
    
    <div v-if="userBetgames && userBetgames.length > 0" class="list p-top-none mybetgameslist">
  
      <!--
      <router-link to="/" class="router_link">
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_logo.svg">
          </div>
          <div class="table-view-cell-label">
            {Tipprundenname}
          </div>
          <div class="table-view-cell-drilldown">
          </div>
        </div>
      </router-link>
      <router-link to="/" class="router_link">
        <div class="table-view-cell">
          <div class="table-view-cell-icon size-24">
            <img src="../assets/icons/ic_24_logo.svg">
          </div>
          <div class="table-view-cell-label">
            {Tipprundenname}
          </div>
          <div class="table-view-cell-drilldown">
          </div>
        </div>
      </router-link>
      -->
      
      <!--
      <a v-for="(betgame, index) in userBetgamesOwner" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div v-if="betgame.type !== 'NONE'" class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
               {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ $t('admin') }} ∙ {{ getCompetition(betgame.id) }} ∙ {{ getSeason(betgame.id) }}
          </div>
          <div v-else class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
               {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ $t('admin') }} ∙ {{ $t('battle') }}
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value">
          <span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span>
        </div>
        <div v-else-if="competitionFinished(getCompetitionId(betgame.id), getSeasonId(betgame.id), betgame.id)" class="table-view-cell-value">
          <div class="indicator">{{ $t('betgame_finished') }}</div>
        </div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      <a v-for="(betgame, index) in userBetgamesAuthor" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div v-if="betgame.type !== 'NONE'" class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ $t('author') }} ∙ {{ getCompetition(betgame.id) }} ∙ {{ getSeason(betgame.id) }}
          </div>
          <div v-else class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ $t('author') }} ∙ {{ $t('battle') }}
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span></div>
        <div v-else-if="competitionFinished(getCompetitionId(betgame.id), getSeasonId(betgame.id), betgame.id)" class="table-view-cell-value">
          <div class="indicator">{{ $t('betgame_finished') }}</div>
        </div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      <a v-for="(betgame, index) in userBetgamesParticipant" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div v-if="betgame.type !== 'NONE'" class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ getCompetition(betgame.id) }} ∙ {{ getSeason(betgame.id) }}
          </div>
          <div v-else class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              {{ $t('active') }} ∙ 
            </span>
            {{ $t('battle') }}
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span></div>
        <div v-else-if="competitionFinished(getCompetitionId(betgame.id), getSeasonId(betgame.id), betgame.id)" class="table-view-cell-value">
          <div class="indicator">{{ $t('betgame_finished') }}</div>
        </div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      -->
      <a v-for="(betgame, index) in userBetgames" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="switchBetgame(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)">
          <img v-else src="../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div v-if="betgame.type !== 'NONE'" class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            <span v-if="isAdmin(betgame)">{{ $t('admin') }} ∙ </span>
            <span v-else-if="isAuthor(betgame)">{{ $t('author') }} ∙ </span>
            <span v-if="!isTournament(getCompetitionId(betgame.id))">{{ getCompetition(betgame.id) }} ∙ {{ getSeason(betgame.id) }}</span>
            <span v-else>{{ getCompetition(betgame.id) }}</span>
          </div>
          <div v-else class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
              {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            <span v-if="isAdmin(betgame)">{{ $t('admin') }}</span>
            <span v-else-if="isAuthor(betgame)">{{ $t('author') }}</span>
            <span v-if="(isAdmin(betgame) || isAuthor(betgame)) && (betGameType(betgame) === 2 || betGameType(betgame) === 3)"> ∙ </span>
            <span v-if="betGameType(betgame) === 2" style="text-transform: uppercase">{{ $t('create_betgame.select_type_battle') }}</span>
            <span v-else-if="betGameType(betgame) === 3" style="text-transform: uppercase">{{ $t('create_betgame.select_type_teams') }}</span>
          </div>
        </div>
        <div v-if="pendingTipsCount(betgame.id) === 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('one_bet_missing') }}</span></div>
        <div v-else-if="pendingTipsCount(betgame.id) > 1" class="table-view-cell-value"><span class="indicator indicator--warning">{{ pendingTipsCount(betgame.id) }} {{ $t('two_or_more_bets_missing') }}</span></div>
        <div v-else-if="competitionFinished(getCompetitionId(betgame.id), getSeasonId(betgame.id), betgame.id)" class="table-view-cell-value">
          <div class="indicator">{{ $t('betgame_finished') }}</div>
        </div>
        <div class="table-view-cell-drilldown"></div>
      </a>
      
      <!--

      -->
    </div>
    
    <div v-if="!showFinished" v-on:click="showFinishedBetgames()" class="more-betgames">
      <img src="../assets/icons/ic_24_time_history.svg" alt="">
      {{ finishedBetgamesCount }}
    </div>
    <div v-else v-on:click="hideFinishedBetgames()" class="more-betgames">
      {{ $t('my_betgames.hide_fnished_betgames') }}
    </div>
    
    <div class="button__wrapper">
      <router-link to="/searchbetgame" class="router_link">
        <button><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('my_betgames.button_create_join_betgame') }}</button>
      </router-link>
    </div>
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'
import Vue from 'vue'

export default {
  name: 'MyBetgames',
  components: {
    NavigationBar
  },
  data() {
    return {
      showFinished: false
    }
  },
  beforeCreate() {
  },
  mounted() {
  },
  created() {
    //if (this.$store.state.userBetgames == null || this.$store.state.userBetgames.length === 0 || this.$store.state.missing_bets === undefined) {
      this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
    //}
    this.$store.dispatch('fetchBetgameTransfersByUser', this.$store.state.user.id).then(() => {
      Vue.$log.debug('incomingBetgameTransfers ' + JSON.stringify(incomingBetgameTransfers))
    }).catch(function (error) {

    })

    if(!this.$store.state.questions && this.$store.state.betgameId && this.$store.state.betgame && this.$store.state.betgame.season) {
      this.$store.dispatch('fetchQuestions', []).then(function (response) {
    })
    }
    if(!this.$store.state.answers && this.$store.state.betgameId && this.$store.state.user) {
      this.$store.dispatch('updateAnswerCount', false).then(function (response) {
      })
      this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
      })
    }
  },
  computed: {
    finishedBetgamesCount() {
      const p = this.userBetgamesParticipant
      const a = this.userBetgamesAuthor
      const o = this.userBetgamesOwner
      var newArray = p.concat(a).concat(o)
      const that = this
      var temp = newArray.filter(function (item) {
        const c = that.getCompetitionId(item.id)
        const s = that.getSeasonId(item.id)
        return !that.competitionFinished(c, s, item.id)
      });
      return this.$i18n.t('my_betgames.finished_betgames_count', { count: newArray.length - temp.length })
    },
    incomingBetgameTransfers() {
      return this.$store.state.incomingBetgameTransfers
    },
    currentBetgame() {
      return this.$store.state.betgame
    },
    userBetgamesOwner() {
      const newArray = this.$store.state.betgames_owner.filter(function (el) {
        return true;
      });
      return newArray.sort((a, b) => b.id - a.id)
    },
    userBetgamesAuthor() {
      const a = this.$store.state.betgames_author
      const o = this.userBetgamesOwner
      var newArray = a.filter(function (el) {
        return o.filter(function(item) { return item.id === el.id; }).length === 0;
      });
      return newArray.sort((a, b) => b.id - a.id)
    },
    userBetgamesParticipant() {
      const p = this.$store.state.betgames_participant
      const a = this.$store.state.betgames_author
      const o = this.userBetgamesOwner
      var newArray = p.filter(function (el) {
        return o.filter(function(item) { return item.id === el.id; }).length === 0 &&
                a.filter(function(item) { return item.id === el.id; }).length === 0;
      });
      return newArray.sort((a, b) => b.id - a.id)
    },
    userBetgames() {
      const p = this.userBetgamesParticipant
      const a = this.userBetgamesAuthor
      const o = this.userBetgamesOwner
      /*var newArray = p.filter(function (el) {
        return o.filter(function(item) { return item.id === el.id; }).length === 0 &&
            a.filter(function(item) { return item.id === el.id; }).length === 0;
      });
      */
      var newArray = o.concat(a).concat(p)
       if(this.showFinished) {
         const that = this
         return newArray.sort((a, b) => {
               const ca = that.getCompetitionId(a.id)
               const sa = that.getSeasonId(a.id)
               const cb = that.getCompetitionId(b.id)
               const sb = that.getSeasonId(b.id)
               const fa = that.competitionFinished(ca, sa, a.id)
               const fb = that.competitionFinished(cb, sb, b.id)
               if(fa === fb) {
                 return 0
               } else {
                 return fa ? 1 : -1
               }
             }
         )
      } else {
        const that = this
        var temp = newArray.filter(function (item) {
          const c = that.getCompetitionId(item.id)
          const s = that.getSeasonId(item.id)
          if(parseInt(c) === 0) {
            return !that.hideBetgame(item.id)
          }
          return !that.competitionFinished(c, s, item.id)
        });
        return temp.sort((a, b) => {
              const ca = that.getCompetitionId(a.id)
              const sa = that.getSeasonId(a.id)
              const cb = that.getCompetitionId(b.id)
              const sb = that.getSeasonId(b.id)
              const fa = that.competitionFinished(ca, sa, a.id)
              const fb = that.competitionFinished(cb, sb, b.id)
              if(fa === fb) {
                return 0
              } else {
                return fa ? 1 : -1
              }
            }
        )
      }
    },
    userId() {
      return this.$store.state.user.id
    }
  },
  methods: {
    betGameType(betgame) {
      const t = this.$store.state.userBetgames.filter(function (item) {
        return item.id === betgame.id
      });
      return t.length > 0 ? t[0].type : 0
    },
    isTournament(competition) {
      const tournments = [19,33,36,87,132,139,533,541,588,999,1110,1180,1235,1379,4228,6395,10700]
      return tournments.includes(parseInt(competition))
    },
    isAdmin(betgame) {
      const t = this.userBetgamesOwner.filter(function (item) {
        return item.id === betgame.id
      });
      return t.length > 0
    },
    isAuthor(betgame) {
      const t = this.userBetgamesAuthor.filter(function (item) {
        return item.id === betgame.id
      });
      return t.length > 0
    },
    showFinishedBetgames() {
      this.showFinished = true
    },
    hideFinishedBetgames() {
      this.showFinished = false
    },
    pendingAnswersCount(betgame) {
      if(this.$store.state.questions) {
        const questions = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(betgame))
        var result = 0
        for (var i = 0; i < questions.length; i++) {
          const question = questions[i]
          const editableUntil = this.parseMatchdate(question.editable_until)
          if(editableUntil > Date.now()) {
            const answers = this.$store.state.answers.filter(a => parseInt(a.fk_question) === parseInt(question.id))
            if(answers.length !== question.answer_count) {
              result += question.answer_count
            }
          }
        }
        return result
      }
      return 0
    },
    pendingTipsCount(betgame) {
      var newArray = this.$store.state.missing_bets.filter(d => d.id === betgame)
      if(newArray.length > 0) {
        return (newArray[0].matchcount - newArray[0].betcount) + this.pendingAnswersCount(betgame)
      }
      return this.pendingAnswersCount(betgame)
    },
    finishBetgameTransfer(betgame) {
      Vue.$log.debug('finishBetgameTransfer ' + betgame)
      const that = this
      this.$store.dispatch('finishBetgameTransfer', '' + betgame).then(() => {
        that.$toast.open({ message: that.$i18n.t('my_betgames.transfer_betgame_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        that.switchBetgame(betgame)
      }).catch(function (error) {
        alert(that.$i18n.t('my_betgames.transfer_betgame_error') + ' ' + error)
      })
    },
    switchBetgame(betgame) {
      if(this.currentBetgame.id !== betgame) {
        this.setBGValue('spmbgid', betgame)
        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
          this.setBGValue('betgameId', betgame)
        }
        this.$store.dispatch('switchBetgame', betgame).then(() => {
          this.$gtag.set({
            'dimension1': (this.$store.state.betgame && this.$store.state.betgame.id === betgame && this.$store.state.betgame.name) ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': betgame ? betgame : 'betgame id not set',
            'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
          })
          if(this.$store.state.betgame && this.$store.state.user) {
            this.$gtag.set({
              user_properties: {
                permission_manage_prediction_round: '' + this.isAdmin(this.$store.state.betgame.id),
                prediction_mode: this.getBetgameType(this.$store.state.betgame),
                prediction_competiton: this.getCompetitionId(this.$store.state.betgame.id),
                notification_preferences: this.getNotificationPreferences(),
                user_age: (this.$store.state.user.isadult) ? '>=18' : '<18'
              },
            })
          }
          if(this.$store.state.user) {
            this.$store.dispatch('setShowLoadingOverlay', true).then(() => {
              this.$router.push('/feed/dashboard')
            })
          } else {
            this.$router.push('/feed/dashboard')
          }
        })
      } else {
        this.setBGValue('spmbgid', betgame)
        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
          this.setBGValue('betgameId', betgame)
        }
        this.$store.dispatch('switchBetgame', betgame).then(() => {
          this.$gtag.set({
            'dimension1': (this.$store.state.betgame && this.$store.state.betgame.id === betgame && this.$store.state.betgame.name) ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': betgame ? betgame : 'betgame id not set',
            'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
          })
          if(this.$store.state.betgame && this.$store.state.user) {
            this.$gtag.set({
              user_properties: {
                permission_manage_prediction_round: '' + this.isAdmin(this.$store.state.betgame.id),
                prediction_mode: this.getBetgameType(this.$store.state.betgame),
                prediction_competiton: this.getCompetitionId(this.$store.state.betgame.id),
                notification_preferences: this.getNotificationPreferences(),
                user_age: (this.$store.state.user.isadult) ? '>=18' : '<18'
              },
            })
          }
          if(this.$store.state.user) {
            this.$store.dispatch('setShowLoadingOverlay', true).then(() => {
              this.$router.push('/feed/dashboard')
            })
          } else {
            this.$router.push('/feed/dashboard')
          }
        })
      }
    },
  }
}

</script>

<style lang="scss" scoped>

.mybetgameslist {
  margin-top: 16px;
}

.mybetgames {
  max-width: 576px;
  margin: 0 auto auto auto;

  @media screen and (min-width: 768px) {
    margin: 48px auto 0;
  }

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  .table-view-cell-label {
    word-break: break-word;
  }

  .table-view-cell-value {
    flex-shrink: 0;
  }

  .indicator {
    padding: 5px 8px;
    border-radius: 10px;
    font-size: 9px;
    font-weight: 500;
    color: rgba($colorGreyDark, .64);
    background: rgba($colorGreyDark, .08);

    &--warning {
      color: $colorWhite;
      background: $colorNegative;
    }
  }

  .active {
    background: rgba($colorGreyLight, .04);
    padding-left: 0;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 100%;
      top: 0;
      background: rgba($colorGreyLight, .04);
      border-radius: 0;
    }

    &:before {
      left: -16px;
    }

    &:after {
      right: -16px;
    }

    .table-view-cell-icon {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: -2px;
        bottom: -2px;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        background: $colorPositive;
        box-shadow: 0 0 0 2px #F9F9F9;
      }
    }

    .table-view-cell-value {
      display: flex;
    }

    .table-view-cell-label-sublabel {
      span {

        &:first-child {
          color: $colorPositive;
          font-weight: 500;
        }
      }
    }
  }
}

.more-betgames {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  color: rgba($colorGreyDark, .48);
  text-decoration: underline;
  margin: 18px 0 0;
  cursor: pointer;

  img {
    opacity: .48;
    margin-right: 4px;
  }
}

.button__wrapper {
  position: sticky;
  z-index: 3;
  bottom: 16px;
  padding: 32px 0 0;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 16px);
    background: linear-gradient(180deg, rgba($colorBackground,0.00) 0%, $colorBackground 50%);
  }
}

.table-view-cell-icon {
  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 3px;
  }
}

.router_link {
  text-decoration: none;
}

</style>
