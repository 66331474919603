<template>
  <div :key="componentKey">
    
    <div id="swiper_container">
      <BetMatchDetailsSwiper :matches="matches"></BetMatchDetailsSwiper>
    </div>

  </div>
</template>

<script>

  import BetMatchDetailsSwiper from '../components/BetMatchDetailsSwiper'
  import NavigationBar from './../components/NavigationBar'
  import moment from 'moment/moment'
export default {
  name: 'BetMatchSlider',
  props: {
    matchday: {
      type: Number,
      default: () => {
        return 1
      }
    },
    selectedTab: {
      type: String,
      default: () => {
        return 'nextMatches'
      }
    }
  },
  data () {
    return {
      componentKey: 0
    }
  },
  components: {
    BetMatchDetailsSwiper, NavigationBar
  },
  created() {
    this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
  },
  mounted() {
    this.componentKey += 1
  },
  computed: {
    switchViewAvailable() {
      return this.$store.state.betgame.competition.type === 'LEAGUE'
    },
    matches() {
      if(this.isBattle()) {
        const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        if(this.selectedTab === 'nextMatches') {
          const d = moment().add(7, 'days')
          const filtered = m.filter(t => this.parseMatchdate(t.matchdate).isSameOrBefore(d, 'days') && this.parseMatchdate(t.matchdate).isAfter(moment(), 'days'))
          if(filtered.length > 0) {
            const sorted = filtered.sort(function (a, b) {
              return Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted
          } else {
            const sorted = m.sort(function (a, b) {
              return Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(0, 10)
          }
        } else if(this.selectedTab === 'lastMatches') {
          const d = moment().subtract(7, 'days')
          const filtered = m.filter(t => this.parseMatchdate(t.matchdate).isSameOrAfter(d, 'days') && this.parseMatchdate(t.matchdate).isBefore(moment(), 'days'))
          if(filtered.length > 0) {
            const sorted = filtered.sort(function (a, b) {
              return Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted
          } else {
            const sorted = m.sort(function (a, b) {
              return Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(Math.max(sorted.length - 10, 0))
          }
        } else if(this.selectedTab === 'todaysMatches') {
          const filtered = m.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
          const sorted = filtered.sort(function (a, b) {
            return Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return sorted
        }else {
          const t = m.sort(function (a, b) {
            return Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return t
        }
      } else {
        const m = this.$store.getters.getMatchesByMatchday(this.matchday).sort(function (a, b) {
          if(a.status && (a.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' ||
              a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung' || a.status.trim() === 'nicht ausgetragen')) {
            return 1
          } else if(b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' ||
              b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung' || b.status.trim() === 'nicht ausgetragen')) {
            return -1
          }
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return m
      }
    },
    matchdayCount() {
      return this.$store.state.matchdayCount
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  #swiper_container {
    width: 100%;
    margin-bottom: 10px;
  }
</style>
