<template>
  <div>
    <vue-final-modal v-model="showModal" @click-outside="clickOutside" class="chat__context">

      <div class="chat__context__content">
        
        <div class="chat__context__list">

          <input id="imagefileInput" type="file" multiple :name="uploadFieldName"
                 class="input-file" style="display: none" accept="image/png, image/jpeg"
                 @change="filesChange($event.target.name, $event.target.files);">
          <input id="fileInput" type="file" multiple :name="uploadFieldName"
                 class="input-file" style="display: none" accept=".doc,.docx,.xlsx,.xls,.ppt,.pptx,.txt,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/vnd.ms-excel,application/msword,application/vnd.ms-powerpoint,text/plain"
                 @change="filesChange($event.target.name, $event.target.files);">
          
          <div v-on:click="addFileClicked('imagefileInput')" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              Foto
            </div>
            <img src="@/assets/chat/ic_20_camera.svg" alt="">
          </div>
          <div v-on:click="addFileClicked('fileInput')" class="chat__context__list__item">
            <div class="chat__context__list__item__label">
              Datei
            </div>
            <img src="@/assets/chat/ic_20_file.svg" alt="">
          </div>

        </div>

      </div>

    </vue-final-modal>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'ChatContextMenu',
  props: {
    chatItem: Object,
    showModal: false,
    uploadFieldName: 'chatfileupload'
  },
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {
    addFileClicked (id) {
      Vue.$log.debug('ChatContextMenu.vue - addFileClicked - id: ' + id)
      this.$emit('clickOutside', { selected: null } )
      var input = document.getElementById(id)
      input.click()
    },
    filesChange (fieldName, fileList) {
      Vue.$log.debug('ChatContextMenu.vue - filesChange')
      if (!fileList.length) {
        return
      }
      var filesOk = true
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i]
        const fileSizeinMB = file.size / 1024 / 1024
        if (fileSizeinMB > 100) {
          filesOk = false
          break
        }
      }
      if (filesOk) {
        Vue.$log.debug('ChatContextMenu.vue - filesChange - emit')
        this.$emit('textEntered', { text: '', files: fileList, '_attachment_follows': true })
      }
    },
    clickOutside () {
      Vue.$log.debug('ChatContextMenu.vue - clickOutside')
      this.$emit('clickOutside', { selected: null } )
    }
  },
  computed: {

  }
}
</script>

<style lang="scss">
.chat__context {
  display: flex;

  .vfm--inset {
    display: flex;
  }

  .vfm__content {
    display: flex;
    margin: auto auto 0 auto;
    width: 100%;
    max-width: 576px;

    @media screen and (min-width: 768px) {
      margin: auto;
    }
  }

  .vfm--overlay {
    backdrop-filter: blur(4px);
  }

  &__content {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    
    .vfm-enter-active & {
      animation: .2s enterAnimation !important;
      animation-fill-mode: forwards !important;
    }

    @keyframes enterAnimation {
      from {  
        transform: translateY(48px)
      }
      to {
        transform: translateY(0)
      }
    }

    .vfm-leave-active & {
      animation: .2s leaveAnimation ease-in-out !important;
      animation-fill-mode: forwards !important;
    }

    @keyframes leaveAnimation {
      from {  
        transform: translateY(0)
      }
      to {
        transform: translateY(48px)
      }
    }
  }

  &__message {
    background: $colorWhite;
    padding: 21px 24px 18px;
    border-radius: 4px 24px 24px 24px;
    width: 100%;
    box-sizing: border-box;

    &__title {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyDark;
      margin-bottom: 8px;

      .chat__item--sender & {
        color: $colorWhite;
      }

      span {
        margin-top: 3px;
        font-size: 11px;
        font-weight: 400;
        opacity: .48;
        margin-left: auto;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      word-break: break-word;

      @media screen and (max-width: 479px) {
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    border-radius: 8px;
    margin-top: 12px;
    overflow-y: scroll;

    &__emojis {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(1, 1fr);
      grid-row-gap: 8px;
      padding: 5px;
      margin: 4px 0;
      max-height: 160px;
      overflow-y: scroll;
      overflow-x: hidden;

      &--expanded {
        position: relative;
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

        &:after {
          position: sticky;
          content: '';
          bottom: -5px;
          margin: -5px;
          grid-column: span 9;
          height: 32px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.64) 100%);
        }
      }

      &__item {
        font-size: 22px;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all .2s ease-in-out;

        &--invisible {
          display: none;
        }

        &--active, &:hover {
          background: rgba(#000000, .08);
        }

        &--add {
          width: 24px;
          height: 24px;
          margin: 8px;
          background: rgba(#000000, .04);

          img {
            opacity: .64;
          }

          &:hover {
            img {
              opacity: .8;
            }
          }
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      padding: 12px 16px;
      transition: all .2s ease-in-out;

      &:hover {
        background: rgba(#000000, .04);
      }

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);
      }

      &--delete {
        color: $colorNegative;
      }

      &__label {
        flex: 1;
      }

      &__avatar {
        position: relative;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: -2px 8px -2px 0;
        overflow: hidden;
        background: rgba($colorGreyDark, .08);

        &:before {
          position: absolute;
          z-index: 1;
          top: 8px;
          left: 8px;
          width: 16px;
          height: 16px;
          content: url('../../assets/chat/ic_16_user.svg');
          opacity: .48;
        }

        img {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      span {
        font-size: 22px;
      }

      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
