<template>
    <div>

        <swiper :options="swiperOption" ref="mySwiper" @slideChange="slideChanged">
            
            <swiper-slide v-for="(match, index) in matches"
                          :key="match.id"
                          :ref="match.id"
            >
                <BetMatchDetailSlide :match="match"></BetMatchDetailSlide>
            </swiper-slide>
    
            <div class="swiper-button-prev" slot="button-prev"><img src="../assets/icons/ic_16_back.svg"></div>
            <div class="swiper-button-next" slot="button-next"><img src="../assets/icons/ic_16_next.svg"></div>
            
        </swiper>

      <!--
        <div v-if="betEditable && betmode === 2" class="bet-input-toto">
          <div @click="totoButtonClicked(1)" :class="{ 'bet-input-toto_button_selected': this.homeBetValue === 1}" class="bet-input-toto_button">
            1
          </div>
          <div @click="totoButtonClicked(0)" :class="{ 'bet-input-toto_button_selected': this.homeBetValue === 0}" class="bet-input-toto_button">
            0
          </div>
          <div @click="totoButtonClicked(2)" :class="{ 'bet-input-toto_button_selected': this.homeBetValue === 2}" class="bet-input-toto_button">
            2
          </div>
        </div>
      -->
        <div v-if="betEditable && betmode === 1" class="bet-input-group">
          <div class="bet-input">
            <input type="range" min="0" max="9" step="1" @change="homeGoalsInputChange"
                   v-model.number="homeBetValue" :disabled="!betEditable">
            <div class="bet-input-bg" v-bind:style="{width: sliderGoalsHomeWidth, 'background-color': getTeamColor(match.fk_team_home)}">
              <span :style="{'background-color': getTeamColor(match.fk_team_home)}" v-bind:class="{ empty : sliderWidthHome === '' }">{{ bet.goalshome }}</span>
              <div class="left-item" :style="{'background-color': getTeamColor(match.fk_team_home)}"></div>
            </div>
            <div class="bet-input-ticks">
              <div class="bet-input-ticks-label">0</div>
              <div class="bet-input-ticks-label">1</div>
              <div class="bet-input-ticks-label">2</div>
              <div class="bet-input-ticks-label">3</div>
              <div class="bet-input-ticks-label">4</div>
              <div class="bet-input-ticks-label">5</div>
              <div class="bet-input-ticks-label">6</div>
              <div class="bet-input-ticks-label">7</div>
              <div class="bet-input-ticks-label">8</div>
              <div class="bet-input-ticks-label">9</div>
            </div>
          </div>

          <div class="bet-input">
            <input id="asd" type="range" min="0" max="9" step="1" @change="guestGoalsInputChange"
                   v-model.number="guestBetValue" :disabled="!betEditable">
            <div class="bet-input-bg" v-bind:style="{width: sliderGoalsGuestWidth, 'background-color': getTeamColor(match.fk_team_guest)}">
              <span :style="{'background-color': getTeamColor(match.fk_team_guest)}" v-bind:class="{ empty : sliderWidthGuest === '' }">{{ bet.goalsguest }}</span>
              <div class="left-item" :style="{'background-color': getTeamColor(match.fk_team_guest)}"></div>
            </div>
            <div class="bet-input-ticks">
              <div class="bet-input-ticks-label">0</div>
              <div class="bet-input-ticks-label">1</div>
              <div class="bet-input-ticks-label">2</div>
              <div class="bet-input-ticks-label">3</div>
              <div class="bet-input-ticks-label">4</div>
              <div class="bet-input-ticks-label">5</div>
              <div class="bet-input-ticks-label">6</div>
              <div class="bet-input-ticks-label">7</div>
              <div class="bet-input-ticks-label">8</div>
              <div class="bet-input-ticks-label">9</div>
            </div>
          </div>
        </div>
      
        <MatchStatistics v-if="showOdds || showMatchStatistics" :match="matches[activeIndex]"></MatchStatistics>
        <div v-if="!showOdds && !showMatchStatistics" class="statistics-empty">
          {{ $t('bet.match_stats_not_available') }}
        </div>

    </div>
</template>

<script>
    
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import BetMatchDetailSlide from '../components/BetMatchDetailSlide'
    import MatchStatistics from '../components/MatchStatistics'
    import moment from 'moment'
    export default {
        name: 'BetMatchDetailsSwiper',
        props: {
            matches: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        components: {
            swiper,
            swiperSlide,
            BetMatchDetailSlide,
            MatchStatistics
        },
        data () {
            return {
                activeIndex: 0,
                homeBetValue: null,
                guestBetValue: null,
                swiperOption: {
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true
                    }
                }
            }
        },
        mounted() {
          if(this.bet) {
            this.homeBetValue = this.bet.goalshome
            this.guestBetValue = this.bet.goalsguest
          }
        },
        computed: {
            betmode() {
              return this.$store.state.betgame.bet_mode || 1
            },
            showMatchStatistics () {
              const match = this.matches[this.activeIndex]
              return match !== null && match.matchday > 1
            },
            match () {
                return this.matches[this.activeIndex]
            },
            matchStats () {
                const match = this.matches[this.activeIndex]
                return this.$store.getters.getMatchStats(match.id)
            },
            currentMatch () {
                return this.matches[this.activeIndex]
            },
            swiper () {
                return this.$refs.mySwiper.swiper
            },
            betEditable () {
                const activeMatch = this.matches[this.activeIndex]
                if(activeMatch === null || activeMatch === undefined) {
                    return false
                }
                //const md = Date.parse(activeMatch.matchdate)
                if(this.isPast(activeMatch.matchdate) && (activeMatch.status === null || activeMatch.status.trim() === '')) {
                  return false;
                }
                if(activeMatch.status && (activeMatch.status.trim() === 'annulliert' || activeMatch.status.trim() === 'abgesagt'
                    || activeMatch.status.trim() === 'verlegt' || activeMatch.status.trim() === 'Wertung'
                    || activeMatch.status.trim() === 'nicht ausgetragen')) {
                  return false
                }
                if(this.isPlaceholderTeam(activeMatch.fk_team_home) || this.isPlaceholderTeam(activeMatch.fk_team_guest)) {
                  return false
                }
                return !this.isNumeric(activeMatch.goalshome) && !this.isNumeric(activeMatch.goalsguest) &&
                  !this.isNumeric(activeMatch.livegoalshome) && !this.isNumeric(activeMatch.livegoalsguest)
            },
            bet () {
                const match = this.matches[this.activeIndex]
                const b = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, match.id)
                if (b != null) {
                  return b
                } else {
                  const nb = {
                    'fk_user': parseInt(this.$store.state.user.id),
                    'fk_match': parseInt(match.id),
                    'fk_betgame': parseInt(this.$store.state.betgameId)
                  }
                  this.$store.dispatch('addBet', nb)
                  return nb
                }
            },
            sliderGoalsHomeWidth(){
              return 'calc(9.21% * ' + this.sliderWidthHome + ')';
            },
            sliderGoalsHomeColor(){
              return `rgba(${this.sliderWidthHome},${this.sliderWidthHome * 2},${this.sliderWidthHome * 1.2})`
            },
            sliderGoalsGuestWidth(){
              return 'calc(9.21% * ' + this.sliderWidthGuest + ')';
            },
            sliderGoalsGuestColor(){
              return `rgba(${this.sliderWidthGuest},${this.sliderWidthGuest * 2},${this.sliderWidthGuest * 1.2})`
            },
            sliderWidthHome() {
              return this.homeBetValue || (this.bet ? this.bet.goalshome || 0 : 0)
            },
            sliderWidthGuest() {
              return this.guestBetValue || (this.bet ? this.bet.goalsguest || 0 : 0)
            },
            showOdds() {
              return false 
              /*
              if(this.$store.state.betgame && this.$store.state.user) {
                return !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
              }
              return false
              */
            },
        },
        methods: {
            totoButtonClicked(val) {
              this.homeBetValue = val
              this.saveBet()
            },
            homeGoalsInputChange() {
              this.saveBet()
            },
            guestGoalsInputChange() {
              this.saveBet()
            },
            slideChanged (e) {
                this.activeIndex = this.$refs.mySwiper.swiper.activeIndex
              if(this.bet) {
                this.homeBetValue = this.bet.goalshome
                this.guestBetValue = this.bet.goalsguest
              }
            },
            saveBet () {
              if (this.betmode === 1 && this.isNumeric(this.homeBetValue) && this.isNumeric(this.guestBetValue)) {
                if (this.bet.goalshome !== this.homeBetValue || this.bet.goalsguest !== this.guestBetValue) {
                  const match = this.matches[this.activeIndex]
                  const bet = {
                    'fk_user': this.$store.state.user.id,
                    'fk_match': match.id,
                    'goalshome': parseInt(this.homeBetValue),
                    'goalsguest': parseInt(this.guestBetValue),
                    'fk_betgame': parseInt(this.$store.state.betgameId)
                  }
                  this.$store.dispatch('updateBet', bet).then(response => {
                    this.homeBetValue = this.bet.goalshome
                    this.guestBetValue = this.bet.goalsguest
                    this.$store.dispatch('setLastBetSaveDate', moment().format('YYYY-MM-DD HH:mm:ss'))
                  }, error => {
                    this.homeBetValue = this.bet.goalshome
                    this.guestBetValue = this.bet.goalsguest
                    alert(this.$t('bet.save_bet_error_try_again'))
                  })
                }
              } else if(this.betmode === 2 && this.isNumeric(this.homeBetValue) && this.bet.goalshome !== this.homeBetValue) {
                const match = this.matches[this.activeIndex]
                const bet = {
                  'fk_user': this.$store.state.user.id,
                  'fk_match': match.id,
                  'goalshome': parseInt(this.homeBetValue),
                  'goalsguest': parseInt(this.homeBetValue),
                  'fk_betgame': parseInt(this.$store.state.betgameId)
                }
                this.$store.dispatch('updateBet', bet).then(response => {
                  this.homeBetValue = this.bet.goalshome
                  this.guestBetValue = this.bet.goalsguest
                  this.$store.dispatch('setLastBetSaveDate', moment().format('YYYY-MM-DD HH:mm:ss'))
                }, error => {
                  this.homeBetValue = this.bet.goalshome
                  this.guestBetValue = this.bet.goalsguest
                  alert(this.$t('bet.save_bet_error_try_again'))
                })
              }
            }
        }
    }
</script>

<style lang="scss" scoped>
  .swiper-container {
      padding: 32px 0 18px;

      @media screen and (min-width: 768px) {
        padding: 58px 0 18px;
      }
  }

  .swiper-wrapper {
      overflow: auto;
  }
  
  @keyframes fadein {
    0%   { padding: 40px 16px 28px; margin-bottom: 16px;}
    100% { padding: 48px 16px 36px; transform: translateY(-8px); margin-bottom: 0; }
  }

  @keyframes fadeout {
    0%   { padding: 48px 16px 36px; transform: translateY(-8px); margin-bottom: 0; }
    100% { padding: 40px 16px 28px; transform: translateY(0); margin-bottom: 16px; }
  }

  @media screen and (min-width: 768px) {
    @keyframes fadein {
      0%   { padding: 40px 16px 28px; margin-bottom: 16px; opacity: .32; }
      100% { padding: 48px 16px 36px; transform: translateY(-8px); margin-bottom: 0; opacity: 1; }
    }

    @keyframes fadeout {
      0%   { padding: 48px 16px 36px; transform: translateY(-8px); margin-bottom: 0; opacity: 1; }
      100% { padding: 40px 16px 28px; transform: translateY(0); margin-bottom: 16px; opacity: .32; }
    }
  }

  .swiper-slide {
      background-position: center;
      background-size: cover;
      width: calc(100% - 48px);
      max-width: 576px;
      margin: 0 6px;
      padding: 40px 16px 28px;
      background: $colorWhite;
      box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
      border-radius: 6px;
      animation: fadeout .2s forwards ease-in-out;
      margin-bottom: 16px;
      align-self: center;
      overflow: hidden;
      opacity: .64;

      @media screen and (min-width: 768px) {
        opacity: .32;
      }

      &:first-child {
        margin-left: 12px;
      }

      &-active {
        animation: fadein .2s forwards ease-in-out;
        opacity: 1;
      }
  }

  .swiper-button {
    &-prev, &-next {
      height: 36px;
      width: 36px;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $colorWhite;
      border-radius: 20px;
      transform: translate(-50%, -50%);
      transition: .2s ease-in-out;
      box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08), 0 0 0 1px rgba(49,49,55, .08);

      @media screen and (min-width: 768px) {
        width: 40px;
        height: 40px;
        box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
      }

      &:hover {
        box-shadow: 0 8px 24px 0 rgba(49,49,55,0.16), 0 0 0 1px rgba(49,49,55, .08);

        @media screen and (min-width: 768px) {
          box-shadow: 0 8px 24px 0 rgba(49,49,55,0.16);
        }
      }
    }
    &-prev {
      top: 50%;
      left: calc(12px + 12px);

      @media screen and (min-width: 768px) {
        top: calc(50% + 12px);
        left: calc(50% - 352px);
      }
    }
    &-next {
      top: 50%;
      right: -12px;

      @media screen and (min-width: 768px) {
        top: calc(50% + 12px);
        left: calc(50% + 352px);
      }
    }
    &-disabled {
      opacity: 0;
    }
  }

  .bet-input-group {
    padding: 8px 0 2px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .bet-input {
      margin: 6px 24px 24px;
      position: relative;
      background: $colorWhite;
      border-radius: 20px;
      height: 40px;
      max-width: 370px;
      width: calc(100% - 48px);

      &-ticks {
          pointer-events:none;
          position: absolute;
          top: 0;
          left: 13px;
          width: calc(100% - 26px);
          height: 40px;
          align-items: center;
          display: flex;

          &-label {
              width: 10%;
              text-align: center;
              color: rgba(#000, .32);
          }
      }

      &-bg {
        position: absolute;
        top: 0;
        height: 40px;
        margin: 0 28px;
        background: #f0f;
        pointer-events: none;

        .left-item {
          position: absolute;
          left: -28px;
          top: 0;
          height: 40px;
          width: 32px;
          border-radius: 20px 0 0 20px;
          pointer-events: none;
        }

        span {
          position: absolute;
          right: -30px;
          top: -9px;
          color: $colorWhite;
          font-weight: 500;
          z-index: 3;
          pointer-events: none;
          background: #000;
          width: 58px;
          height: 58px;
          border-radius: 29px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 4px 12px 0 rgba(0,0,0,0.32), 0 8px 24px 0 rgba(49,49,55,0.04), inset 0 0 0 3px $colorWhite;

          @media screen and (max-width: 320px) {
            right: -24px;
          }

          &.empty {
            &:before {
              content: '';
              position: absolute;
              width: 17px;
              height: 16px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background: url('../assets/icons/ic_16_slider_thumb.svg');
            }
          }
        }
      }

      input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        height: 40px;
        background: none;
        margin: 0;
        position: relative;
      }
      input[type=range]:focus {
        outline: 0;
      }
      input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 40px;
        cursor: pointer;
        animate: 0.2s;
        background: none;
        border-radius: 20px;
        border: 0;
      }
      input[type=range]::-webkit-slider-thumb {
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.32), 0 8px 24px 0 rgba(49,49,55,0.04), inset 0 0 0 3px $colorWhite;
        height: 56px;
        width: 56px;
        border-radius: 28px;
        background: none;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8px;
        opacity: 0;
      }
      input[type=range]:focus::-webkit-slider-runnable-track {
        background: none;
      }
      input[type=range]::-moz-range-track {
        width: 100%;
        height: 40px;
        cursor: pointer;
        animate: 0.2s;
        background: none;
        border-radius: 20px;
        border: 0;
        box-shadow: none;
      }
      input[type=range]::-moz-focus-outer {
        border: 0;
      }
      input[type=range]::-moz-range-thumb {
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.32), 0 8px 24px 0 rgba(49,49,55,0.04), inset 0 0 0 3px $colorWhite;
        height: 56px;
        width: 56px;
        border-radius: 28px;
        background: none;
        cursor: pointer;
        margin-top: -8px;
        opacity: 0;
      }
      input[type=range]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }
      input[type=range]::-ms-fill-lower {
        background: #2a6495;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }
      input[type=range]::-ms-fill-upper {
        background: #3071a9;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }
      input[type=range]::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
        opacity: 0;
      }
      input[type=range]:focus::-ms-fill-lower {
        background: #3071a9;
      }
      input[type=range]:focus::-ms-fill-upper {
        background: #367ebd;
      }
  }

  .bet-input-toto {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 0 16px;

    &_button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      background: $colorWhite;
      color: $colorGreyDark;
      font-size: 20px;
      font-weight: 500;
      font-family: 'Rubik';
      padding: 0;
      border-radius: 6px;
      margin: 0 8px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &_selected {
        background: var(--color-primary);
        color: $colorWhite;
      }

      &_win {
        background: $colorPositive;
        color: $colorWhite;
      }

      &_lose {
        background: $colorNegative;
        color: $colorWhite;
      }
    }
  }
</style>
