<template>
    <div class="match-statistics">
        <div v-if="showMatchStatistics" class="match-statistics-form">
            <div class="match-statistics-form-left">
                <ResultTrendBox :matches="matchStats['home']['last_matches'].slice().reverse()" :team-id="match.fk_team_home"></ResultTrendBox>
            </div>
            <div class="match-statistics-form-right">
                <ResultTrendBox :matches="matchStats['guest']['last_matches']" :team-id="match.fk_team_guest"></ResultTrendBox>
            </div>
        </div>
    
        <div v-if="betEditable && showOdds" class="bet-presented">
          {{ $t('presented_by') }} <img src="../assets/icons/ic_logo_bwin_small.svg">
        </div>
        <BetBox v-if="betEditable && showOdds" :match="match"></BetBox>
        
        <div v-if="showMatchStatistics" class="match-statistics-schedule">
          <table v-if="showTableStats && tablePositions.length === 2" class="table">
            <thead>
                <th>#</th>
                <th style="text-align: left;">{{ $t('team') }}</th>
                <th>{{ $t('goals') }}</th>
                <th>+/-</th>
                <th>{{ $t('rankings_table_header_points') }}</th>
              </thead>
            <tbody></tbody>
            <tr>
                <td>{{ tablePositions[0].position }}</td>
                <td class="col_team_name">
                    <img v-if="firstTeamCode && firstTeamCode.length > 6" :src="firstTeamLogo" width="24">
                    <flag v-else-if="firstTeamCode && firstTeamCode.length <= 6" :iso="firstTeamCode" />
                    <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(tablePositions[0].fk_team )}"></path>
                    </svg>
                    {{ tablePositions[0].team }}
                </td>
                <td v-if="tablePositions[0].goals_scored !== null && tablePositions[0].goals_against !== null && tablePositions[0].goals_scored > -1 && tablePositions[0].goals_against > -1" class="col_value">{{ tablePositions[0].goals_scored }}:{{ tablePositions[0].goals_against }}</td>
                <td v-else class="col_value">0:0</td>
                <td v-if="tablePositions[0].goals_diff > 0" class="col_value">+{{ tablePositions[0].goals_diff }}</td>
                <td v-else-if="tablePositions[0].goals_diff < 0" class="col_value">{{ tablePositions[0].goals_diff }}</td>
                <td v-else class="col_value">0</td>
                <td class="col_value col_points">{{ tablePositions[0].points }}</td>
            </tr>
            <tr>
                <td>{{ tablePositions[1].position }}</td>
                <td class="col_team_name">
                    <img v-if="secondTeamCode && secondTeamCode.length > 6" :src="secondTeamLogo" width="24">
                    <flag v-else-if="secondTeamCode && secondTeamCode.length <= 6" :iso="secondTeamCode" />
                    <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(tablePositions[1].fk_team )}"></path>
                    </svg>
                    {{ tablePositions[1].team }}
                </td>
                <td v-if="tablePositions[1].goals_scored !== null && tablePositions[1].goals_against !== null && tablePositions[1].goals_scored > -1 && tablePositions[1].goals_against > -1" class="col_value">{{ tablePositions[1].goals_scored }}:{{ tablePositions[1].goals_against }}</td>
                <td v-else class="col_value">0:0</td>
                <td v-if="tablePositions[1].goals_diff > 0" class="col_value">+{{ tablePositions[1].goals_diff }}</td>
                <td v-else-if="tablePositions[1].goals_diff < 0" class="col_value">{{ tablePositions[1].goals_diff }}</td>
                <td v-else class="col_value">0</td>
                <td class="col_value col_points">{{ tablePositions[1].points }}</td>
            </tr>
          </table>
          <!--
          <table class="table">
            <thead>
                <th>Heimtabelle</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </thead>
            <tbody></tbody>
            <tr>
                <td>4.</td>
                <td class="col_team_name"><img src="../assets/jerseys/24/ic_24_jersey_wolfsburg.svg"></td>
                <td class="col_value">20:8</td>
                <td class="col_value">+12</td>
                <td class="col_value col_points">24</td>
            </tr>
          </table>
          <table class="table">
            <thead>
                <th>Auswärtstabelle</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </thead>
            <tbody></tbody>
            <tr>
                <td>17.</td>
                <td class="col_team_name"><img src="../assets/jerseys/24/ic_24_jersey_wolfsburg.svg">Name (Gast)</td>
                <td class="col_value">12:22</td>
                <td class="col_value">-10</td>
                <td class="col_value col_points">2</td>
            </tr>
          </table>
          -->

          <!-- Heimbilanz -->
          <table class="table">
            <thead>
                <th>{{ $t('team_home_balance') }}</th>
                <th></th>
                <th></th>
              </thead>
            <tbody></tbody>
            <tr>
                <td></td>
                <td class="col_team_name">
                  <img v-if="homeTeamLogo && homeTeamCode.length > 6" :src="homeTeamLogo" width="24">
                  <flag v-else-if="homeTeamCode && homeTeamCode.length <= 6" :iso="homeTeamCode" />
                  <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_home)}"></path>
                  </svg>
                  <span>{{ getTeamName(match.fk_team_home, match.team_home) }}</span>
                </td>
                <td>
                  <div class="bilanz">
                    <span>{{ matchStats['home']['home_w'] }}</span>
                    <span>{{ matchStats['home']['home_d'] }}</span>
                    <span>{{ matchStats['home']['home_l'] }}</span>
                  </div>
                </td>
            </tr>
          </table>

          <!-- Auswärtsbilanz -->
          <table class="table">
            <thead>
                <th>{{ $t('team_away_balance') }}</th>
                <th></th>
                <th></th>
              </thead>
            <tbody></tbody>
            <tr>
                <td></td>
                <td class="col_team_name">
                  <img v-if="guestTeamLogo && guestTeamCode.length > 6" :src="guestTeamLogo" width="24">
                  <flag v-else-if="guestTeamCode && guestTeamCode.length <= 6" :iso="guestTeamCode" />
                  <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_guest)}"></path>
                  </svg>
                  <span>{{ getTeamName(match.fk_team_guest, match.team_guest) }}</span>
                </td>
                <td>
                  <div class="bilanz">
                    <span>{{ matchStats['guest']['guest_w'] }}</span>
                    <span>{{ matchStats['guest']['guest_d'] }}</span>
                    <span>{{ matchStats['guest']['guest_l'] }}</span>
                  </div>
                </td>
            </tr>
          </table>
          <!--<div>{{ match.team_home }}<br>Heimbilanz: {{ matchStats['home']['home_w'] }} - {{ matchStats['home']['home_d'] }} - {{ matchStats['home']['home_l'] }}<br>
              Auswärtsbilanz: {{ matchStats['home']['guest_w'] }} - {{ matchStats['home']['guest_d'] }} - {{ matchStats['home']['guest_l'] }} </div><br>

          <div>{{ match.team_guest }}<br>Heimbilanz: {{ matchStats['guest']['home_w'] }} - {{ matchStats['guest']['home_d'] }} - {{ matchStats['guest']['home_l'] }}<br>
              Auswärtsbilanz: {{ matchStats['guest']['guest_w'] }} - {{ matchStats['guest']['guest_d'] }} - {{ matchStats['guest']['guest_l'] }} </div><br>-->
        </div>
    </div>
</template>

<script>
  import ResultTrendBox from './ResultTrendBox'
  import BetBox from './BetsBox'
  export default {
    name: 'MatchStatistics',
    components: { ResultTrendBox, BetBox },
    props: {
      match: {
        type: Object,
        default: () => {
          return null
        }
      }
    },
    created() {
      if (this.$store.state.positions.length === 0 && this.$store.state.leagueId > 0 && this.showTableStats) {
        this.$store.dispatch('fetchTable', this.$store.state.leagueId)
      }
    },
    computed: {
      showMatchStatistics () {
        return this.match !== null && this.match.matchday > 1
      },
      showTableStats() {
        if(this.$store.state.betgame.competition !== undefined && this.$store.state.betgame.competition !== null) {
          return this.$store.state.betgame.competition.type === 'LEAGUE'
        }
        return false
      },
      firstTeamCode() {
        if(this.tablePositions === undefined || this.tablePositions === null || this.tablePositions.length < 2) {
          return null
        }
        const l = this.getTeamLogo(this.tablePositions[0].fk_team)
        if(l) {
          return l
        }
        return null
      },
      secondTeamCode() {
        if(this.tablePositions === undefined || this.tablePositions === null || this.tablePositions.length < 2) {
          return null
        }
        const l = this.getTeamLogo(this.tablePositions[1].fk_team)
        if(l) {
          return l
        }
        return null
      },
      firstTeamLogo() {
        if(this.tablePositions === undefined || this.tablePositions === null || this.tablePositions.length < 2) {
          return null
        }
        const l = this.getTeamLogo(this.tablePositions[0].fk_team)
        if(l) {
          return this.getBGValue('spmau') + '/images/jerseys/24/' + l
        }
        return null
      },
      secondTeamLogo() {
        if(this.tablePositions === undefined || this.tablePositions === null || this.tablePositions.length < 2) {
          return null
        }
        const l = this.getTeamLogo(this.tablePositions[1].fk_team)
        if(l) {
          return this.getBGValue('spmau') + '/images/jerseys/24/' + l
        }
        return null
      },
      homeTeamCode() {
        const l = this.getTeamLogo(this.match.fk_team_home)
        if(l) {
          return l
        }
        return null
      },
      guestTeamCode() {
        const l = this.getTeamLogo(this.match.fk_team_guest)
        if(l) {
          return l
        }
        return null
      },
      homeTeamLogo() {
        const l = this.getTeamLogo(this.match.fk_team_home)
        if(l) {
          return this.getBGValue('spmau') + '/images/jerseys/24/' + l
        }
        return null
      },
      guestTeamLogo() {
        const l = this.getTeamLogo(this.match.fk_team_guest)
        if(l) {
          return this.getBGValue('spmau') + '/images/jerseys/24/' + l
        }
        return null
      },
      showOdds() {
        return false
        /*
        if(this.$store.state.betgame && this.$store.state.user) {
          if(!this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true) {
            if (this.match) {
              const r = this.$store.getters.getMatchBet(this.match.id)
              return r !== null && r !== undefined && r.length > 0
            }
          }
        }
        return false
        */
      },
      betEditable () {
        //const md = Date.parse(this.match.matchdate)
        if(this.isPast(this.match.matchdate) && (this.match.status === null || this.match.status.trim() === '')) {
          return false;
        }
        if(this.match.status && (this.match.status.trim() === 'annulliert' || this.match.status.trim() === 'abgesagt'
            || this.match.status.trim() === 'verlegt' || this.match.status.trim() === 'Wertung'
            || this.match.status.trim() === 'nicht ausgetragen')) {
          return false
        }
        if(this.isPlaceholderTeam(this.match.fk_team_home) || this.isPlaceholderTeam(this.match.fk_team_guest)) {
          return false
        }
        return !this.isNumeric(this.match.goalshome) && !this.isNumeric(this.match.goalsguest) &&
          !this.isNumeric(this.match.livegoalshome) && !this.isNumeric(this.match.livegoalsguest)
      },
      matchStats () {
        return this.$store.getters.getMatchStats(this.match.id)
      },
      tablePositions() {
        return this.$store.getters.getTablePositions(this.match)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .match-statistics {
    padding-bottom: 66px;
    
    &-form {
      display: flex;
      padding: 14px 0 32px;

      &-left, &-right {
        display: flex;
        flex: 1;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: $colorGreyMid;
          height: 2px;
          width: calc(100% - 14px);
          top: 11px;
          z-index: -1;

          @media screen and (max-width: 320px) {
            width: calc(100% - 8px);
          }
        }

        &:after {
          content: '';
          position: absolute;
          background: url('../assets/icons/ic_form_arrow.svg');
          width: 8px;
          height: 10px;
          top: 7px;
        }
      }

      &-left {
        padding-right: 24px;
        justify-content: flex-end;

        @media screen and (max-width: 320px) {
          padding-right: 12px;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 7px;

          @media screen and (max-width: 320px) {
            right: 1px;
          }
        }
      }

      &-right {
        padding-left: 24px;
        justify-content: flex-start;

        @media screen and (max-width: 320px) {
          padding-left: 12px;
        }

        &:before {
          right: 0;
        }

        &:after {
          left: 7px;
          transform: rotate(180deg);

          @media screen and (max-width: 320px) {
            left: 1px;
          }
        }
      }
    }
    
    &-schedule {
      padding: 0 16px;
      max-width: 576px;
      margin: 0px auto;
    }
  }
  .table {
    padding-bottom: 0;
    
    tr {
      background: none;

      &:last-child {
        td {
          border-bottom:0;
        }
      }
    }

    th {
      border-bottom: 1px solid rgba($colorGreyLight, .08);
    }

    th {
      &:first-child {
        width: 20px;
        max-width: 20px;
      }
    }
    td {
      &:first-child {
        width: 28px;
        max-width: 28px;
        padding-left: 8px;
        text-align: center;
      }
    }

    .col_team_name {
      padding: 10px 0 9px;
      align-items: center;
      display: flex;
      white-space: normal;

      img, svg {
        margin-right: 4px;
      }

      .flag-icon, span {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .08);
        margin-right: 8px;
      }
    }

    .bilanz {
      display: flex;
      justify-content: flex-end;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: $colorWhite;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        margin: -4px 4px;

        &:first-child {
          background: $colorPositive;
        }
        &:nth-child(2) {
            background: $colorGreyMid;
        }
        &:last-child {
            background: $colorNegative;
            margin-right: 0;
        }
      }
    }
  }

  .result_box {
      display: flex;
      width: 50%;
  }
  .result_span {
      text-align: center;
      color: white;
      vertical-align: middle;
  }

  .bet-presented {
      color: $colorGreyMid;
      font-size: 14px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      max-width: 576px;
      margin: 10px auto 4px;
      padding: 0 16px;
    
      img {
          margin-top: -4px;
          margin-left: 3px;
      }
  }
</style>
