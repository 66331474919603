<template>
  <div class="chat__fullscreenimage">
    <div class="chat__fullscreenimage__header">
      <div class="chat__fullscreenimage__header__left">
      </div>
      <div class="chat__fullscreenimage__header__center">
        <div class="chat__fullscreenimage__header__label">
          {{ userName }}
        </div>
        <div class="chat__fullscreenimage__header__sublabel">
          {{ getDateFormattedChat(chatItem.item.time) }}
        </div>
      </div>
      <div class="chat__fullscreenimage__header__right">
        <div class="chat__fullscreenimage__header__close" @click="hideFullscreen">
          <img src="../../assets/icons/ic_16_close_white.svg">
        </div>
      </div>
    </div>
    <div class="chat__fullscreenimage__content">
    <img :src="fullscreenImageURL" alt="" />
  </div>
    <div class="chat__fullscreenimage__footer">
      <div v-on:click="replyClicked()" class="chat__fullscreenimage__footer__item">
        <img src="../../assets/chat/ic_20_answer_white.svg">
        <div class="chat__fullscreenimage__footer__item__label">
          {{ $t('feed.reply') }}
        </div>
      </div>
      <div v-on:click="downloadClicked()" class="chat__fullscreenimage__footer__item">
        <img src="../../assets/chat/ic_20_download_white.svg">
        <div class="chat__fullscreenimage__footer__item__label">
          Download
        </div>
      </div>
      <div v-on:click="reportClicked()" class="chat__fullscreenimage__footer__item">
        <img src="../../assets/chat/ic_20_report_white.svg">
        <div class="chat__fullscreenimage__footer__item__label">
          {{ $t('feed.report') }}
        </div>
      </div>
      <div v-if="deleteAvailable" v-on:click="deleteClicked()" class="chat__fullscreenimage__footer__item">
        <img src="../../assets/chat/ic_20_delete_white.svg">
        <div class="chat__fullscreenimage__footer__item__label">
          {{ $t('feed.delete') }}
        </div>
      </div>
      <div v-if="!deleteAvailable" v-on:click="ignoreClicked()" class="chat__fullscreenimage__footer__item">
        <img src="../../assets/chat/ic_20_delete_white.svg">
        <div class="chat__fullscreenimage__footer__item__label">
          {{ $t('feed.delete') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment/moment'
import Vue from 'vue'

export default {
  name: 'ChatFullscreenItem',
  components: { },
  props: {
    fullscreenImageURL: String,
    chatItem: Object
  },
  data () {
    return {}
  },
  beforeCreate () {
  },
  methods: {
    downloadClicked() {
      this.$emit('downloadFileClicked', { selected: this.chatItem.embed, download: 'true' } )
    },
    reportClicked() {
      this.$emit('reportClicked', { selected: null } )
    },
    replyClicked() {
      this.$emit('replyClicked', { selected: null } )
    },
    deleteClicked() {
      Vue.$log.debug('ChatFullscreenItem deleteClicked')
      this.$emit('deleteClicked', { selected: null } )
    },
    ignoreClicked() {
      Vue.$log.debug('ChatFullscreenItem ignoreClicked')
      this.$emit('ignoreClicked', { selected: null } )
    },
    hideFullscreen() {
      this.$emit('hideClicked', {})
    },
    getDateFormattedChat(date) {
      if(this.isToday(date)) {
        return this.$t('today_at') + ' ' + moment(date).format('LT')
      } else if(this.isYesterday(date)) {
        return this.$t('yesterday_at') + ' ' + moment(date).format('LT')
      }
      return moment(date).format('DD.MM.YYYY - LT')
    },
  },
  computed: {
    deleteAvailable() {
      if(!this.chatItem.item || !this.chatItem.item.timestamp) {
        return false
      }
      const d = Date.now() - parseInt(this.chatItem.item.timestamp)
      const week = 1000 * 60 * 60 * 24 * 7
      return this.chatItem.item.sender && d < week
    },
    userName() {
      return this.chatItem.item.username
    }
  }
}
</script>

<style lang="scss">
  .chat__fullscreenimage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;

    &__header {
      display: flex;
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      flex-shrink: 0;

      &__left {
        flex: 1;
      }

      &__center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__label {
        font-size: 14px;
        font-weight: 500;
        color: $colorWhite;
        margin-bottom: 4px;
      }

      &__sublabel {
        font-size: 11px;
        color: rgba($colorWhite, .48);
      }

      &__close {
        display: flex;
        padding: 16px;
        background: rgba($colorWhite, .12);
        border-radius: 50%;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
          background: rgba($colorWhite, .16);
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &__content {
      flex: 1;
      height: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
      flex-shrink: 0;
      background: rgba($colorWhite, .12);

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        max-width: 128px;
        padding: 15px 0;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
          background: rgba($colorWhite, .04);
        }

        img {
          width: 24px;
          height: 24px;
          margin-bottom: 4px;
        }

        &__label {
          font-size: 14px;
          line-height: 22px;
          color: $colorWhite;
        }
      }
    }
  }
</style>
