<template>

  <div :key="componentKey" class="dashboard-container">

    <div class="dashboard-matchday__list">

      <!-- List: Title -->
      <div class="dashboard-matchday__list__title">
        <div v-if="this.isBetgame()" class="dashboard-matchday__list__title__left">
          {{ matchdayTextGlobal(matchdayMatches[0].matchday, matchdayMatches[0].fk_competition) }}
          <span v-if="this.$store.getters.getLiveMatch" style="max-height: 12px;">Live</span>
        </div>
        <div v-else class="dashboard-matchday__list__title__left">
          {{ $t('current_matches') }}
          <span v-if="this.$store.getters.getLiveMatch" style="max-height: 12px;">Live</span>
        </div>
        <div class="dashboard-matchday__list__title__right">
          <span>{{ matchdayPoints }}</span>/{{ maxPoints }}
        </div>
      </div>

      <div v-visibility-change="visibilityChange" class="dashboard-matchday__list__chart">

        <!-- Match -->
        <!-- TODO @Lennard: Add class "--result", "--tendency", "--exact", "--lost" already during match in realtime (due to the current betting result) and in addition another class ".dashboard-matchday__list__chart__item--live" while match is runnning -->
        <div v-for="(match, index) in matchdayMatches"
             v-bind:class="getClass(match)"
             class="dashboard-matchday__list__chart__item">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>{{ betPoints(match, $store.state.user.id)}}</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label" v-bind:class="getTeamClass(match, match.fk_team_home)">
            {{ getTeamShort(match.fk_team_home, match.fk_team_home) }} <!--{{ getGoals(match.goalshome, match.livegoalshome) }}-->
          </div>
          <!-- TODO @Lennard: Add class ".dashboard-matchday__list__chart__item__label--highlight" only if match is finished and only for the time who has won or (if match ended duce) add it to both labels -->
          <div class="dashboard-matchday__list__chart__item__label" v-bind:class="getTeamClass(match, match.fk_team_guest)">
            {{ getTeamShort(match.fk_team_guest, match.team_guest) }} <!--{{ getGoals(match.goalsguest, match.livegoalsguest) }}-->
          </div>
        </div>
     
        <!-- 
        <div class="dashboard-matchday__list__chart__item dashboard-matchday__list__chart__item--exact">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>3</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            WOB
          </div>
          <div class="dashboard-matchday__list__chart__item__label dashboard-matchday__list__chart__item__label--highlight">
            S04
          </div>
        </div>


        <div class="dashboard-matchday__list__chart__item dashboard-matchday__list__chart__item--tendency">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>2</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label dashboard-matchday__list__chart__item__label--highlight">
            SVW
          </div>
          <div class="dashboard-matchday__list__chart__item__label dashboard-matchday__list__chart__item__label--highlight">
            M05
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item dashboard-matchday__list__chart__item--result">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>1</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label dashboard-matchday__list__chart__item__label--highlight">
            F95
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            RBL
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item dashboard-matchday__list__chart__item--lost">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>0</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            FCA
          </div>
          <div class="dashboard-matchday__list__chart__item__label dashboard-matchday__list__chart__item__label--highlight">
            TSG
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item dashboard-matchday__list__chart__item--exact dashboard-matchday__list__chart__item--live">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>2</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            FCB
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            SCF
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item dashboard-matchday__list__chart__item--result
        dashboard-matchday__list__chart__item--live">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>0</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            BMG
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            FCU
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>?</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            SGE
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            B04
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>?</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            SGE
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            B04
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>?</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            SGE
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            B04
          </div>
        </div>

        <div class="dashboard-matchday__list__chart__item">
          <div class="dashboard-matchday__list__chart__item__bar">
            <span>?</span>
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            SGE
          </div>
          <div class="dashboard-matchday__list__chart__item__label">
            B04
          </div>
        </div>
        -->
        
      </div>

    </div>

    <div v-if="matchdayRankingShort && matchdayRankingShort.length > 0" class="dashboard-ranking">
      <div v-for="(ranking, index) in matchdayRankingShort" class="dashboard-ranking__item" v-bind:class="{ 'dashboardranking__item--highlighted': isCurrentUser(ranking.fk_user) }">
        <div v-if="index === 0 || matchdayRankingShort[index-1].position < matchdayRankingShort[index].position" class="dashboard-ranking__item__position">
          {{ ranking.position }}.
        </div>
        <div v-else class="dashboard-ranking__item__position">
        </div>
        <div class="dashboard-ranking__item__name">
          {{ ranking.user_name }}
        </div>
        <div class="dashboard-ranking__item__points">
          {{ livepoints(ranking.fk_user) }}
        </div>
      </div>
    


    <!--
    <div class="dashboard-ranking">
      <div class="dashboard-ranking__item">
        <div class="dashboard-ranking__item__position">
          2
        </div>
        <div class="dashboard-ranking__item__name">
          Marcel Hager
        </div>
        <div class="dashboard-ranking__item__points">
          3
        </div>
      </div>
      <div class="dashboard-ranking__item">
        <div class="dashboard-ranking__item__position">
          3
        </div>
        <div class="dashboard-ranking__item__name">
          Dirk Hellmann
        </div>
        <div class="dashboard-ranking__item__points">
          1
        </div>
      </div>
      <div class="dashboard-ranking__item">
        <div class="dashboard-ranking__item__position">
          4
        </div>
        <div class="dashboard-ranking__item__name">
          Philipp Fromm
        </div>
        <div class="dashboard-ranking__item__points">
          1
        </div>
      </div>
      -->
      <button v-if="pendingTipsCountCurrentBetgame === 0" v-on:click="rankingsButtonClicked">{{ $t('show_matchday_rankings') }}</button>
      <button v-else v-on:click="betsButtonClicked">{{ $t('show_edit_bets') }}</button>
    </div>

  </div>

</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'DashboardLiveStatisticsMultipleMatches',
    data() {
      return {
        componentKey: 0
      }
    },
    props: {
      runningMatchday: {
        type: Number,
        default: () => {
          return 0
        }
      }
    },
    created () {
      Vue.$log.debug('DashboardLiveStatisticsMultipleMatches created - runningMatchday -> ' + this.runningMatchday)
      if (this.runningMatchday > 0 && this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
          this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
        const rm = this.runningMatchday
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, rm]).then(() => {
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, rm]).then(() => {
              this.setBGValue('spmlflr', new Date())
            })
          })
        })
      }
    },
    mounted () {
      if(this.isSPMUser()) {
        window.appResumeEvent = new Event('appResumeEvent')
        window.addEventListener('appResumeEvent', this.appResumeEventFunction, false)
        window.appPullToRefreshEvent = new Event('appPullToRefreshEvent')
        window.addEventListener('appPullToRefreshEvent', this.appPullToRefreshEventFunction, false)
      }
    },
    beforeDestroy() {
      window.removeEventListener('appResumeEvent', this.appResumeEventFunction)
      window.removeEventListener('appPullToRefreshEvent', this.appPullToRefreshEventFunction)
    },
    methods: {
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      },
      appResumeEventFunction() {
        Vue.$log.debug('DashboardLiveStatisticsMultipleMatches appResumeEventFunction')
        this.visibilityChange('appResumeEvent', false)
        return 'appResumeEvent'
      },
      appPullToRefreshEventFunction() {
        Vue.$log.debug('DashboardLiveStatisticsMultipleMatches appPullToRefreshEventFunction')
        const rm = this.runningMatchday
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, rm]).then(() => {
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, rm]).then(() => {
              this.componentKey += 1
              this.setBGValue('spmlflr', new Date())
            })
          })
        })
        return 'appPullToRefreshEvent'
      },
      visibilityChange (evt, hidden) {
        Vue.$log.debug('DashboardLiveStatisticsMultipleMatches visibilityChange - hidden -> ' + hidden)
        if (hidden === false && this.$store.state.leagueId) {
          //this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
          //  this.componentKey += 1
          //})
          const rm = this.runningMatchday
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, rm]).then(() => {
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, rm]).then(() => {
                this.componentKey += 1
                this.setBGValue('spmlflr', new Date())
              })
            })
          })
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        }
      },
      livepoints (user) {
        if(!this.matchdayMatches || this.matchdayMatches === undefined || this.matchdayMatches === null || this.matchdayMatches.length === 0) {
          return 0
        }
        var result = 0
        for (var i = 0; i < this.matchdayMatches.length; i++) {
          const match = this.matchdayMatches[i]
          const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
          const p = this.getPoints(bet, match)
          if (p > -1) {
            result += p
          }
        }
        return result
      },
      getGoals(result, live) {
        if(result !== null && result !== undefined && result !== '' && result !== 'null' && result >= 0) {
          return result
        } else if(live !== null && live !== undefined && live !== '' && live !== 'null' && live >= 0) {
          return live
        } else {
          return ''
        }
      },
      rankingsButtonClicked() {
        if(this.runningMatchday === 0) {
          this.$router.push({ name: 'rankingsbymatchday' })
        } else {
          this.$router.push({ name: 'rankingsbymatchday', params: { matchday: this.runningMatchday } })
        }
      },
      betsButtonClicked() {
        if(this.runningMatchday === 0) {
          this.$router.push({ name: 'bet' })
        } else {
          this.$router.push({ name: 'bet', params: { matchday: this.runningMatchday } })
        }
      },
      getTeamClass (match, fkTeam) {
        if(parseInt(match.goalshome) >= 0 && parseInt(match.goalsguest) >= 0) {
          if (match.goalshome === match.goalsguest) {
            return 'dashboard-matchday__list__chart__item__label--highlight'
          }
          if (fkTeam === match.fk_team_home) {
            if (match.goalshome > match.goalsguest) {
              return 'dashboard-matchday__list__chart__item__label--highlight'
            }
          } else if (fkTeam === match.fk_team_guest) {
            if (match.goalsguest > match.goalshome) {
              return 'dashboard-matchday__list__chart__item__label--highlight'
            }
          }
        } else if(parseInt(match.livegoalshome) >= 0 && parseInt(match.livegoalsguest) >= 0) {
          if (match.livegoalshome === match.livegoalsguest) {
            return 'dashboard-matchday__list__chart__item__label--highlight dashboard-matchday__list__chart__item--team--live'
          }
          if (fkTeam === match.fk_team_home) {
            if (match.livegoalshome > match.livegoalsguest) {
              return 'dashboard-matchday__list__chart__item__label--highlight dashboard-matchday__list__chart__item--team--live'
            }
          } else if (fkTeam === match.fk_team_guest) {
            if (match.livegoalsguest > match.livegoalshome) {
              return 'dashboard-matchday__list__chart__item__label--highlight dashboard-matchday__list__chart__item--team--live'
            }
          }
          return 'dashboard-matchday__list__chart__item--team--live'
        }
        return ''
      },
      getClass (match) {
        var c = ''
        const bp = this.betPoints(match, this.$store.state.user.id)
        switch (bp) {
          case this.pointsBetResult:
            c = 'dashboard-matchday__list__chart__item--exact'
            break
          case this.pointsBetTendency:
            c = 'dashboard-matchday__list__chart__item--result'
            break
          case this.pointsBetGoaldiff:
            c = 'dashboard-matchday__list__chart__item--tendency'
            break
          default:
            c = 'dashboard-matchday__list__chart__item--lost'
            break
        }
        if(parseInt(match.livegoalshome) >= 0 && parseInt(match.livegoalsguest) >= 0) {
          //c += ' dashboard-matchday__list__chart__item--live'
        }
        return c
      },
      betPoints (match, user) {
        if (user !== this.$store.state.user.id &&
            match.goalshome === null && match.goalsguest === null &&
            match.livegoalshome === null && match.livegoalsguest === null) {
          return ''
        }
        if (match.goalshome === null && match.goalsguest === null &&
            match.livegoalshome === null && match.livegoalsguest === null) {
          return '?'
        }
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
        if (bet !== undefined && bet !== null) {
          const p = this.getPoints(bet, match)
          return p
        } else {
          if ((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
              (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
            return 0
          }
        }
      },
    },
    computed: {
      pendingTipsCountCurrentBetgame() {
        if(this.$store.state.missing_bets !== undefined) {
          const mb = this.$store.state.missing_bets
          var result = 0
          for (var i = 0; i < mb.length; i++) {
            const item = mb[i]
            if (item.matchcount > item.betcount && item.id === this.$store.state.betgame.id) {
              result += item.matchcount - item.betcount
            }
          }
          return result
        } else {
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
          return 0
        }
      },
      betForAllMatchdayMatches () {
        const bets = this.$store.state.bets
        var result = false
        if (bets.length > 0 && this.runningMatchday > 0) {
          const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.runningMatchday)
          if (matchdayMatches.length > 0) {
            for (var i = 0; i < matchdayMatches.length; i++) {
              const match = matchdayMatches[i]
              if(this.isPast(match.matchdate)) {
                continue
              }
              if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
                  || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung'
                  || match.status.trim() === 'nicht ausgetragen')) {
                continue
              }
              const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
              if (betMatches.length === 0) {
                return false
              } else {
                result = true
              }
            }
          }
        }
        return result
      },
      /*
      runningMatchday() {
        if(this.getRunningMatchdays.length > 0) {
          return this.getRunningMatchdays[0].matchday
        } else if(this.$store.getters.getLiveMatch) {
          return this.$store.getters.getLiveMatch.matchday
        }
        return 0
      },
      getRunningMatchdays() {
        const amtchdays = this.calcMatchdayStartEnd
        var result = []
        for(var i=0; i<amtchdays.length; i++) {
          const matchday = amtchdays[i]
          if(matchday && matchday.start && matchday.end) {
            const start = this.parseMatchdate(matchday.start)
            const end = this.parseMatchdate(matchday.end)
            if(start && end && matchday.days <= 4) {
              if(start < Date.now() && end.add(180, 'minutes') > Date.now()) {
                result.push(matchday)
              }
            }
          }
        }
        return result
      },
      */
      calcMatchdayStartEnd() {
        const matchesByMathday = this.groupMatchesByMathday
        var result = []
        for(var matchday = 0; matchday < matchesByMathday.length; matchday++) {
          const matchdayMatches = matchesByMathday[matchday]
          if(matchdayMatches) {
            const sorted = matchdayMatches.sort(function (a, b) {
              return Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            const matchdayStart = sorted[0].matchdate
            const matchdayEnd = sorted[sorted.length - 1].matchdate
            const diffInDays = 1 + Math.floor((Date.parse(matchdayEnd) - Date.parse(matchdayStart)) / (1000 * 60 * 60 * 24))
            result[matchday] = {
              start: matchdayStart,
              end: matchdayEnd,
              days: diffInDays,
              matchday: matchday
            }
          }
        }
        return result
      },
      groupMatchesByMathday() {
        var result = []
        for(var i = 0; i < this.$store.state.matches.length; i++) {
          const match = this.$store.state.matches[i]
          if(result[match.matchday] === undefined) {
            result[match.matchday] = []
          }
          result[match.matchday].push(match)
        }
        return result
      },
      matchdayRankingShort() {
        var rankings = []
        const betgameUsers = this.$store.state.betgameUsers || []
        for(var i = 0; i < betgameUsers.length; i++) {
          const lp = this.livepoints(betgameUsers[i].fk_user)
          rankings.push({fk_user: betgameUsers[i].fk_user, user_name: betgameUsers[i].nickname, points_total: lp})
        }
        rankings = rankings.sort(function (a, b) {
          return b.points_total - a.points_total
        })
        for(var i = 0; i < rankings.length; i++) {
          if(i > 0 && rankings[i].points_total === rankings[i - 1].points_total) {
            rankings[i].position = rankings[i - 1].position
          } else {
            rankings[i].position = i + 1
          }
        }
        
        if(rankings && rankings.length > 3) {
          var t = []
          for(var i = 0; i < rankings.length; i++) {
            if(rankings[i].fk_user === this.$store.state.user.id) {
              if(i === 0) {
                t.push(rankings[i])
                t.push(rankings[i + 1])
                t.push(rankings[i + 2])
              } else if (i > 0) {
                if(i === rankings.length - 1 && rankings.length > 2) {
                  t.push(rankings[i - 2])
                  t.push(rankings[i - 1])
                  t.push(rankings[i])
                } else {
                  t.push(rankings[i - 1])
                  t.push(rankings[i])
                  if (i < rankings.length - 1) {
                    t.push(rankings[i + 1])
                  }
                }
              }
            }
          }
          return t
        } else {
          return rankings
        }
      },
      matchdayPoints() {
        var result = 0
        const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.runningMatchday)
        if(matchdayMatches) {
          matchdayMatches.forEach(match => {
            const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, this.$store.state.user.id)
            if(bet !== undefined && bet !== null) {
              const p = this.getPoints(bet, match)
              result += p
            } else {
              if((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
                 (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
                result += 0
              }
            }
          })
        }
        return result
      },
      pointsBetResult() {
        return this.$store.state.betgame.points_bet_result
      },
      pointsBetGoaldiff() {
        return this.$store.state.betgame.points_bet_goaldiff
      },
      pointsBetTendency() {
        return this.$store.state.betgame.points_bet_tendency
      },
      maxPoints() {
        if(!this.matchdayMatches) {
          return 0
        }
        return this.matchdayMatches.length * this.$store.state.betgame.points_bet_result
      },      
      matchdayMatchesCount() {
        if(!this.matchdayMatches) {
          return 0
        }
        return this.matchdayMatches.length
      },
      matchdayMatches () {
        var matchdayMatches = []
        var temp = this.$store.getters.getMatchesByMatchday(this.runningMatchday)
        if (!this.isBetgame()) {
          temp = this.$store.getters.getTodaysMatches
        }
        if (temp) {
          for (var i = 0; i < temp.length; i++) {
            matchdayMatches.push(temp[i])
          }
          const sorted = matchdayMatches.sort(function (a, b) {
            if (parseInt(a.livegoalshome) >= 0 && parseInt(a.livegoalsguest) >= 0 && !b.livegoalshome && !b.livegoalsguest) {
              return -1
            } else if (parseInt(b.livegoalshome) >= 0 && parseInt(b.livegoalsguest) >= 0 && !a.livegoalshome && !a.livegoalsguest) {
              return 1
            }
            if (a.matchdate === b.matchdate) {
              return a.id - b.id
            }
            return Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return sorted
        }
        return []
      },
    }
  }
</script>

<style lang="scss">
.dashboard-matchday__list__chart__item--team--live {
  color: $colorNegative;
}
</style>

<style lang="scss" scoped>
  .dashboard-container {
    margin-bottom: 24px;
  }
</style>
